import { useContext } from 'react';

import { IToastOptions, SnackbarContext } from '../context/SnackbarContext';

interface IUseSnackbar {
  addToast: (content: string, opt?: IToastOptions) => void;
  removeAll: () => void;
}

export const useSnackbar: () => IUseSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext);

  const handleAddToast = (content: string, opt?: IToastOptions) => {
    return snackbarContext.showToast(content, opt);
  };

  const handleRemoveAll = () => {
    return snackbarContext.removeAll();
  };

  return {
    addToast: handleAddToast,
    removeAll: handleRemoveAll,
  };
};

export default useSnackbar;

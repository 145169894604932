import tw from 'twin.macro';

import styled from 'styled-components';

export const QuestionContainer = styled.div`
  ${tw`flex flex-col text-base font-medium`}
  margin-bottom: 24px;
`;

export const Btn = styled.button`
  ${tw`w-full text-base font-medium`}
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #038767;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const BlockButton = styled(Btn)`
  ${tw`bg-observatory-500 text-white`}
`;

export const BorderButton = styled(Btn)`
  ${tw`bg-white text-observatory-500`}
`;

export const DangerButton = styled(Btn)`
  ${tw`text-white bg-red-500 border-none`}
`;

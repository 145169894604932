import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const LoyaltyPage = loadable(() => import(/* webpackChunkName: "LoyaltyPage" */ './Loyalty'), {
  fallback: <LoadingScreen />,
});

export const loyaltyRoutes: Route[] = [
  {
    name: 'Loyalty',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.Loyalty,
      component: LoyaltyPage,
    },
  },
];

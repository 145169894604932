import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const AuthLoginPagesV2 = loadable(
  () => import(/* webpackChunkName: "AuthLoginPages" */ './AuthLoginV2'),
  {
    fallback: <LoadingScreen />,
  },
);

export const AuthLoginPageSwitch = () => {
  return <AuthLoginPagesV2 />;
};

export const loginRoutes: Route[] = [
  {
    name: 'Auth Login',
    isGuest: true,
    routeProps: {
      exact: true,
      path: PATH.AUTH_LOGIN,
      component: AuthLoginPageSwitch,
    },
  },
];

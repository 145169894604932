// Generated by `$ payara generate`- DON'T EDIT THIS FILE
export type EnvKeys =
  | 'REACT_APP_AUTH_V2_PHONE_PREFIX'
  | 'REACT_APP_BUCKET_NAME'
  | 'REACT_APP_CHAT_DOWNSTREAM_SERVICES'
  | 'REACT_APP_CHAT_DOWNSTREAM_TOKEN'
  | 'REACT_APP_COMMERCE_SERVICE'
  | 'REACT_APP_CRM_SERVICE'
  | 'REACT_APP_CULTIVATION_FORM'
  | 'REACT_APP_CUSTOMER_SERVICE_PHONE_NUMBER'
  | 'REACT_APP_ELASTIC_APM_ENVIRONMENT'
  | 'REACT_APP_ELASTIC_APM_SERVER_URL'
  | 'REACT_APP_FEATURE_ADDITIONAL_DOCUMENTS_KBR_KBX'
  | 'REACT_APP_FEATURE_ADDRESS_MANAGEMENT_ON_KBX_KBL'
  | 'REACT_APP_FEATURE_ADD_DIRECT_URL_KABAYAN_RESTRICTION'
  | 'REACT_APP_FEATURE_ADJUSTMENT_STATUS_KABAYAN'
  | 'REACT_APP_FEATURE_ASURANSI_TAGIHAN_KABAYAN'
  | 'REACT_APP_FEATURE_AUTH_V2'
  | 'REACT_APP_FEATURE_AUTO_ADD_UTM_PARAMS'
  | 'REACT_APP_FEATURE_BLOCK_SALES_DEVICE'
  | 'REACT_APP_FEATURE_BLOCK_SHRIMP_USER'
  | 'REACT_APP_FEATURE_CHANGE_PHONE'
  | 'REACT_APP_FEATURE_CHECK_UPDATED_PRICE'
  | 'REACT_APP_FEATURE_CONTINUE_KBR_DRAFT'
  | 'REACT_APP_FEATURE_DEVICE_ID_SD'
  | 'REACT_APP_FEATURE_DISABLE_BANNER_RESUBMISSION'
  | 'REACT_APP_FEATURE_DISABLE_DOUBLE_KBX_SUBMISSION'
  | 'REACT_APP_FEATURE_EMERGENCY_CONTACT_ON_KBX_KBL'
  | 'REACT_APP_FEATURE_ENABLED_ADDRESS_VALIDATION'
  | 'REACT_APP_FEATURE_ENABLED_ENHANCE_PERFORMANCE'
  | 'REACT_APP_FEATURE_ENABLED_POINT_CODE'
  | 'REACT_APP_FEATURE_ENABLED_STOCK_VISIBILITY'
  | 'REACT_APP_FEATURE_ENCHANCE_OCR_KABAYAN'
  | 'REACT_APP_FEATURE_ENHANCE_GET_CART_RESPONSE'
  | 'REACT_APP_FEATURE_ENHANCE_OTP_REQUEST'
  | 'REACT_APP_FEATURE_FORCE_UPDATE'
  | 'REACT_APP_FEATURE_HEADER_PLATFORM'
  | 'REACT_APP_FEATURE_HISTORY_TRX_FROM_SAWARNA'
  | 'REACT_APP_FEATURE_HOME_BACKGROUNDSYNC'
  | 'REACT_APP_FEATURE_JEMPUT_TUNAI'
  | 'REACT_APP_FEATURE_KABAYAN_ENHANCEMENT_OCR'
  | 'REACT_APP_FEATURE_KABAYAN_LOCATION_ID'
  | 'REACT_APP_FEATURE_KABAYAN_REGULER_SUBMISSION'
  | 'REACT_APP_FEATURE_KABAYAN_RESUBMISSION'
  | 'REACT_APP_FEATURE_KBR_OFFLINE_DRAFTING'
  | 'REACT_APP_FEATURE_KBX_DRAFT_SAWARNA'
  | 'REACT_APP_FEATURE_KBX_KBL_SAWARNA_V2'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_COACHMARK_PAY_KABAYAN'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_CONNECTING_BUYER'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_ENABLE_CS'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_KABAYAN_BILL'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_MULTI_COMMODITY'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_NEW_ADDRESS'
  | 'REACT_APP_FEATURE_LAPAK_IKAN_PROMO'
  | 'REACT_APP_FEATURE_LATLONG_SUBDISTRICT'
  | 'REACT_APP_FEATURE_LEDGER_FROM_SAWARNA'
  | 'REACT_APP_FEATURE_MANDATORY_PATOKAN'
  | 'REACT_APP_FEATURE_MERGE_BLACKLIST_SUPPLIER_TO_PUTPOSTCART'
  | 'REACT_APP_FEATURE_MIGRATE_LOCATIONS_SAWARNA_REGISTER'
  | 'REACT_APP_FEATURE_MIN_VALIDATION_OCR_KABAYAN'
  | 'REACT_APP_FEATURE_NEW_API_INVOICE'
  | 'REACT_APP_FEATURE_NEW_DEVICE_ID'
  | 'REACT_APP_FEATURE_NEW_WORDING_POPUP'
  | 'REACT_APP_FEATURE_POND_IMAGE_MIN_3_KBR'
  | 'REACT_APP_FEATURE_PRODUCT_CATEGORIES_V2'
  | 'REACT_APP_FEATURE_PWC_AUDIT'
  | 'REACT_APP_FEATURE_REGISTERED_PHONE_V2'
  | 'REACT_APP_FEATURE_REST_API'
  | 'REACT_APP_FEATURE_SHOW_FUNDER_NAME'
  | 'REACT_APP_FEATURE_SIMILARITY_CHECK'
  | 'REACT_APP_FEATURE_TRACKING_PUSH_NOTIF_SAWARNA_V2'
  | 'REACT_APP_FEATURE_VALIDATE_ADDRESS_KTP_DOMICILE'
  | 'REACT_APP_FEATURE_VERIFIED_FARMER'
  | 'REACT_APP_FEATURE_ZIPCODE_ON_GET_SUBDISTRICT'
  | 'REACT_APP_FEEDBACK_CUSTOMER'
  | 'REACT_APP_FEEDEROPS_URL'
  | 'REACT_APP_FILE_ALBUM_NAME'
  | 'REACT_APP_FIREBASE_API_KEY'
  | 'REACT_APP_FIREBASE_APP_ID'
  | 'REACT_APP_FIREBASE_AUTH_DOMAIN'
  | 'REACT_APP_FIREBASE_CLOUD_MESSAGING'
  | 'REACT_APP_FIREBASE_MESSAGING_SENDER_ID'
  | 'REACT_APP_FIREBASE_PROJECT_ID'
  | 'REACT_APP_FIREBASE_STORAGE_BUCKET'
  | 'REACT_APP_FLAGR_ACTIVE_PRODUCT_CATEGORIES'
  | 'REACT_APP_FLAGR_BELANJA_PAKAN_VIA_CHAT'
  | 'REACT_APP_FLAGR_BELI_PAKAN_V2'
  | 'REACT_APP_FLAGR_BLACKBOX'
  | 'REACT_APP_FLAGR_COMMODITY_LAPAK_IKAN_V2'
  | 'REACT_APP_FLAGR_COMPLAINT_FORM_V2'
  | 'REACT_APP_FLAGR_COMPLAINT_HANDLING_V2'
  | 'REACT_APP_FLAGR_COMPLAINT_REQUEST_TOKEN_V2'
  | 'REACT_APP_FLAGR_CULTIVATION_FORM'
  | 'REACT_APP_FLAGR_DEMO_ACCOUNT_V2'
  | 'REACT_APP_FLAGR_EARLY_PAYMENT_V2'
  | 'REACT_APP_FLAGR_ENABLE_FILTER_POINT_ACTIVE'
  | 'REACT_APP_FLAGR_ENABLE_POPUP_HOMEPAGE'
  | 'REACT_APP_FLAGR_ENABLE_POPUP_UPDATE_NPWP'
  | 'REACT_APP_FLAGR_E_NOTA_V2'
  | 'REACT_APP_FLAGR_FARMER_BALANCE'
  | 'REACT_APP_FLAGR_FEEDBACK_CUSTOMER_V2'
  | 'REACT_APP_FLAGR_FILTER_PRODUCT_CATEGORIES_V2'
  | 'REACT_APP_FLAGR_FILTER_SUPPLIER_V2'
  | 'REACT_APP_FLAGR_FMS_V2'
  | 'REACT_APP_FLAGR_FORCE_UPDATE_V2'
  | 'REACT_APP_FLAGR_HIDE_STATUS'
  | 'REACT_APP_FLAGR_KABAYAN_ELLIGIBLE_CHECK'
  | 'REACT_APP_FLAGR_KABAYAN_ELLIGIBLE_CHECK_V2'
  | 'REACT_APP_FLAGR_KABAYAN_EXPRESS_V2'
  | 'REACT_APP_FLAGR_KABAYAN_STATUS_V2'
  | 'REACT_APP_FLAGR_KBR_FORM'
  | 'REACT_APP_FLAGR_KBR_SUBMISSION_POINTS_V2'
  | 'REACT_APP_FLAGR_KBR_SUBMISSION_V2'
  | 'REACT_APP_FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN'
  | 'REACT_APP_FLAGR_LAPAK_IKAN_V2'
  | 'REACT_APP_FLAGR_LOYALTY_ENTRY'
  | 'REACT_APP_FLAGR_MAINTENANCE_BELI_PAKAN_V2'
  | 'REACT_APP_FLAGR_MAINTENANCE_EFEEDER_V2'
  | 'REACT_APP_FLAGR_MAINTENANCE_LAPAK_IKAN_V2'
  | 'REACT_APP_FLAGR_MAINTENANCE_V2'
  | 'REACT_APP_FLAGR_OCR_KK_V2'
  | 'REACT_APP_FLAGR_OCR_KTP'
  | 'REACT_APP_FLAGR_ON_REAPPLY'
  | 'REACT_APP_FLAGR_OTP_SMS_V2'
  | 'REACT_APP_FLAGR_OTP_WA_V2'
  | 'REACT_APP_FLAGR_PAYMENT_EXPIRED_DURATION_V2'
  | 'REACT_APP_FLAGR_PAY_KABAYAN_V2'
  | 'REACT_APP_FLAGR_PCFA_CONTACT_V2'
  | 'REACT_APP_FLAGR_PRIVACY_POLICY'
  | 'REACT_APP_FLAGR_PRODUCT_SOLD_THRESHOLD'
  | 'REACT_APP_FLAGR_REFETCH_INTERVAL'
  | 'REACT_APP_FLAGR_REGISTER_POINT'
  | 'REACT_APP_FLAGR_REPAYMENT_V2'
  | 'REACT_APP_FLAGR_SELF_TRANSACTION_V2'
  | 'REACT_APP_FLAGR_SUPPORT_INFO_V2'
  | 'REACT_APP_FLAGR_URL'
  | 'REACT_APP_FLAGR_URL_V2'
  | 'REACT_APP_FORM_COMPLAINT'
  | 'REACT_APP_FORM_KABAYAN_EXPRESS'
  | 'REACT_APP_FORM_LAPAK_IKAN'
  | 'REACT_APP_GRAPH_API'
  | 'REACT_APP_GROWTH_REMOTE_URL'
  | 'REACT_APP_GTM_CODE'
  | 'REACT_APP_IMPROVEMENT_OCR_TRACKER_GTM'
  | 'REACT_APP_KMP_FARMER_IDS'
  | 'REACT_APP_LOYALTY_DIVIDER'
  | 'REACT_APP_MODE'
  | 'REACT_APP_OCR_TRACKER_GTM'
  | 'REACT_APP_OCTO_SERVICE'
  | 'REACT_APP_PAYMENT_EXPIRED_DURATION'
  | 'REACT_APP_PAYMENT_GATEWAY'
  | 'REACT_APP_PRIVACY_POLICY'
  | 'REACT_APP_PRIVACY_POLICY_PAGE'
  | 'REACT_APP_PROMOTION_SERVICE'
  | 'REACT_APP_RECOMMENDATION_SERVICES'
  | 'REACT_APP_REPAYMENT_GUIDE_URL'
  | 'REACT_APP_REQUEST_SIGNATURE_SECRET'
  | 'REACT_APP_SAWARNA_V2_SERVICES'
  | 'REACT_APP_SENTRY_DSN'
  | 'REACT_APP_SUPPORT_MALL_URL'
  | 'REACT_APP_TEMPLATE_PROCURATION_URL'
  | 'REACT_APP_USER_SERVICE'
  | 'REACT_APP_WA_TESTING';
export const AUTH_V2_PHONE_PREFIX = process.env.REACT_APP_AUTH_V2_PHONE_PREFIX;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const CHAT_DOWNSTREAM_SERVICES = process.env.REACT_APP_CHAT_DOWNSTREAM_SERVICES;
export const CHAT_DOWNSTREAM_TOKEN = process.env.REACT_APP_CHAT_DOWNSTREAM_TOKEN;
export const COMMERCE_SERVICE = process.env.REACT_APP_COMMERCE_SERVICE;
export const CRM_SERVICE = process.env.REACT_APP_CRM_SERVICE;
export const CULTIVATION_FORM = process.env.REACT_APP_CULTIVATION_FORM;
export const CUSTOMER_SERVICE_PHONE_NUMBER = process.env.REACT_APP_CUSTOMER_SERVICE_PHONE_NUMBER;
export const ELASTIC_APM_ENVIRONMENT = process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT;
export const ELASTIC_APM_SERVER_URL = process.env.REACT_APP_ELASTIC_APM_SERVER_URL;
export const FEATURE_ADDITIONAL_DOCUMENTS_KBR_KBX = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ADDITIONAL_DOCUMENTS_KBR_KBX || '',
);
export const FEATURE_ADDRESS_MANAGEMENT_ON_KBX_KBL = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ADDRESS_MANAGEMENT_ON_KBX_KBL || '',
);
export const FEATURE_ADD_DIRECT_URL_KABAYAN_RESTRICTION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ADD_DIRECT_URL_KABAYAN_RESTRICTION || '',
);
export const FEATURE_ADJUSTMENT_STATUS_KABAYAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ADJUSTMENT_STATUS_KABAYAN || '',
);
export const FEATURE_ASURANSI_TAGIHAN_KABAYAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ASURANSI_TAGIHAN_KABAYAN || '',
);
export const FEATURE_AUTH_V2 = ['true', '1'].includes(process.env.REACT_APP_FEATURE_AUTH_V2 || '');
export const FEATURE_AUTO_ADD_UTM_PARAMS = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_AUTO_ADD_UTM_PARAMS || '',
);
export const FEATURE_BLOCK_SALES_DEVICE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_BLOCK_SALES_DEVICE || '',
);
export const FEATURE_BLOCK_SHRIMP_USER = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_BLOCK_SHRIMP_USER || '',
);
export const FEATURE_CHANGE_PHONE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_CHANGE_PHONE || '',
);
export const FEATURE_CHECK_UPDATED_PRICE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_CHECK_UPDATED_PRICE || '',
);
export const FEATURE_CONTINUE_KBR_DRAFT = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_CONTINUE_KBR_DRAFT || '',
);
export const FEATURE_DEVICE_ID_SD = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_DEVICE_ID_SD || '',
);
export const FEATURE_DISABLE_BANNER_RESUBMISSION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_DISABLE_BANNER_RESUBMISSION || '',
);
export const FEATURE_DISABLE_DOUBLE_KBX_SUBMISSION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_DISABLE_DOUBLE_KBX_SUBMISSION || '',
);
export const FEATURE_EMERGENCY_CONTACT_ON_KBX_KBL = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_EMERGENCY_CONTACT_ON_KBX_KBL || '',
);
export const FEATURE_ENABLED_ADDRESS_VALIDATION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENABLED_ADDRESS_VALIDATION || '',
);
export const FEATURE_ENABLED_ENHANCE_PERFORMANCE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENABLED_ENHANCE_PERFORMANCE || '',
);
export const FEATURE_ENABLED_POINT_CODE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENABLED_POINT_CODE || '',
);
export const FEATURE_ENABLED_STOCK_VISIBILITY = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENABLED_STOCK_VISIBILITY || '',
);
export const FEATURE_ENCHANCE_OCR_KABAYAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENCHANCE_OCR_KABAYAN || '',
);
export const FEATURE_ENHANCE_GET_CART_RESPONSE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENHANCE_GET_CART_RESPONSE || '',
);
export const FEATURE_ENHANCE_OTP_REQUEST = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ENHANCE_OTP_REQUEST || '',
);
export const FEATURE_FORCE_UPDATE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_FORCE_UPDATE || '',
);
export const FEATURE_HEADER_PLATFORM = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_HEADER_PLATFORM || '',
);
export const FEATURE_HISTORY_TRX_FROM_SAWARNA = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_HISTORY_TRX_FROM_SAWARNA || '',
);
export const FEATURE_HOME_BACKGROUNDSYNC = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_HOME_BACKGROUNDSYNC || '',
);
export const FEATURE_JEMPUT_TUNAI = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_JEMPUT_TUNAI || '',
);
export const FEATURE_KABAYAN_ENHANCEMENT_OCR = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KABAYAN_ENHANCEMENT_OCR || '',
);
export const FEATURE_KABAYAN_LOCATION_ID = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KABAYAN_LOCATION_ID || '',
);
export const FEATURE_KABAYAN_REGULER_SUBMISSION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KABAYAN_REGULER_SUBMISSION || '',
);
export const FEATURE_KABAYAN_RESUBMISSION = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KABAYAN_RESUBMISSION || '',
);
export const FEATURE_KBR_OFFLINE_DRAFTING = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KBR_OFFLINE_DRAFTING || '',
);
export const FEATURE_KBX_DRAFT_SAWARNA = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KBX_DRAFT_SAWARNA || '',
);
export const FEATURE_KBX_KBL_SAWARNA_V2 = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_KBX_KBL_SAWARNA_V2 || '',
);
export const FEATURE_LAPAK_IKAN_COACHMARK_PAY_KABAYAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_COACHMARK_PAY_KABAYAN || '',
);
export const FEATURE_LAPAK_IKAN_CONNECTING_BUYER = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_CONNECTING_BUYER || '',
);
export const FEATURE_LAPAK_IKAN_ENABLE_CS = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_ENABLE_CS || '',
);
export const FEATURE_LAPAK_IKAN_KABAYAN_BILL = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_KABAYAN_BILL || '',
);
export const FEATURE_LAPAK_IKAN_MULTI_COMMODITY = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_MULTI_COMMODITY || '',
);
export const FEATURE_LAPAK_IKAN_NEW_ADDRESS = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_NEW_ADDRESS || '',
);
export const FEATURE_LAPAK_IKAN_PROMO = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LAPAK_IKAN_PROMO || '',
);
export const FEATURE_LATLONG_SUBDISTRICT = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LATLONG_SUBDISTRICT || '',
);
export const FEATURE_LEDGER_FROM_SAWARNA = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_LEDGER_FROM_SAWARNA || '',
);
export const FEATURE_MANDATORY_PATOKAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_MANDATORY_PATOKAN || '',
);
export const FEATURE_MERGE_BLACKLIST_SUPPLIER_TO_PUTPOSTCART = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_MERGE_BLACKLIST_SUPPLIER_TO_PUTPOSTCART || '',
);
export const FEATURE_MIGRATE_LOCATIONS_SAWARNA_REGISTER = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_MIGRATE_LOCATIONS_SAWARNA_REGISTER || '',
);
export const FEATURE_MIN_VALIDATION_OCR_KABAYAN = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_MIN_VALIDATION_OCR_KABAYAN || '',
);
export const FEATURE_NEW_API_INVOICE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_NEW_API_INVOICE || '',
);
export const FEATURE_NEW_DEVICE_ID = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_NEW_DEVICE_ID || '',
);
export const FEATURE_NEW_WORDING_POPUP = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_NEW_WORDING_POPUP || '',
);
export const FEATURE_POND_IMAGE_MIN_3_KBR = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_POND_IMAGE_MIN_3_KBR || '',
);
export const FEATURE_PRODUCT_CATEGORIES_V2 = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_PRODUCT_CATEGORIES_V2 || '',
);
export const FEATURE_PWC_AUDIT = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_PWC_AUDIT || '',
);
export const FEATURE_REGISTERED_PHONE_V2 = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_REGISTERED_PHONE_V2 || '',
);
export const FEATURE_REST_API = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_REST_API || '',
);
export const FEATURE_SHOW_FUNDER_NAME = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_SHOW_FUNDER_NAME || '',
);
export const FEATURE_SIMILARITY_CHECK = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_SIMILARITY_CHECK || '',
);
export const FEATURE_TRACKING_PUSH_NOTIF_SAWARNA_V2 = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_TRACKING_PUSH_NOTIF_SAWARNA_V2 || '',
);
export const FEATURE_VALIDATE_ADDRESS_KTP_DOMICILE = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_VALIDATE_ADDRESS_KTP_DOMICILE || '',
);
export const FEATURE_VERIFIED_FARMER = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_VERIFIED_FARMER || '',
);
export const FEATURE_ZIPCODE_ON_GET_SUBDISTRICT = ['true', '1'].includes(
  process.env.REACT_APP_FEATURE_ZIPCODE_ON_GET_SUBDISTRICT || '',
);
export const FEEDBACK_CUSTOMER = process.env.REACT_APP_FEEDBACK_CUSTOMER;
export const FEEDEROPS_URL = process.env.REACT_APP_FEEDEROPS_URL;
export const FILE_ALBUM_NAME = process.env.REACT_APP_FILE_ALBUM_NAME;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_CLOUD_MESSAGING = process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FLAGR_ACTIVE_PRODUCT_CATEGORIES =
  process.env.REACT_APP_FLAGR_ACTIVE_PRODUCT_CATEGORIES;
export const FLAGR_BELANJA_PAKAN_VIA_CHAT = process.env.REACT_APP_FLAGR_BELANJA_PAKAN_VIA_CHAT;
export const FLAGR_BELI_PAKAN_V2 = process.env.REACT_APP_FLAGR_BELI_PAKAN_V2;
export const FLAGR_BLACKBOX = process.env.REACT_APP_FLAGR_BLACKBOX;
export const FLAGR_COMMODITY_LAPAK_IKAN_V2 = process.env.REACT_APP_FLAGR_COMMODITY_LAPAK_IKAN_V2;
export const FLAGR_COMPLAINT_FORM_V2 = process.env.REACT_APP_FLAGR_COMPLAINT_FORM_V2;
export const FLAGR_COMPLAINT_HANDLING_V2 = process.env.REACT_APP_FLAGR_COMPLAINT_HANDLING_V2;
export const FLAGR_COMPLAINT_REQUEST_TOKEN_V2 =
  process.env.REACT_APP_FLAGR_COMPLAINT_REQUEST_TOKEN_V2;
export const FLAGR_CULTIVATION_FORM = process.env.REACT_APP_FLAGR_CULTIVATION_FORM;
export const FLAGR_DEMO_ACCOUNT_V2 = process.env.REACT_APP_FLAGR_DEMO_ACCOUNT_V2;
export const FLAGR_EARLY_PAYMENT_V2 = process.env.REACT_APP_FLAGR_EARLY_PAYMENT_V2;
export const FLAGR_ENABLE_FILTER_POINT_ACTIVE =
  process.env.REACT_APP_FLAGR_ENABLE_FILTER_POINT_ACTIVE;
export const FLAGR_ENABLE_POPUP_HOMEPAGE = process.env.REACT_APP_FLAGR_ENABLE_POPUP_HOMEPAGE;
export const FLAGR_ENABLE_POPUP_UPDATE_NPWP = process.env.REACT_APP_FLAGR_ENABLE_POPUP_UPDATE_NPWP;
export const FLAGR_E_NOTA_V2 = process.env.REACT_APP_FLAGR_E_NOTA_V2;
export const FLAGR_FARMER_BALANCE = process.env.REACT_APP_FLAGR_FARMER_BALANCE;
export const FLAGR_FEEDBACK_CUSTOMER_V2 = process.env.REACT_APP_FLAGR_FEEDBACK_CUSTOMER_V2;
export const FLAGR_FILTER_PRODUCT_CATEGORIES_V2 =
  process.env.REACT_APP_FLAGR_FILTER_PRODUCT_CATEGORIES_V2;
export const FLAGR_FILTER_SUPPLIER_V2 = process.env.REACT_APP_FLAGR_FILTER_SUPPLIER_V2;
export const FLAGR_FMS_V2 = process.env.REACT_APP_FLAGR_FMS_V2;
export const FLAGR_FORCE_UPDATE_V2 = process.env.REACT_APP_FLAGR_FORCE_UPDATE_V2;
export const FLAGR_HIDE_STATUS = process.env.REACT_APP_FLAGR_HIDE_STATUS;
export const FLAGR_KABAYAN_ELLIGIBLE_CHECK = process.env.REACT_APP_FLAGR_KABAYAN_ELLIGIBLE_CHECK;
export const FLAGR_KABAYAN_ELLIGIBLE_CHECK_V2 =
  process.env.REACT_APP_FLAGR_KABAYAN_ELLIGIBLE_CHECK_V2;
export const FLAGR_KABAYAN_EXPRESS_V2 = process.env.REACT_APP_FLAGR_KABAYAN_EXPRESS_V2;
export const FLAGR_KABAYAN_STATUS_V2 = process.env.REACT_APP_FLAGR_KABAYAN_STATUS_V2;
export const FLAGR_KBR_FORM = process.env.REACT_APP_FLAGR_KBR_FORM;
export const FLAGR_KBR_SUBMISSION_POINTS_V2 = process.env.REACT_APP_FLAGR_KBR_SUBMISSION_POINTS_V2;
export const FLAGR_KBR_SUBMISSION_V2 = process.env.REACT_APP_FLAGR_KBR_SUBMISSION_V2;
export const FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN =
  process.env.REACT_APP_FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN;
export const FLAGR_LAPAK_IKAN_V2 = process.env.REACT_APP_FLAGR_LAPAK_IKAN_V2;
export const FLAGR_LOYALTY_ENTRY = process.env.REACT_APP_FLAGR_LOYALTY_ENTRY;
export const FLAGR_MAINTENANCE_BELI_PAKAN_V2 =
  process.env.REACT_APP_FLAGR_MAINTENANCE_BELI_PAKAN_V2;
export const FLAGR_MAINTENANCE_EFEEDER_V2 = process.env.REACT_APP_FLAGR_MAINTENANCE_EFEEDER_V2;
export const FLAGR_MAINTENANCE_LAPAK_IKAN_V2 =
  process.env.REACT_APP_FLAGR_MAINTENANCE_LAPAK_IKAN_V2;
export const FLAGR_MAINTENANCE_V2 = process.env.REACT_APP_FLAGR_MAINTENANCE_V2;
export const FLAGR_OCR_KK_V2 = process.env.REACT_APP_FLAGR_OCR_KK_V2;
export const FLAGR_OCR_KTP = process.env.REACT_APP_FLAGR_OCR_KTP;
export const FLAGR_ON_REAPPLY = process.env.REACT_APP_FLAGR_ON_REAPPLY;
export const FLAGR_OTP_SMS_V2 = process.env.REACT_APP_FLAGR_OTP_SMS_V2;
export const FLAGR_OTP_WA_V2 = process.env.REACT_APP_FLAGR_OTP_WA_V2;
export const FLAGR_PAYMENT_EXPIRED_DURATION_V2 =
  process.env.REACT_APP_FLAGR_PAYMENT_EXPIRED_DURATION_V2;
export const FLAGR_PAY_KABAYAN_V2 = process.env.REACT_APP_FLAGR_PAY_KABAYAN_V2;
export const FLAGR_PCFA_CONTACT_V2 = process.env.REACT_APP_FLAGR_PCFA_CONTACT_V2;
export const FLAGR_PRIVACY_POLICY = process.env.REACT_APP_FLAGR_PRIVACY_POLICY;
export const FLAGR_PRODUCT_SOLD_THRESHOLD = process.env.REACT_APP_FLAGR_PRODUCT_SOLD_THRESHOLD;
export const FLAGR_REFETCH_INTERVAL = process.env.REACT_APP_FLAGR_REFETCH_INTERVAL;
export const FLAGR_REGISTER_POINT = process.env.REACT_APP_FLAGR_REGISTER_POINT;
export const FLAGR_REPAYMENT_V2 = process.env.REACT_APP_FLAGR_REPAYMENT_V2;
export const FLAGR_SELF_TRANSACTION_V2 = process.env.REACT_APP_FLAGR_SELF_TRANSACTION_V2;
export const FLAGR_SUPPORT_INFO_V2 = process.env.REACT_APP_FLAGR_SUPPORT_INFO_V2;
export const FLAGR_URL = process.env.REACT_APP_FLAGR_URL;
export const FLAGR_URL_V2 = process.env.REACT_APP_FLAGR_URL_V2;
export const FORM_COMPLAINT = process.env.REACT_APP_FORM_COMPLAINT;
export const FORM_KABAYAN_EXPRESS = process.env.REACT_APP_FORM_KABAYAN_EXPRESS;
export const FORM_LAPAK_IKAN = process.env.REACT_APP_FORM_LAPAK_IKAN;
export const GRAPH_API = process.env.REACT_APP_GRAPH_API;
export const GROWTH_REMOTE_URL = process.env.REACT_APP_GROWTH_REMOTE_URL;
export const GTM_CODE = process.env.REACT_APP_GTM_CODE;
export const IMPROVEMENT_OCR_TRACKER_GTM = ['true', '1'].includes(
  process.env.REACT_APP_IMPROVEMENT_OCR_TRACKER_GTM || '',
);
export const KMP_FARMER_IDS = process.env.REACT_APP_KMP_FARMER_IDS;
export const LOYALTY_DIVIDER = process.env.REACT_APP_LOYALTY_DIVIDER;
export const MODE = process.env.REACT_APP_MODE;
export const OCR_TRACKER_GTM = ['true', '1'].includes(process.env.REACT_APP_OCR_TRACKER_GTM || '');
export const OCTO_SERVICE = process.env.REACT_APP_OCTO_SERVICE;
export const PAYMENT_EXPIRED_DURATION = process.env.REACT_APP_PAYMENT_EXPIRED_DURATION;
export const PAYMENT_GATEWAY = process.env.REACT_APP_PAYMENT_GATEWAY;
export const PRIVACY_POLICY = ['true', '1'].includes(process.env.REACT_APP_PRIVACY_POLICY || '');
export const PRIVACY_POLICY_PAGE = process.env.REACT_APP_PRIVACY_POLICY_PAGE;
export const PROMOTION_SERVICE = process.env.REACT_APP_PROMOTION_SERVICE;
export const RECOMMENDATION_SERVICES = process.env.REACT_APP_RECOMMENDATION_SERVICES;
export const REPAYMENT_GUIDE_URL = process.env.REACT_APP_REPAYMENT_GUIDE_URL;
export const REQUEST_SIGNATURE_SECRET = process.env.REACT_APP_REQUEST_SIGNATURE_SECRET;
export const SAWARNA_V2_SERVICES = process.env.REACT_APP_SAWARNA_V2_SERVICES;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SUPPORT_MALL_URL = process.env.REACT_APP_SUPPORT_MALL_URL;
export const TEMPLATE_PROCURATION_URL = process.env.REACT_APP_TEMPLATE_PROCURATION_URL;
export const USER_SERVICE = process.env.REACT_APP_USER_SERVICE;
export const WA_TESTING = process.env.REACT_APP_WA_TESTING;

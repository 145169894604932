import { MODE } from 'env';
import TagManager from 'react-gtm-module';
import { parseJWTGeneral } from 'utils';

import { UTM_PARAMS } from 'routes/history';
import { MerkleTrakerProps } from 'types/Merkle';
import { CommerceItem, ItemListId, ItemListName } from 'types/Merkle/commerce';
import { DataLayerSetUserId } from 'types/Merkle/user';
import { APP_VERSION } from 'utils/constants';

type TrackerProps = {
  dataLayer: {
    event: string;
    eventCategory: string;
    eventLabel: string;
    widgetId?: string;
  };
};

export const dataLayer = async (currObj: TrackerProps) => {
  try {
    const dataUser = parseJWTGeneral(window.token);
    const currentVersion = window?.appVersion || '';
    const customDeviceId = window?.customDeviceId || '';
    const customDeviceIdNew = window?.customDeviceIdNew || '';
    const customDeviceIdSD = window?.customDeviceIdSD || '';
    const leadId = (dataUser && dataUser.lead_id) || '';
    const utmParams = {};

    for (const key of UTM_PARAMS) {
      if (sessionStorage.getItem(key)) utmParams[key] = sessionStorage.getItem(key);
    }

    const tempObj = {
      ...currObj,
      dataLayer: {
        ...currObj.dataLayer,
        userId: leadId,
        customDeviceId,
        customDeviceIdNew,
        customDeviceIdSD,
        webAppVersion: APP_VERSION || '',
        appVersion: currentVersion || '',
        utmParams,
        widgetId: currObj.dataLayer.widgetId || undefined,
      },
    };

    // GTM
    TagManager.dataLayer(tempObj);
    if (process.env.NODE_ENV !== 'test' && MODE === 'staging') {
      console.debug('🏷 [GTM]', tempObj.dataLayer.event, tempObj.dataLayer);
    }
  } catch (err) {
    console.error(err);
  }
};

// Send post message to android wrapper if available
const logEvent = (name?: String, params?: MerkleTrakerProps['dataLayer']) => {
  if (!name) return;

  const port = window.postMessageTwa;

  if (typeof port === 'undefined') return;

  const message = {
    command: 'logEvent',
    name,
    parameters: params,
  };

  port?.postMessage?.(JSON.stringify(message));
};

const setUserId = (id: String) => {
  const port = window.postMessageTwa;

  if (typeof port === 'undefined') return;

  const message = {
    command: 'setUserId',
    name: null,
    parameters: id,
  };

  port?.postMessage?.(JSON.stringify(message));
};

const setUserProperty = (name: String, params: String) => {
  const port = window.postMessageTwa;

  if (typeof port === 'undefined') return;

  const message = {
    command: 'setUserProperty',
    name,
    parameters: params,
  };

  port?.postMessage?.(JSON.stringify(message));
};

export const dataLayerMerkleSetUserId = (
  id: MerkleTrakerProps<DataLayerSetUserId>['dataLayer']['id'],
) =>
  dataLayerMerkle({
    dataLayer: {
      event: 'setUserId',
      id,
    },
  });

export const dataLayerMerkleSetUserProperty = (properties: {
  name: String | null;
  email: String | null;
  phone: String | null;
  point_code: String | null;
  point_name: String | null;
  lead_id: String | null;
  is_kabayan: String | null;
}) => {
  const setProperties = Object.keys(properties).map((key) =>
    dataLayerMerkle({
      dataLayer: {
        event: 'setUserProperty',
        property_name: key,
        property: properties[key],
      },
    }),
  );

  return Promise.all(setProperties);
};

export const dataLayerMerkle = async (currObj: MerkleTrakerProps) => {
  try {
    const customDeviceId = window?.customDeviceId || '';
    const customDeviceIdNew = window?.customDeviceIdNew || '';
    const customDeviceIdSD = window?.customDeviceIdSD || '';
    const dataUser = parseJWTGeneral(window.token);
    const leadId = (dataUser && dataUser.lead_id) || '';

    const tempObj = {
      ...currObj,
      dataLayer: {
        ...currObj.dataLayer,
        userId: leadId,
        page_location: window.location.href,
        customDeviceId,
        customDeviceIdNew,
        customDeviceIdSD,
        cd_device_id: customDeviceId,
        cd_device_id_new: customDeviceIdNew,
        cd_device_id_sd: customDeviceIdSD,
      },
    };

    // GTM
    if (window.postMessageTwa) {
      // log event from wrapper analytics
      const obj = { ...tempObj.dataLayer };
      const { event: eventName, ...eventData } = obj;

      if (eventName === 'setUserId') {
        setUserId(eventData['id']);
        return;
      }
      if (eventName === 'setUserProperty') {
        setUserProperty(eventData['property_name'], eventData['property']);
        return;
      }
      logEvent(eventName, eventData as MerkleTrakerProps['dataLayer']);
    } else {
      TagManager.dataLayer(tempObj);
    }

    if (process.env.NODE_ENV !== 'test' && MODE === 'staging') {
      console.debug('🏷 [MERKLE_GTM]', tempObj.dataLayer.event, tempObj.dataLayer);
    }
  } catch (error) {
    console.error(error);
  }
};

// normalizer Product Catalog
export const normalizerCommerceItem = (
  items: any[],
  sectionId: ItemListId,
  sectionName: ItemListName,
): CommerceItem[] => {
  return items.map((item, index) => ({
    index,
    price: item.cashPrice,
    affiliation: item.supplier?.name,
    item_brand: item.product?.brand,
    item_category: item.product?.category,
    quantity: 1,
    item_id: item.id?.toString(),
    item_list_id: sectionId,
    item_list_name: sectionName,
    item_name: item.product?.name,
    item_variant: `1 ${item.product.unit} / ${item.product.quantityUnit} Kg`,
  }));
};

const GTMManager = {
  dataLayer,
  dataLayerMerkle,
};

export default GTMManager;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';
import OnPaymentPage from './OnPaymentPage';

const BillHistoryPage = loadable(
  () => import(/* webpackChunkName: "BillHistoryPage" */ './BillHistoryPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const BillCreatePage = loadable(
  () => import(/* webpackChunkName: "BillCreatePage" */ './BillCreatePage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const billRoutes: Route[] = [
  {
    name: 'Bill Create',
    isPublic: false,
    routeProps: {
      exact: false,
      path: PATH.BILL_CREATE,
      component: BillCreatePage,
    },
  },
  {
    name: 'Bill History',
    isPublic: false,
    routeProps: {
      exact: false,
      path: PATH.BILL_HISTORY,
      component: BillHistoryPage,
    },
  },
  {
    name: 'Bill On Payment',
    isPublic: false,
    routeProps: {
      exact: false,
      path: PATH.BILL_ONPAYMENT,
      component: OnPaymentPage,
    },
  },
];

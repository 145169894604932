import { AUTH_V2_PHONE_PREFIX, FEATURE_AUTH_V2, FEATURE_ENABLED_ENHANCE_PERFORMANCE } from 'env';

import { useSnackbar } from 'components/Snackbar';
import { ModalType } from 'features/home/constants';
import { removeModalStatus } from 'features/home/hooks/useActivePopup';
import {
  _hasBlockNotif,
  _hasSubscribeCT,
  tokenAuth,
  tokenAuthKolamku,
  userKabayanData,
  userProfileData,
} from 'utils/constants/cookies';
import { dataLayerMerkleSetUserId, dataLayerMerkleSetUserProperty } from 'utils/helpers/tracking';
import useAuthContext from 'utils/hooks/useAuthContext';
import useFlagrContext from 'utils/hooks/useFlagrContext';

import { useLogoutMutation } from '../graphql/auth.v2.gen';

const PREFIXES = (AUTH_V2_PHONE_PREFIX || '').split(',');

export const removeCookiesAndRedirect = (href = '/') => {
  if (FEATURE_ENABLED_ENHANCE_PERFORMANCE) {
    document.cookie = `${userProfileData}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `${userKabayanData}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
  document.cookie = `${tokenAuth}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${tokenAuthKolamku}=; Domain=.efishery.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${_hasBlockNotif}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${_hasSubscribeCT}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  window.location.href = href;
};

export const useLogout = (options: Parameters<typeof useLogoutMutation>[0] = {}) => {
  const { addToast } = useSnackbar();
  const { isEnableUpdateNpwp } = useFlagrContext();

  const { phone } = useAuthContext().userProfile;
  const isEnableAuthV2 = PREFIXES.some((prefix) => phone?.startsWith(prefix));

  const logoutMutation = useLogoutMutation({
    ...options,
    onSuccess: () => {
      removeCookiesAndRedirect();
    },
    onError: (err: any) => {
      if (err?.response?.status === 400) {
        // When user login using auth v1, but logout using auth v2
        return removeCookiesAndRedirect();
      }
      addToast('Gagal melakukan logout');
    },
  });

  const logout = async () => {
    await dataLayerMerkleSetUserId(null);
    await dataLayerMerkleSetUserProperty({
      name: null,
      email: null,
      phone: null,
      point_code: null,
      point_name: null,
      lead_id: null,
      is_kabayan: null,
    });

    if (isEnableUpdateNpwp) removeModalStatus(ModalType.UPDATE_NPWP);

    if (FEATURE_AUTH_V2 && isEnableAuthV2) logoutMutation.mutate({});
    else removeCookiesAndRedirect();
  };

  return [logout, logoutMutation] as const;
};

import { styled } from 'twin.macro';

export const Container = styled.div`
  background-color: white;
`;

export const Content = styled.div`
  margin-top: 83px;
  min-height: 100vh;
  padding: 0 16px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  text-align: center;
`;

export const Banner = styled.img`
  margin: 20px 0;
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  background-color: white;
  padding: 7px 16px;
  color: #038767;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  align-item: center;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
`;

export const Link = styled.a`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  background-color: white;
  padding: 7px 16px;
  color: #038767;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  align-item: center;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

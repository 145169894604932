import { CRM_SERVICE, FIREBASE_CLOUD_MESSAGING, PRIVACY_POLICY } from 'env';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  deleteToken as deleteTokenFCM,
  getMessaging,
  getToken as getTokenFCM,
  isSupported,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import { GraphQLClient } from 'graphql-request';
import { Cookies } from 'react-cookie';

import {
  trackNotificationBackground,
  trackNotificationForeground,
  trackNotificationOpen,
  trackPushNotificationBeluga,
} from 'features/notification/utils/trackers';
import { isSupportNotification } from 'utils/constants';
import { fcmToken, pushNotifTokenNative, tokenAuth } from 'utils/constants/cookies';

import saveNotifTokenMutationV2 from '../mutation/saveNotifTokenV2';
import { getMonthFromNow } from './date';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

let firebaseApp: FirebaseApp | undefined;

let messaging: Messaging | undefined;

interface ISaveTokenVar {
  token: string;
  replaceToken?: string;
}

export const saveTokenToServer = async (token: string, replaceToken?: string) => {
  // get the authentication token from cookies if it exists
  const cookies = new Cookies();
  const authToken = cookies.get(tokenAuth);

  const client = new GraphQLClient(process.env.REACT_APP_SAWARNA_V2_SERVICES || '');
  const requestHeaders = {
    ...(authToken && { authorization: `Bearer ${authToken}` }),
  };

  const variables: ISaveTokenVar = { token };

  if (replaceToken) {
    variables.replaceToken = replaceToken;
    cookies.set(pushNotifTokenNative, token, { path: '/', expires: getMonthFromNow(24) });
  }

  const resp = await client.request(saveNotifTokenMutationV2, variables, requestHeaders);
  return resp;
};

export const getNativePNTOken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has('token_native')) return urlSearchParams.get('token_native');
  // then check from cookies
  const cookies = new Cookies();
  const nativeTokenFromCookies = cookies.get(pushNotifTokenNative) || null;
  return nativeTokenFromCookies;
};

const RECEIVE_TYPE = 'RECEIVE';

let prevMessageId = '';

export const receiveMessage = () => {
  if (!messaging) return;
  // [START messaging_receive_message]
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker

  onMessage(messaging, (payload) => {
    try {
      if (prevMessageId === payload?.messageId) return;

      const { body, image, tag, title, redirectURL, tracking_id: trackingID } = payload.data as any;

      const inputNotif = {
        tracking_id: trackingID,
        type: RECEIVE_TYPE,
        title,
        body,
      };

      trackPushNotificationBeluga(inputNotif);
      trackNotificationForeground(title);

      const notificationTitle = title;
      const notificationOptions = {
        body,
        image,
        tag,
        badge: '/android-chrome-192x192.png',
        icon: '/android-chrome-192x192.png',
        data: { redirectURL },
      };

      navigator.serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then((registration) => {
          registration?.showNotification(notificationTitle, notificationOptions);
        });

      prevMessageId = payload.messageId;
    } catch (e) {
      console.log('error onmessage', e);
    }
  });
  // [END messaging_receive_message]
};

export const subscribeTokenToTopicPrivacyPolicy = (token: string) => {
  fetch(`${CRM_SERVICE}/v1/privacy-policies/subscribe`, {
    method: 'POST',
    body: JSON.stringify({
      token,
      source_app: 'EFISHERYKU',
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw new Error(`Error subscribing to topic: ${response.status} - ${response.text()}`);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getToken = async () => {
  if (!messaging) return;
  const token = await getTokenFCM(messaging, {
    vapidKey: FIREBASE_CLOUD_MESSAGING,
  });

  if (token && PRIVACY_POLICY) subscribeTokenToTopicPrivacyPolicy(token);

  if (!token) {
    console.log('No registration token available. Request permission to generate one.');
    return '';
  }

  return token;
};

export const requestPermission = async () => {
  const cookies = new Cookies();
  const fcmTokenCookie = cookies.get(fcmToken);

  if (!isSupportNotification) {
    cookies.remove(fcmToken);
    return '';
  }

  // [START messaging_request_permission]
  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
    console.log('Unable to get permission to notify.');
    cookies.remove(fcmToken);
    return '';
  }
  console.log('Notification permission granted.');
  // TODO(developer): Retrieve a registration token for use with FCM.
  receiveMessage();
  const token = await getToken();

  if (fcmTokenCookie && fcmTokenCookie === token) return fcmTokenCookie;

  if (token) {
    // save token to server
    saveTokenToServer(token);
    cookies.set(fcmToken, token, { path: '/', expires: getMonthFromNow(24) });
  }

  return token;

  // [END messaging_request_permission]
};

export const deleteToken = () => {
  if (!messaging) return;
  // [START messaging_delete_token]
  deleteTokenFCM(messaging)
    .then(() => {
      console.log('Token deleted.');
      // ...
    })
    .catch((err) => {
      console.log('Unable to delete token. ', err);
    });
  // [END messaging_delete_token]
};

export const initBroadcastChannel = (): void => {
  // Create an environment-specific channel name
  const channel = new BroadcastChannel(`eFiku_SW_Broadcast_${process.env.REACT_APP_MODE}`);

  channel.addEventListener('message', (event) => {
    // adds an extra layer of security to prevent cross-origin message injection.
    const isSameOrigin = event.data.origin == self.location.origin;

    if (event.data && event.data.type === 'TRACK_NOTIFICATION' && isSameOrigin) {
      const { type, title } = event.data.payload;
      switch (type) {
        case 'background':
          trackNotificationBackground(title);
          break;
        case 'open':
          trackNotificationOpen(title);
          break;
      }
    }
  });
};

const initFirebase = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
    isSupported().then((hasSupported) => {
      if (hasSupported) {
        messaging = getMessaging(firebaseApp);
        initBroadcastChannel();
      }
    });
  }
};

export const registerFirebase = async () => {
  try {
    initFirebase();
    const nativeToken = getNativePNTOken();

    if (nativeToken) {
      // replace token on DB + delete web token
      const webToken = await getToken();
      await saveTokenToServer(nativeToken, webToken);

      if (PRIVACY_POLICY) subscribeTokenToTopicPrivacyPolicy(nativeToken);

      return nativeToken;
    }
    // do the existing logic
    const token = await requestPermission();
    return token;
  } catch (err) {
    console.error(err, 'err notif firebase');
  }
};

export const refreshToken = async () => {
  const nativeToken = getNativePNTOken();

  if (nativeToken) {
    // replace token on DB + delete web token
    const webToken = await getToken();
    await saveTokenToServer(nativeToken, webToken);

    if (PRIVACY_POLICY) subscribeTokenToTopicPrivacyPolicy(nativeToken);

    return nativeToken;
  }
  // do the existing logic
  const token = await requestPermission();
  return token;
};

import { A, flow, R, S } from '@mobily/ts-belt';

const replaceMinToPlus = S.replace('-', '+');
const replacePlusToMin = S.replace('+', '-');

const replaceUnderscoreToSlash = S.replace('_', '/');
const replaceSlashToUnderscore = S.replace('/', '_');

const splitByDot = S.split('.');
const joinByDot = A.join('.');

const addPadding = (s: string) => s.concat('='.repeat((4 - (s.length % 4)) % 4));
const removePadding = S.replaceByRe(/=+$/, '');

// https://stackoverflow.com/questions/13607921/removing-non-latin-characters-from-a-string
const removeNonLatinChars = S.replaceByRe(/[\u0250-\ue007]/g, '');

export const defaultHeader = { alg: 'HS256', typ: 'JWT' };

const decode = flow(
  splitByDot,
  ([a, b]) => [a, addPadding(b)],
  A.map(replaceMinToPlus),
  A.map(replaceUnderscoreToSlash),
  A.map(atob),
  A.map(JSON.parse),
  ([header, payload]) => ({ header, payload }),
);

const encode: <Payload = unknown, Header = unknown>(payload: Payload, header?: Header) => string =
  flow(
    (payload, header) => ({ header, payload }),
    ({ header, payload }) => [header || defaultHeader, payload],
    A.map(JSON.stringify),
    A.map(removeNonLatinChars),
    A.map(btoa),
    A.map(replaceSlashToUnderscore),
    A.map(replacePlusToMin),
    ([a, b]) => [a, removePadding(b)],
    joinByDot,
  );

export const encodeJWT = encode;

const decodeResult = (token: string) => R.fromExecution(() => decode(token));

export const decodeJWT = flow(
  decodeResult,
  R.getWithDefault({ payload: null }),
  ({ payload }) => payload,
);

export const isNotExpiredJWT = flow(
  decodeResult,
  R.getWithDefault({ payload: { exp: null } }),
  ({ payload: { exp } }) => (exp ? exp > new Date().getUTCSeconds() : false),
);

export const parseJWTGeneral = decodeJWT;

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ModalType } from 'features/home/constants';
import { userApi } from 'utils/api-client';
import { isNotEmptyObject } from 'utils/helpers/assertion';
import useAuthContext from 'utils/hooks/useAuthContext';
import useFlagrContext from 'utils/hooks/useFlagrContext';

const CACHE_TIME = 24 * 60 * 60 * 1000; // 24 hours

interface PopupInfo {
  userTransactionCount: number;
  npwpNumber: string;
  npwpType: string;
}

const getPopupInfo = async (): Promise<PopupInfo> => {
  const response = await userApi.get('/v1/pop-up/info');
  return response.data;
};

export const setModalStatus = (key: ModalType) => {
  window.sessionStorage.setItem(key, '1');
};

export const getModalStatus = (key: ModalType): boolean => {
  return window.sessionStorage.getItem(key) === '1';
};

export const removeModalStatus = (key: ModalType) => {
  window.sessionStorage.removeItem(key);
};

const determineActivePopup = (
  data: PopupInfo,
  isEnableUpdateNpwp: boolean,
  isActivePopupHomepage: boolean,
): ModalType | null => {
  const { userTransactionCount, npwpNumber, npwpType } = data;
  if (
    isEnableUpdateNpwp &&
    npwpNumber &&
    !npwpType &&
    userTransactionCount > 0 &&
    !getModalStatus(ModalType.UPDATE_NPWP)
  ) {
    return ModalType.UPDATE_NPWP;
  }

  if (isActivePopupHomepage && !getModalStatus(ModalType.POPUP_HOMEPAGE)) {
    return ModalType.POPUP_HOMEPAGE;
  }

  if (
    !isActivePopupHomepage &&
    userTransactionCount === 0 &&
    !getModalStatus(ModalType.ORDER_REMINDER)
  ) {
    return ModalType.ORDER_REMINDER;
  }

  return null;
};

export const useActivePopup = () => {
  const { isLogin } = useAuthContext();
  const { activePopupHomepage, isEnableUpdateNpwp } = useFlagrContext();
  const isActivePopupHomepage = isNotEmptyObject(activePopupHomepage);
  const [activePopup, setActivePopup] = useState<ModalType | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery<PopupInfo>(['popup-info'], getPopupInfo, {
    enabled: isLogin,
    cacheTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });

  useEffect(() => {
    if (data) {
      setActivePopup(determineActivePopup(data, isEnableUpdateNpwp, isActivePopupHomepage));
    }
  }, [data, isActivePopupHomepage, isEnableUpdateNpwp]);

  useEffect(() => {
    if (activePopup) {
      setIsOpen(true);
    }
  }, [activePopup]);

  const handleClose = () => setIsOpen(false);

  return {
    activePopup,
    userTransactionCount: data?.userTransactionCount ?? 0,
    npwpNumber: data?.npwpNumber ?? '',
    npwpType: data?.npwpType ?? '',
    isOpen,
    handleClose,
  };
};

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const OrderPaymentPage = loadable(
  () => import(/* webpackChunkName: "OrderPaymentPage" */ 'features/payment/components/payment'),
  {
    fallback: <LoadingScreen />,
  },
);

const OrderPaymentSuccessPage = loadable(
  () =>
    import(
      /* webpackChunkName: "OrderPaymentPage" */ 'features/payment/components/payment-success'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

export const paymentRoutes: Route[] = [
  {
    name: 'Order Payment',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.ORDER_PAYMENT,
      component: OrderPaymentPage,
    },
  },
  {
    name: 'Order Success',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.ORDER_PAYMENT_SUCCESS,
      component: OrderPaymentSuccessPage,
    },
  },
];

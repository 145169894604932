export function getFirstItem<T>(array: T[]): T | undefined {
  return array != null && array.length ? array[0] : undefined;
}

export function getLastItem<T>(array: T[]): T | undefined {
  const length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

export function getPrevItem<T>(index: number, array: T[], loop = true): T | undefined {
  const prevIndex = getPrevIndex(index, array.length, loop);
  return array[prevIndex];
}

export function getNextItem<T>(index: number, array: T[], loop = true): T | undefined {
  const nextIndex = getNextIndex(index, array.length, 1, loop);
  return array[nextIndex];
}

export function removeIndex<T>(array: T[], index: number): T[] {
  return array.filter((_, idx) => idx !== index);
}

export function addItem<T>(array: T[], item: T): T[] {
  return [...array, item];
}

export function removeItem<T>(array: T[], item: T): T[] {
  return array.filter((eachItem) => eachItem !== item);
}

/**
 * Get the next index based on the current index and step.
 *
 * @param {number} currentIndex the current index
 * @param {number} length the total length or count of items
 * @param {number} step the number of steps
 * @param {boolean} loop whether to circle back once `currentIndex` is at the start/end
 */
export function getNextIndex(currentIndex, length, step = 1, loop = true) {
  const lastIndex = length - 1;

  if (currentIndex === -1) {
    return step > 0 ? 0 : lastIndex;
  }

  const nextIndex = currentIndex + step;

  if (nextIndex < 0) {
    return loop ? lastIndex : 0;
  }

  if (nextIndex >= length) {
    if (loop) return 0;
    return currentIndex > length ? length : currentIndex;
  }

  return nextIndex;
}

/**
 * Get's the previous index based on the current index.
 * Mostly used for keyboard navigation.
 *
 * @param {number} index - the current index
 * @param {number} count - the length or total count of items in the array
 * @param {boolean} loop - whether we should circle back to the
 * first/last once `currentIndex` is at the start/end
 */
export function getPrevIndex(index, count, loop = true) {
  return getNextIndex(index, count, -1, loop);
}

/**
 * Converts an array into smaller chunks or groups.
 *
 * @param {array} array the array to chunk into group
 * @param {number} size the length of each chunk
 */
export function chunk(array, size) {
  return array.reduce((rows, currentValue, index) => {
    if (index % size === 0) {
      rows.push([currentValue]);
    } else {
      rows[rows.length - 1].push(currentValue);
    }
    return rows;
  }, []);
}

/**
 * Callback item to string
 *
 * @callback itemToStringCallback
 * @param {*} items
 * @returns {string}
 */

/**
 * Gets the next item based on a search string
 *
 * @function
 * @param {array} items array of items
 * @param {string} searchString the search string
 * @param {itemToStringCallback} itemToString resolves an item to string
 * @param {*} currentItem the current selected item
 */
export function getNextItemFromSearch(items, searchString, itemToString, currentItem) {
  if (searchString == null) {
    return currentItem;
  }

  // If current item doesn't exist, find the item that matches the search string
  if (!currentItem) {
    return items.find((item) =>
      itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()),
    );
  }

  // Filter items for ones that match the search string (case insensitive)
  const matchingItems = items.filter((item) =>
    itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()),
  );

  // If there's a match, let's get the next item to select
  if (matchingItems.length > 0) {
    let nextIndex;

    // If the currentItem is in the available items, we move to the next available option
    if (matchingItems.includes(currentItem)) {
      const currentIndex = matchingItems.indexOf(currentItem);
      nextIndex = currentIndex + 1;
      if (nextIndex === matchingItems.length) {
        nextIndex = 0;
      }
      return matchingItems[nextIndex];
    }
    // Else, we pick the first item in the available items
    nextIndex = items.indexOf(matchingItems[0]);
    return items[nextIndex];
  }

  // a decent fallback to the currentItem
  return currentItem;
}

/**
 * mapping splitted version to at least has 3 elements ['x', 'x', 'x']
 *
 * @function
 * @param {array} versionRef array of splitted version
 *
 */
export const mappingVersions = (version) => {
  const result = version.split('.').map((num) => (num ? Number(num) : 0));
  if (result.length < 3) {
    for (let i = 0; i < 3; i++) {
      result.push(0);
      if (result.length === 3) break;
    }
  }
  return result;
};

export function arrayMoveElement<T>(arr: T[], fromIndex: number, toIndex: number): T[] | undefined {
  if (toIndex >= arr.length) return;
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

import { createContext, FunctionComponent } from 'react';

import useAuth, { IUseAuth } from 'utils/hooks/useAuth';

const initialContext: IUseAuth = {
  isLogin: false,
  isFetched: false,
  isLoading: false,
  token: '',
  error: false,
  getProfile: () => {},
  refreshKabayan: () => {},
  refreshProfile: () => {},
  userProfile: {},
};

export const AuthContext = createContext<IUseAuth>(initialContext);

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider: FunctionComponent<AuthProps> = ({ children }) => {
  const obj = useAuth();

  return <AuthContext.Provider value={obj}>{children}</AuthContext.Provider>;
};

import React from 'react';
import loadable from '@loadable/component';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const KBXRegisterPages = loadable(
  () => import(/* webpackChunkName: "KBXRegisterPages" */ './PageRegister'),
  {
    fallback: <LoadingScreen />,
  },
);

export const kbxRoute = {
  name: 'Kabayan Register',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.KABAYAN_REGISTER,
    component: KBXRegisterPages,
  },
};

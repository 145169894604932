import { useHistory } from 'react-router-dom';

const useBack = () => {
  const history = useHistory();

  const handleBack = () => {
    if (history.length > 2) return history.goBack();

    return history.push('/');
  };

  return {
    handleBack,
  };
};

export default useBack;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const LapakIkanPages = loadable(
  () => import(/* webpackChunkName: "LapakIkanPages" */ './LapakIkanPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const LapakIkanRegisterFormPages = loadable(
  () => import(/* webpackChunkName: "LapakIkanRegisterFormPages" */ './RegisterPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const LapakIkanVerificationPage = loadable(
  () => import(/* webpackChunkName: "LapakIkanVerificationPage" */ './VerificationPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const LapakIkanContractStockPage = loadable(
  () => import(/* webpackChunkName: "LapakIkanContractStockPage" */ './ContractPage'),
  {
    fallback: <LoadingScreen />,
  },
);
export const lapakIkanRoutes: Route[] = [
  {
    routeProps: {
      exact: true,
      path: PATH.LAPAK_IKAN_REGISTER,
      component: LapakIkanRegisterFormPages,
    },
    isPublic: false,
    name: 'Lapak Ikan Register',
  },
  {
    routeProps: {
      exact: true,
      path: PATH.LAPAK_IKAN_VERIFICATION,
      component: LapakIkanVerificationPage,
    },
    isPublic: false,
    name: 'Lapak Ikan Verification',
  },
  {
    routeProps: {
      exact: true,
      path: PATH.LAPAK_IKAN_CONTRACT,
      component: LapakIkanContractStockPage,
    },
    isPublic: false,
    name: 'Lapak Ikan Contract Stock',
  },
  {
    routeProps: {
      exact: false,
      path: PATH.LAPAK_IKAN,
      component: LapakIkanPages,
    },
    isPublic: false,
    name: 'Lapak Ikan',
  },
];

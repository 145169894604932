import React, { useEffect, useState } from 'react';
import {
  FEATURE_FORCE_UPDATE,
  FEATURE_KABAYAN_ENHANCEMENT_OCR,
  FLAGR_BLACKBOX,
  FLAGR_FARMER_BALANCE,
  FLAGR_OCR_KTP,
} from 'env';
import { any } from 'prop-types';

import { DEFAULT_SHOP_VIA_CHAT_URL } from 'features/beli-pakan/constants';
import { DEFAULT_SUPPORT_INFO } from 'features/help/constants';
import { flagr } from 'utils/constants';
import { getMaintenanceContentV2 } from 'utils/helpers/flagr';
import { parseYoutubeId } from 'utils/helpers/youtube';
import useAuthContext from 'utils/hooks/useAuthContext';
import { getAllEfisheryKuFlagrV2 } from 'utils/services/flagrServices';

let skipErrFlagr = false;
let idleId = null;

const {
  FLAGR_BELI_PAKAN,
  FLAGR_COMPLAINT_FORM,
  FLAGR_CULTIVATION_FORM,
  FLAGR_DEMO_ACCOUNT,
  FLAGR_E_NOTA,
  FLAGR_FEEDBACK_CUSTOMER,
  FLAGR_FILTER_PRODUCT_CATEGORIES,
  FLAGR_FILTER_SUPPLIER,
  FLAGR_FORCE_UPDATE,
  FLAGR_KABAYAN_ELLIGIBLE_CHECK,
  FLAGR_KABAYAN_EXPRESS,
  FLAGR_KABAYAN_STATUS,
  FLAGR_KBR_SUBMISSION,
  FLAGR_KBR_SUBMISSION_POINTS,
  FLAGR_LAPAK_IKAN,
  FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN,
  FLAGR_MAINTENANCE,
  FLAGR_MAINTENANCE_BELI_PAKAN,
  FLAGR_MAINTENANCE_EFEEDER,
  FLAGR_MAINTENANCE_LAPAK_IKAN,
  FLAGR_OTP_SMS,
  FLAGR_OTP_WA,
  FLAGR_PAY_KABAYAN,
  FLAGR_PAYMENT_EXPIRED_DURATION,
  FLAGR_PCFA_CONTACT,
  FLAGR_REPAYMENT,
  FLAGR_SELF_TRANSACTION,
  FLAGR_SUPPORT_INFO,
  FLAGR_COMPLAINT_REQUEST_TOKEN,
  FLAGR_OCR_KK,
  FLAGR_BELANJA_PAKAN_VIA_CHAT,
  FLAGR_EARLY_PAYMENT,
  FLAGR_PRODUCT_SOLD_THRESHOLD,
  FLAGR_LOYALTY_ENTRY,
  FLAGR_REGISTER_POINT,
  FLAGR_KBR_FORM,
  FLAGR_PRIVACY_POLICY,
  FLAGR_ACTIVE_PRODUCT_CATEGORIES,
  FLAGR_COMPLAINT_HANDLING_V2,
  FLAGR_FMS_V2,
  FLAGR_ON_REAPPLY,
  FLAGR_ENABLE_FILTER_POINT_ACTIVE,
  FLAGR_ENABLE_POPUP_UPDATE_NPWP,
  FLAGR_HIDE_STATUS,
  FLAGR_ENABLE_POPUP_HOMEPAGE,
} = flagr;

const requestIdleCallback =
  window.requestIdleCallback ||
  ((cb) =>
    setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining: () => 0,
      });
    }, 1));

const DEFAULT_CS_PHONE = '02230509969';

export const defaultContext = {
  isFlagrFetched: false,
  isDemoAccount: false,
  isKabexActive: false,
  isLapakIkanActive: false,
  isPayKabayan: false,
  isENota: false,
  isBeliPakanActive: false,
  isRepaymentActive: false,
  isSelfTransactionActive: false,
  filterSuppliers: [],
  paymentExpiredDuration: 0,
  isMaintenance: false,
  maintenanceSpecificPage: {},
  activeProductCategories: [],
  maintenanceFinish: '',
  appVersions: {
    playStoreVersion: '',
    minimumVersion: '',
  },
  isForceUpdateActive: false,
  kabayanStatusConfig: {},
  contacts: { cs: DEFAULT_CS_PHONE },
  otpEnabled: { wa: true, sms: true },
  supportInfo: DEFAULT_SUPPORT_INFO,
  errors: null,
  kbrSubmission: {
    enabled: false,
    youtubeUrl: '',
    homeYoutubeUrl: '',
    homeYoutubeThumbUrl: '',
  },
  isNeedToCheckKabayanElligible: false,
  isEnableShopViaChat: false,
  shopViaChatUrl: DEFAULT_SHOP_VIA_CHAT_URL,
  earlyPaymentEnabled: false,
  startSchedule: () => {},
  stopSchedule: () => {},
  fetchFlagrByPointIDGuestMode: (pointId) => {},
  productSoldThreshold: 10,
  feedbackCustomerEnabled: false,
  cultivationFormEnabled: false,
  isBlackboxPoint: false,
  isBalanceEnabled: false,
  isPrivacyPolicyEnabled: false,
  isComplaintHandlingV2Enabled: false,
  isFMSV2Enabled: false,
  isOnReapplyEnabled: false,
  isEnableFilterPointactive: false,
  isEnableUpdateNpwp: false,
  isHideStatus: false,
};

export const FlagrContextV2 = React.createContext(defaultContext);

export const FlagrProviderV2 = ({ children }) => {
  const { userProfile, isLoading, isLogin, isFetched } = useAuthContext();
  const [isDemoAccount, setIsDemoAccount] = useState(false);
  const [isLapakIkanActive, setIsLapakIkanActive] = useState(true);
  const [isPayKabayan, setIsPayKabayan] = useState(true);
  const [isEnablePayKabayan, setIsEnablePayKabayan] = useState(true);
  const [isENota, setIsENota] = useState(false);
  const [isKabexActive, setIsKabexActive] = useState(true);
  const [isRepaymentActive, setIsRepaymentActive] = useState(false);
  const [isBeliPakanActive, setIsBeliPakanActive] = useState(true);
  const [isSelfTransactionActive, setIsSelfTransactionActive] = useState(true);
  const [filterSuppliers, setFilterSuppliers] = useState([]);
  const [isFlagrFetched, setIsFlagrFetched] = useState(false);
  const [paymentExpiredDuration, setPaymentExpiredDuration] = useState(0);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceSpecificPage, setMaintenanceSpecificPage] = useState({});
  const [maintenanceFinish, setMaintenanceFinish] = useState('');
  const [activeProductCategories, setActiveProductCategories] = useState([]);
  const [appVersions, setAppVersions] = useState({});
  const [isForceUpdateActive, setIsForceUpdateActive] = useState(false);
  const [errors, setErrors] = useState(null);
  const [contacts, setContacts] = useState({ cs: DEFAULT_CS_PHONE });
  const [kabayanStatusConfig, setKabayanStatusConfig] = useState({});
  const [otpEnabled, setOtpEnabled] = useState({ wa: true, sms: true });
  const [supportInfo, setSupportInfo] = useState(DEFAULT_SUPPORT_INFO);
  const [kbrSubmission, setKbrSubmission] = useState({
    enabled: false,
    youtubeUrl: '',
    homeYoutubeUrl: '',
  });
  const [isKbrSubmissionPointActive, setIsKbrSubmissionPointActive] = useState(true);
  const [isNeedToCheckKabayanElligible, setNeedToCheckKabayanElligible] = useState(false);
  const [complaintFormEnabled, setComplaintFormEnabled] = useState(false);
  const [cultivationFormEnabled, setCultivationFormEnabled] = useState(false);
  const [feedbackCustomerEnabled, setFeedbackCustomerEnabled] = useState(false);
  const [complaintRequestTokenEnabled, setComplaintRequestTokenEnabled] = useState(false);
  const [ocrKkEnabled, setOcrKkEnabled] = useState(false);
  const [ocrKtpEnabled, setOcrKtpEnabled] = useState(false);
  const [isEnableShopViaChat, setIsEnableShopViaChat] = useState(false);
  const [shopViaChatUrl, setShopViaChatUrl] = useState(DEFAULT_SHOP_VIA_CHAT_URL);
  const [earlyPaymentEnabled, setEarlyPaymentEnabled] = useState(false);
  const [productSoldThreshold, setProductSoldThreshold] = useState(10);
  const [loyaltyEntryFlag, setLoyaltyEntryFlag] = useState(false);
  const [isEnableUpdateNpwp, setIsEnableUpdateNpwp] = useState(false);
  const [isBlackboxPoint, setIsBlackboxPoint] = useState(false);
  const [isBalanceEnabled, setIsBalanceEnabled] = useState(false);
  const [registerPointEnabled, setRegisterPointEnabled] = useState(undefined);
  const [isDisableKbrForm, setIsDisableKbrForm] = useState(false);
  const [isPrivacyPolicyEnabled, setIsPrivacyPolicyEnabled] = useState(false);
  const [newActiveProductCategories, setNewActiveProductCategories] = useState([
    {
      id: 1,
      label: 'Pakan',
      categories: ['pakan', 'fish feed'],
    },
  ]);
  const [isComplaintHandlingV2Enabled, setIsComplaintHandlingV2Enabled] = useState(false);
  const [isFMSV2Enabled, setisFMSV2Enabled] = useState(false);
  const [isOnReapplyEnabled, setIsOnReapplyEnabled] = useState(false);
  const [isEnableFilterPointactive, setIsEnableFilterPointactive] = useState(false);
  const [isHideStatus, setIsHideStatus] = useState(false);
  const [activePopupHomepage, setActivePopupHomepage] = useState({});

  const providerValue = {
    isFlagrFetched,
    isDemoAccount,
    isKabexActive: isKabexActive || isDemoAccount,
    isLapakIkanActive: isLapakIkanActive || isDemoAccount,
    isEnablePayKabayan,
    isPayKabayan: isPayKabayan || isDemoAccount,
    isENota: isENota || isDemoAccount,
    isBeliPakanActive: isBeliPakanActive || isDemoAccount,
    isRepaymentActive: isRepaymentActive || isDemoAccount,
    isSelfTransactionActive: isSelfTransactionActive || isDemoAccount,
    filterSuppliers,
    paymentExpiredDuration,
    isMaintenance,
    maintenanceSpecificPage,
    activeProductCategories,
    maintenanceFinish,
    appVersions,
    isForceUpdateActive,
    contacts: contacts || DEFAULT_CS_PHONE,
    kabayanStatusConfig,
    otpEnabled,
    supportInfo,
    errors,
    kbrSubmission: {
      enabled: kbrSubmission.enabled && isKbrSubmissionPointActive,
      youtubeUrl: kbrSubmission.youtubeUrl,
      homeYoutubeUrl: kbrSubmission.homeYoutubeUrl,
      homeYoutubeThumbUrl: `http://i3.ytimg.com/vi/${parseYoutubeId(
        kbrSubmission.homeYoutubeUrl,
      )}/hqdefault.jpg`,
    },
    isNeedToCheckKabayanElligible,
    complaintFormEnabled,
    cultivationFormEnabled,
    feedbackCustomerEnabled,
    complaintRequestTokenEnabled,
    ocrKkEnabled: FEATURE_KABAYAN_ENHANCEMENT_OCR && ocrKkEnabled,
    ocrKtpEnabled: FEATURE_KABAYAN_ENHANCEMENT_OCR && ocrKtpEnabled,
    isEnableShopViaChat,
    shopViaChatUrl,
    earlyPaymentEnabled,
    productSoldThreshold,
    loyaltyEntryFlag,
    isBlackboxPoint,
    isBalanceEnabled,
    registerPointEnabled,
    isDisableKbrForm,
    isPrivacyPolicyEnabled,
    newActiveProductCategories,
    isComplaintHandlingV2Enabled,
    isFMSV2Enabled,
    isOnReapplyEnabled,
    isEnableFilterPointactive,
    isEnableUpdateNpwp,
    isHideStatus,
    activePopupHomepage,
  };

  const isFlagrV2Enabled = (flag) => {
    return flag?.variantKey ? flag.variantKey !== 'off' : false;
  };

  const getFlagrV2ById = (data, id) => {
    return (
      data.find(({ flagID }) => {
        return Number(id) === flagID;
      }) || {}
    );
  };

  const getAllEfisheryKuFlagrData = async (user) => {
    try {
      const userPhoneNumber = user.phone;
      const userPointCodeNumber = Number(user.point?.code);
      const userPointName = user.point?.name;
      const userPointNameLowercase = userPointName && userPointName.toLowerCase();

      const { evaluationResults: data = [] } = await getAllEfisheryKuFlagrV2(user);

      // FLAGR_KABAYAN_ELLIGIBLE_CHECK
      const kabayanElligible = getFlagrV2ById(data, FLAGR_KABAYAN_ELLIGIBLE_CHECK);
      const isKabayanElligibleEnabled = isFlagrV2Enabled(kabayanElligible);
      const kabayanElligibleVariant = kabayanElligible.variantAttachment?.variant || [];
      const isKabayanElligibleActivated =
        isKabayanElligibleEnabled && kabayanElligibleVariant.includes(userPointCodeNumber);
      setNeedToCheckKabayanElligible(isKabayanElligibleActivated);

      // FLAGR_DEMO_ACCOUNT
      const demoAccounts = getFlagrV2ById(data, FLAGR_DEMO_ACCOUNT);
      const isDemoAccountsEnabled = isFlagrV2Enabled(demoAccounts);
      const demoAccountVariant = demoAccounts.variantAttachment?.variant || [];
      const isDemoAccountsActivated =
        isDemoAccountsEnabled && demoAccountVariant.includes(userPhoneNumber);
      setIsDemoAccount(isDemoAccountsActivated);

      // FLAGR_KABAYAN_EXPRESS
      const nonKabex = getFlagrV2ById(data, FLAGR_KABAYAN_EXPRESS);
      const isNonKabexEnabled = isFlagrV2Enabled(nonKabex);
      const nonKabexVariant = nonKabex.variantAttachment?.variant || [];
      if (isNonKabexEnabled || userPointCodeNumber == 0) {
        setIsKabexActive(
          !nonKabexVariant.includes(userPointCodeNumber) && userPointCodeNumber != 0,
        );
      }

      // FLAGR_LAPAK_IKAN
      const lapakIkan = getFlagrV2ById(data, FLAGR_LAPAK_IKAN);
      const isLapakIkanEnabled = isFlagrV2Enabled(lapakIkan);
      const lapakIkanVariant = lapakIkan.variantAttachment?.variant || [];
      if (isLapakIkanEnabled) {
        setIsLapakIkanActive(
          lapakIkanVariant.length === 0 || lapakIkanVariant.includes(userPointCodeNumber),
        );
      }

      // FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN
      const enablePayKabayan = getFlagrV2ById(data, FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN);
      const isEnableFlagPayKabayan = isFlagrV2Enabled(enablePayKabayan);
      setIsEnablePayKabayan(isEnableFlagPayKabayan);

      // FLAGR_PAY_KABAYAN
      const payKabayanPointsFlagr = getFlagrV2ById(data, FLAGR_PAY_KABAYAN);
      const isPayKabayanPoints = isFlagrV2Enabled(payKabayanPointsFlagr);
      if (isPayKabayanPoints) {
        const payKabayanPoints = payKabayanPointsFlagr.variantAttachment?.areaId || [];
        const isPointPayKabayan = payKabayanPoints.includes(userPointCodeNumber);
        setIsPayKabayan(isPointPayKabayan);
      }

      // FLAGR_FORCE_UPDATE
      if (FEATURE_FORCE_UPDATE) {
        const forceUpdate = getFlagrV2ById(data, FLAGR_FORCE_UPDATE);
        const isForceUpdateEnabled = isFlagrV2Enabled(forceUpdate);
        if (isForceUpdateEnabled) setIsForceUpdateActive(true);
        const forceUpdateVariant = forceUpdate.variantAttachment?.variant || [];
        const playStoreVersion = forceUpdateVariant[0]?.playStoreVersion || '';
        const minimumVersion = forceUpdateVariant[0]?.minimumVersion || '';
        setAppVersions({ playStoreVersion, minimumVersion });
      }

      // FLAGR_KBR_SUBMISSION
      const kbrSubmissionData = getFlagrV2ById(data, FLAGR_KBR_SUBMISSION);
      const isKbrSubmissionDataEnabled = isFlagrV2Enabled(kbrSubmissionData);
      const kbrSubmissionDataVariant = kbrSubmissionData.variantAttachment?.variant || [];
      if (isKbrSubmissionDataEnabled && kbrSubmissionDataVariant[0]) {
        setKbrSubmission({
          enabled: isKbrSubmissionDataEnabled,
          youtubeUrl: kbrSubmissionDataVariant[0].url || '',
          homeYoutubeUrl: kbrSubmissionDataVariant[0].entryVideoUrl || '',
        });
      }

      // FLAGR_KBR_SUBMISSION_POINTS
      const nonKbrSubmission = getFlagrV2ById(data, FLAGR_KBR_SUBMISSION_POINTS);
      const isNonKbrSubmissionEnabled = isFlagrV2Enabled(nonKbrSubmission);
      const nonKbrSubmissionVariant = [
        ...(nonKbrSubmission.variantAttachment?.variant || []),
        0,
      ].map((value) => ({ key: value }));
      if (isNonKbrSubmissionEnabled || userPointCodeNumber == 0) {
        const nonKbrSubmissionActivePoint =
          !nonKbrSubmissionVariant.find(
            (variant) => Number(variant?.key || 0) === userPointCodeNumber,
          ) && userPointCodeNumber != 0;
        setIsKbrSubmissionPointActive(nonKbrSubmissionActivePoint);
      }

      // FLAGR_PCFA_CONTACT
      const pcFaContactsFlagr = getFlagrV2ById(data, FLAGR_PCFA_CONTACT);
      const isPcFaContactsEnabled = isFlagrV2Enabled(pcFaContactsFlagr);
      if (isPcFaContactsEnabled) {
        const pcs = pcFaContactsFlagr.variantAttachment;
        setContacts(pcs);
      }

      // FLAGR_E_NOTA
      const eNota = getFlagrV2ById(data, FLAGR_E_NOTA);
      const isENotaEnabled = isFlagrV2Enabled(eNota);
      const eNotaVariant = eNota.variantAttachment?.variant || [];
      const isENotaActivated = isENotaEnabled && eNotaVariant.includes(userPointCodeNumber);
      setIsENota(isENotaActivated);

      // FLAGR_REPAYMENT
      const repaymentPointsFlagr = getFlagrV2ById(data, FLAGR_REPAYMENT);
      const isRepaymentPoints = isFlagrV2Enabled(repaymentPointsFlagr);
      const repaymentPoints = repaymentPointsFlagr.variantAttachment;
      if (isRepaymentPoints) {
        const isPointRepayment = repaymentPoints.point.find(
          (v) =>
            (typeof v === 'string' ? v.replace(/_/g, ' ').toLowerCase() : '') ===
            userPointNameLowercase,
        );
        setIsRepaymentActive(Boolean(isPointRepayment || repaymentPoints.point.length === 0));
      }

      // FLAGR_BELI_PAKAN
      const nonBeliPakan = getFlagrV2ById(data, FLAGR_BELI_PAKAN);
      const isNonBeliPakanEnabled = isFlagrV2Enabled(nonBeliPakan);
      const nonBeliPakanVariant = [
        ...(nonBeliPakan.variantAttachment?.variant || []),
        'NON-POINT',
      ].map((point) => (typeof point === 'string' ? point.replace(/_/g, ' ').toLowerCase() : ''));
      const isBeliPakanActivated = isNonBeliPakanEnabled
        ? !nonBeliPakanVariant.includes(userPointNameLowercase)
        : userPointNameLowercase !== 'non-point';
      setIsBeliPakanActive(isBeliPakanActivated);

      // FLAGR_SELF_TRANSACTION
      const nonSelfTransactionPointsFlagr = getFlagrV2ById(data, FLAGR_SELF_TRANSACTION);
      const isNonSelfTransaction = isFlagrV2Enabled(nonSelfTransactionPointsFlagr);
      if (isNonSelfTransaction) {
        const nonSelfTransactionPoints = [
          ...(nonSelfTransactionPointsFlagr.variantAttachment?.point || []),
          'NON-POINT',
        ];

        const isPointST = !nonSelfTransactionPoints.find(
          (v) =>
            (typeof v === 'string' ? v.replace(/_/g, ' ').toLowerCase() : '') ===
            userPointNameLowercase,
        );
        setIsSelfTransactionActive(isPointST);
      }

      // FLAGR_FILTER_SUPPLIER
      const filterSupplier = getFlagrV2ById(data, FLAGR_FILTER_SUPPLIER);
      const isFilterSupplierEnabled = isFlagrV2Enabled(filterSupplier);
      const filterSupplierVariant = filterSupplier.variantAttachment?.variant || [];
      if (isFilterSupplierEnabled && filterSupplierVariant.length) {
        const filterSupplierIds = filterSupplierVariant
          .map((v) => parseInt(v, 10))
          .filter((x) => x);
        setFilterSuppliers(filterSupplierIds);
      }

      // FLAGR_PAYMENT_EXPIRED_DURATION
      const paymentExpiredDurationsFlagr = getFlagrV2ById(data, FLAGR_PAYMENT_EXPIRED_DURATION);
      const isPaymentExpiredDuration = isFlagrV2Enabled(paymentExpiredDurationsFlagr);
      if (isPaymentExpiredDuration) {
        const expiredDuration =
          parseInt(paymentExpiredDurationsFlagr.variantAttachment?.expired_duration, 10) || 1440;
        setPaymentExpiredDuration(expiredDuration);
      }

      // FLAGR_MAINTENANCE
      const maintenance = getFlagrV2ById(data, FLAGR_MAINTENANCE);
      const isMaintance = isFlagrV2Enabled(maintenance);
      if (isMaintance) {
        const endDate = maintenance.variantAttachment?.endDate || '';
        if (endDate) {
          setMaintenanceFinish(endDate);
        }
        setIsMaintenance(true);
      }

      // FLAGR_MAINTENANCE SPECIFIC PAGE
      // beli pakan
      const maintenanceBeliPakan = getFlagrV2ById(data, FLAGR_MAINTENANCE_BELI_PAKAN);
      const maintenanceBeliPakanContent = getMaintenanceContentV2(maintenanceBeliPakan);
      // feeder
      const maintenanceEFeeder = getFlagrV2ById(data, FLAGR_MAINTENANCE_EFEEDER);
      // lapak ikan
      const maintenanceLapakIkan = getFlagrV2ById(data, FLAGR_MAINTENANCE_LAPAK_IKAN);
      const maintenanceLapakIkanContent = getMaintenanceContentV2(maintenanceLapakIkan);

      setMaintenanceSpecificPage((prev) => ({
        ...prev,
        beliPakan: {
          isMaintained: isFlagrV2Enabled(maintenanceBeliPakan),
          content: maintenanceBeliPakanContent,
        },
        efeeder: isFlagrV2Enabled(maintenanceEFeeder),
        lapakIkan: {
          isMaintained: isFlagrV2Enabled(maintenanceLapakIkan),
          content: maintenanceLapakIkanContent,
        },
      }));

      // FLAGR_ENABLE_OTP
      const otpWA = getFlagrV2ById(data, FLAGR_OTP_WA);
      const otpSMS = getFlagrV2ById(data, FLAGR_OTP_SMS);
      setOtpEnabled({ wa: !!isFlagrV2Enabled(otpWA), sms: !!isFlagrV2Enabled(otpSMS) });

      // FLAGR_KABAYAN_STATUS
      const kabayanStatus = getFlagrV2ById(data, FLAGR_KABAYAN_STATUS);
      const isKabayanStatusEnabled = isFlagrV2Enabled(kabayanStatus);
      const kabayanStatusVariant = kabayanStatus.variantAttachment?.variant || [];
      if (isKabayanStatusEnabled && kabayanStatusVariant[0]) {
        setKabayanStatusConfig(kabayanStatusVariant[0]);
      }

      // FLAGR_SUPPORT_INFO
      const supportInfoVariantFlagr = getFlagrV2ById(data, FLAGR_SUPPORT_INFO);
      const isSupportInfo = isFlagrV2Enabled(supportInfoVariantFlagr);
      const supportInfoVariant = supportInfoVariantFlagr.variantAttachment;
      if (isSupportInfo) {
        setSupportInfo(supportInfoVariant);
      }

      // FLAGR_FILTER_PRODUCT_CATEGORIES
      const filterProductCategories = getFlagrV2ById(data, FLAGR_FILTER_PRODUCT_CATEGORIES);
      const isFilterProductCategoriesEnabled = isFlagrV2Enabled(filterProductCategories);
      const filterProductCategoriesVariant =
        filterProductCategories.variantAttachment?.variant || [];
      if (isFilterProductCategoriesEnabled) {
        setActiveProductCategories(filterProductCategoriesVariant);
      }

      // FLAGR_COMPLAINT_FORM
      const complaintForm = getFlagrV2ById(data, FLAGR_COMPLAINT_FORM);
      const isComplaintFormEnabled = isFlagrV2Enabled(complaintForm);
      setComplaintFormEnabled(isComplaintFormEnabled);

      const cultivationForm = getFlagrV2ById(data, FLAGR_CULTIVATION_FORM);
      const isCultivationFormEnabled = isFlagrV2Enabled(cultivationForm);
      setCultivationFormEnabled(isCultivationFormEnabled);

      // FLAGR_FEEDBACK_CUSTOMER
      const feedbackCustomer = getFlagrV2ById(data, FLAGR_FEEDBACK_CUSTOMER);
      const isFeedbackCustomerEnabled = isFlagrV2Enabled(feedbackCustomer);
      setFeedbackCustomerEnabled(isFeedbackCustomerEnabled);

      // FLAGR_COMPLAINT_REQUEST_TOKEN
      const complaintRequestToken = getFlagrV2ById(data, FLAGR_COMPLAINT_REQUEST_TOKEN);
      const isComplaintRequestTokenEnabled = isFlagrV2Enabled(complaintRequestToken);
      setComplaintRequestTokenEnabled(isComplaintRequestTokenEnabled);

      // FLAGR_OCR_KK
      const ocrKkFlagr = getFlagrV2ById(data, FLAGR_OCR_KK);
      const isOrcKkEnabled = isFlagrV2Enabled(ocrKkFlagr);
      setOcrKkEnabled(isOrcKkEnabled);

      // FLAGR_OCR_KTP
      const ocrKtpFlagr = getFlagrV2ById(data, FLAGR_OCR_KTP);
      const isOrcKtpEnabled = isFlagrV2Enabled(ocrKtpFlagr);
      setOcrKtpEnabled(isOrcKtpEnabled);

      // FLAGR_BELANJA_PAKAN_VIA_CHAT
      const shopViaChat = getFlagrV2ById(data, FLAGR_BELANJA_PAKAN_VIA_CHAT);
      const isShopViaChatEnabled = isFlagrV2Enabled(shopViaChat);
      const urlShopViaChat = shopViaChat.variantAttachment?.url || DEFAULT_SHOP_VIA_CHAT_URL;
      setIsEnableShopViaChat(isShopViaChatEnabled);
      setShopViaChatUrl(urlShopViaChat);

      // FLAGR_EARLY_PAYMENT
      const earlyPaymentFlagr = getFlagrV2ById(data, FLAGR_EARLY_PAYMENT);
      const isEarlyPaymentEnabled = isFlagrV2Enabled(earlyPaymentFlagr);
      setEarlyPaymentEnabled(isEarlyPaymentEnabled);

      // FLAGR_PRODUCT_SOLD_THRESHOLD
      const productSoldThresholdFlagr = getFlagrV2ById(data, FLAGR_PRODUCT_SOLD_THRESHOLD);
      const isProductSoldThresoldEnabled = isFlagrV2Enabled(productSoldThresholdFlagr);
      const qtyProductSoldThresold = isProductSoldThresoldEnabled
        ? productSoldThresholdFlagr.variantAttachment?.qty || 10
        : 10;
      setProductSoldThreshold(qtyProductSoldThresold);

      // FLAGR_LOYALTY_ENTRY_V2
      const LoyaltyEntryFlagr = getFlagrV2ById(data, FLAGR_LOYALTY_ENTRY);
      const LoyaltyEntryEnabled = isFlagrV2Enabled(LoyaltyEntryFlagr);
      setLoyaltyEntryFlag(LoyaltyEntryEnabled);

      // FLAGR_UPDATE_NPWP
      const isUpdateNpwpFlagr = getFlagrV2ById(data, FLAGR_ENABLE_POPUP_UPDATE_NPWP);
      const isUpdateNpwpEnabled = isFlagrV2Enabled(isUpdateNpwpFlagr);
      setIsEnableUpdateNpwp(isUpdateNpwpEnabled);

      // FLAGR_BLACKBOX
      const blackboxFlagr = getFlagrV2ById(data, FLAGR_BLACKBOX);
      const isBlackboxEnabled = isFlagrV2Enabled(blackboxFlagr);
      setIsBlackboxPoint(isBlackboxEnabled);

      // FLAGR_FARMER_BALANCE
      const farmerBalanceFlagr = getFlagrV2ById(data, FLAGR_FARMER_BALANCE);
      const isFarmerBalanceEnabled = isFlagrV2Enabled(farmerBalanceFlagr);
      setIsBalanceEnabled(isFarmerBalanceEnabled);

      // FLAGR_REGISTER_POINT
      const registerPointFlagr = getFlagrV2ById(data, FLAGR_REGISTER_POINT);
      const isRegisterPointEnabled = isFlagrV2Enabled(registerPointFlagr);
      setRegisterPointEnabled(isRegisterPointEnabled);

      // FLAGR_KBR_FORM
      const flagrKbrForm = getFlagrV2ById(data, FLAGR_KBR_FORM);
      const isKbrFormDisable = isFlagrV2Enabled(flagrKbrForm);
      setIsDisableKbrForm(isKbrFormDisable);

      // FLAGR_PRIVACY_POLICY
      const flagrPrivacyPolicy = getFlagrV2ById(data, FLAGR_PRIVACY_POLICY);
      const isPrivolEnabled = isFlagrV2Enabled(flagrPrivacyPolicy);
      setIsPrivacyPolicyEnabled(isPrivolEnabled);

      // TODO: fix fetch flagr
      // FLAGR_ACTIVE_PRODUCT_CATEGORIES
      const activeProductCategoriesV2 = getFlagrV2ById(data, FLAGR_ACTIVE_PRODUCT_CATEGORIES);
      const isActiveProductCategoriesEnabled = isFlagrV2Enabled(activeProductCategoriesV2);
      const activeProductCategoriesVariant =
        activeProductCategoriesV2.variantAttachment?.variant || [];
      if (isActiveProductCategoriesEnabled) {
        setNewActiveProductCategories(activeProductCategoriesVariant);
      }

      // FLAGR_COMPLAINT_HANDLING
      const flargComplaintHandling = getFlagrV2ById(data, FLAGR_COMPLAINT_HANDLING_V2);
      setIsComplaintHandlingV2Enabled(isFlagrV2Enabled(flargComplaintHandling));

      // FLAGR_FMS_V2
      const flagrFMSV2 = getFlagrV2ById(data, FLAGR_FMS_V2);
      setisFMSV2Enabled(isFlagrV2Enabled(flagrFMSV2));

      // FLAGR_FMS_V2
      const flagrIsOnReapply = getFlagrV2ById(data, FLAGR_ON_REAPPLY);
      setIsOnReapplyEnabled(isFlagrV2Enabled(flagrIsOnReapply));

      // FLAGR_ENABLE_POPUP_HOMEPAGE
      const flagrPopupHomepage = getFlagrV2ById(data, FLAGR_ENABLE_POPUP_HOMEPAGE);
      const isActivePopupHomepageEnabled = isFlagrV2Enabled(flagrPopupHomepage);
      const activePopupHomepageVariant = flagrPopupHomepage.variantAttachment || {};
      if (isActivePopupHomepageEnabled) {
        setActivePopupHomepage(activePopupHomepageVariant);
      }

      const flagrIsEnableFilterPointactive = getFlagrV2ById(data, FLAGR_ENABLE_FILTER_POINT_ACTIVE);
      setIsEnableFilterPointactive(isFlagrV2Enabled(flagrIsEnableFilterPointactive));

      const flagrIsHideStatus = getFlagrV2ById(data, FLAGR_HIDE_STATUS);
      setIsHideStatus(isFlagrV2Enabled(flagrIsHideStatus));
    } catch (error) {
      if (!skipErrFlagr) throw error;
    }
  };

  const fetchAllFlagr = async () => {
    try {
      setErrors(null);
      await getAllEfisheryKuFlagrData(userProfile);
      setIsFlagrFetched(true);
      skipErrFlagr = true;
    } catch (err) {
      setErrors(err);
      setIsFlagrFetched(true);
      if (!skipErrFlagr) throw err;
    }
  };

  const fetchFlagrByPointIDGuestMode = async (pointId) => {
    await getAllEfisheryKuFlagrData({ point: { code: pointId } });
  };

  const fetchAllnScheduled = async () => {
    await fetchAllFlagr();
    startSchedule();
  };

  const startSchedule = () => {
    stopSchedule();
    idleId = setTimeout(
      () => requestIdleCallback(fetchAllnScheduled),
      process.env.REACT_APP_FLAGR_REFETCH_INTERVAL
        ? process.env.REACT_APP_FLAGR_REFETCH_INTERVAL * 1000
        : 60000,
    );
  };

  const stopSchedule = () => {
    if (idleId) clearTimeout(idleId);
  };

  useEffect(() => {
    if (!isLoading && ((isLogin && isFetched) || !isLogin)) {
      fetchAllnScheduled();
    }
    return () => clearTimeout(idleId);
  }, [userProfile, isLoading, isFetched]);

  return (
    <FlagrContextV2.Provider
      value={{ ...providerValue, startSchedule, stopSchedule, fetchFlagrByPointIDGuestMode }}
    >
      {children}
    </FlagrContextV2.Provider>
  );
};

FlagrProviderV2.propTypes = {
  children: any.isRequired,
};

import 'twin.macro';

import React, { FunctionComponent, useMemo } from 'react';
import ReactModal, { Styles } from 'react-modal';

import Button from 'components/Button';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import { ChildrenCont, ContentContainer, GlobalStyles, LockScroll, Title } from './styles';

const ModalFloat: FunctionComponent<IModalFloat> = ({
  children,
  isOpen,
  title,
  okTitle,
  cancelTitle,
  okTestId,
  cancelTestId,
  clickOk,
  clickCancel,
}) => {
  const { width } = useWindowDimensions();

  const custom: Styles = useMemo(
    () => ({
      overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(29, 29, 29, 0.30)',
        zIndex: 10000,
      },
      content: {
        width: '80%',
        maxWidth: 380,
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '8px',
        padding: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    }),
    [width],
  );
  return (
    <>
      {isOpen && <LockScroll />}
      <GlobalStyles />
      <ReactModal
        isOpen={isOpen}
        onRequestClose={clickCancel}
        style={custom}
        closeTimeoutMS={250}
        shouldCloseOnOverlayClick={false}
      >
        <ContentContainer>
          <ChildrenCont>
            {title && <Title>{title}</Title>}
            {children}
          </ChildrenCont>
          <Button onClick={clickOk} tw="mb-2" data-testid={okTestId}>
            {okTitle || 'OK'}
          </Button>
          {clickCancel && (
            <Button isSecondary onClick={clickCancel} data-testid={cancelTestId}>
              {cancelTitle || 'Batal'}
            </Button>
          )}
        </ContentContainer>
      </ReactModal>
    </>
  );
};

export interface IModalFloat {
  children: any;
  isOpen: boolean;
  title?: string;
  okTitle?: string;
  cancelTitle?: string;
  okTestId?: string;
  cancelTestId?: string;
  clickOk: () => void;
  clickCancel?: () => void;
}

export default ModalFloat;

import { gql } from 'graphql-request';

const updateAddressSawarnaV2 = gql`
  mutation updateAddress(
    $id: Int!
    $v1LocationInput: seluang_LocationServiceUpdateLocationByIDBody_Input!
  ) {
    response: seluang_UpdateLocationByID(id: $id, input: $v1LocationInput) {
      message
      success
    }
  }
`;

export default updateAddressSawarnaV2;

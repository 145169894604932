import { bool, object, string } from 'prop-types';
import { Redirect } from 'react-router-dom';

import Route from 'components/Route';
import { TRANSACTIONAL_ROUTES } from 'routes/constants';
import useAuthContext from 'utils/hooks/useAuthContext';

const PrivateRoute = (props) => {
  const { isLogin, userProfile } = useAuthContext();
  const { isGuest, location, path } = props;
  const isBlacklistUser = userProfile.isBlacklist;

  if (isLogin) {
    if (isGuest) return <Redirect to="/" />;

    if (isBlacklistUser && TRANSACTIONAL_ROUTES.includes(path)) return <Redirect to="/" />;

    return <Route {...props} />;
  }

  if (!isLogin && isGuest) {
    return <Route {...props} />;
  }

  const { pathname, search } = location;
  const targetUrl = pathname + search || '';

  return (
    <Redirect to={`/auth/login${targetUrl ? `?next=${encodeURIComponent(targetUrl)}` : ''}`} />
  );
};

PrivateRoute.propTypes = {
  isGuest: bool,
  location: object,
  path: string,
};

PrivateRoute.defaultProps = {
  isGuest: false,
  location: {},
  path: '',
};

export default PrivateRoute;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const CartPages = loadable(() => import(/* webpackChunkName: "CartPages" */ './Cart'), {
  fallback: <LoadingScreen />,
});

export const cartRoute: Route = {
  name: 'CartPages',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.CART,
    component: CartPages,
  },
};

import React, { createContext, FunctionComponent, useContext, useState } from 'react';
import Modal from 'react-modal';

import { BlockButton, BorderButton, DangerButton, QuestionContainer } from './style';

type IConfirmModalOptionButtonType = 'border' | 'danger';

interface IConfirmModalOptionButton {
  label: string;
  type: IConfirmModalOptionButtonType;
  testId?: string;
  onClick?: () => void;
}

export interface IConfirmModalOption {
  question: string;
  buttons: IConfirmModalOptionButton[];
  testId?: string;
}

type IConfirmModalContext = {
  showConfirmModal: (opt: IConfirmModalOption) => void;
};

const ConfirmModalContext = createContext<IConfirmModalContext>({
  showConfirmModal: () => {},
});
interface IConfirmModalContainer {
  children: React.ReactNode;
}
const ConfirmModalContainer: FunctionComponent<IConfirmModalContainer> = ({ children }) => {
  const modalHeight = 215;
  const modalWidth = 330;

  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState<string>();
  const [buttons, setButtons] = useState<IConfirmModalOptionButton[]>([]);
  const [dataTestId, setDataTestId] = useState<string | undefined>('');

  const closeConfirmModal = () => {
    setIsOpen(false);
    setDataTestId('');
  };
  const showConfirmModal = (opt: IConfirmModalOption) => {
    setQuestion(opt.question);
    setButtons(opt.buttons || []);
    setDataTestId(opt.testId);
    setIsOpen(true);
  };

  return (
    <ConfirmModalContext.Provider value={{ showConfirmModal }}>
      <div>
        {children}
        <Modal
          isOpen={isOpen}
          onRequestClose={closeConfirmModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(29, 29, 29, 0.30)',
              zIndex: 100000,
            },
            content: {
              width: modalWidth,
              height: modalHeight,
              top: `calc(50% - ${modalHeight / 2}px)`,
              left: `calc(50% - ${modalWidth / 2}px)`,
              borderRadius: 8,
            },
          }}
          testId={`wrp_popup_${dataTestId}`}
        >
          <QuestionContainer>{question}</QuestionContainer>
          {buttons.map((btn, index) => {
            let Comp = BlockButton;
            switch (btn.type) {
              case 'border':
                Comp = BorderButton;
                break;
              case 'danger':
                Comp = DangerButton;
                break;
              default:
                break;
            }
            const onClick = () => {
              if (btn.onClick) btn.onClick();
              closeConfirmModal();
            };
            return (
              <Comp
                key={index}
                onClick={onClick}
                data-testid={dataTestId && `btn_popup_${dataTestId}_${btn.testId}`}
              >
                {btn.label}
              </Comp>
            );
          })}
        </Modal>
      </div>
    </ConfirmModalContext.Provider>
  );
};

const useConfirmModal = () => useContext(ConfirmModalContext);

export { ConfirmModalContainer, useConfirmModal };

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const TransactionInvoicePage = loadable(
  () => import(/* webpackChunkName: "TransactionInvoicePage" */ './TransactionInvoicePage'),
  {
    fallback: <LoadingScreen />,
  },
);

const SuccessOrderValidationPage = loadable(
  () => import(/* webpackChunkName: "SuccessOrderValidationPage" */ './SuccessOrderValidationPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const TransactionHistoryPageV2 = loadable(
  () => import(/* webpackChunkName: "TransactionHistoryPageV2" */ './TransactionHistoryPageV2'),
  {
    fallback: <LoadingScreen />,
  },
);

const TransactionDetailPageV2 = loadable(
  () => import(/* webpackChunkName: "TransactionDetailPageV2" */ './TransactionDetailPageV2'),
  {
    fallback: <LoadingScreen />,
  },
);

const TransactionDetailTrack = loadable(
  () => import(/* webpackChunkName: "TransactionDetailTrack" */ './TransactionDetailTrackPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const transactionHistoryRoutes: Route[] = [
  {
    name: 'Transaction History',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.TRANSACTION_HISTORY,
      component: TransactionHistoryPageV2,
    },
  },
  {
    name: 'Transaction Detail',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.TRANSACTION_DETAIL,
      component: TransactionDetailPageV2,
    },
  },
  {
    name: 'Transaction Invoice',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.TRANSACTION_INVOICE,
      component: TransactionInvoicePage,
    },
  },
  {
    name: 'Success Validation Order',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.SUCCESS_ORDER_VALIDATION,
      component: SuccessOrderValidationPage,
    },
  },
  {
    name: 'Track Transaction',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.TRANSACTION_DETAIL_TRACK,
      component: TransactionDetailTrack,
    },
  },
];

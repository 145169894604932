import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const AddressListPage = loadable(
  () => import(/* webpackChunkName: "AddressListPage" */ './AddressListPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const AddressFormPage = loadable(
  () => import(/* webpackChunkName: "AddressFormPage" */ './AddressFormPage'),
  {
    fallback: <LoadingScreen />,
  },
);

const AddressSearchPage = loadable(
  () => import(/* webpackChunkName: "AddressSearchPage" */ './AddressSearchPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const addressManagementRoutes: Route[] = [
  {
    name: 'Address List',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.ADDRESS,
      component: AddressListPage,
    },
  },
  {
    name: 'Address Register',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.ADDRESS_REGISTER,
      component: AddressFormPage,
    },
  },
  {
    name: 'Address Detail',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.ADDRESS_REGISTER_DETAIL,
      component: AddressSearchPage,
    },
  },
];

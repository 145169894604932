import { gql } from 'graphql-request';

const saveNotifTokenMutationSawarnaV2 = gql`
  mutation saveNotifToken($token: String!, $replaceToken: String) {
    beluga_saveNotifToken: beluga_SaveNotifToken(
      input: { token: $token, replaceToken: $replaceToken }
    ) {
      message
      success
    }
  }
`;

export default saveNotifTokenMutationSawarnaV2;

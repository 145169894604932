import React from 'react';
import { any } from 'prop-types';

import { ConfirmModalContainer } from 'components/ConfirmModal';

import { BannerApp, BannerContainer, Container } from './style';

const AppContainer = ({ children }) => {
  return (
    <Container>
      <ConfirmModalContainer>
        {process.env.REACT_APP_MODE === 'staging' && (
          <BannerContainer>
            <BannerApp>Environment Staging </BannerApp>
          </BannerContainer>
        )}
        {children}
      </ConfirmModalContainer>
    </Container>
  );
};

AppContainer.propTypes = {
  children: any.isRequired,
};

export default AppContainer;

import 'twin.macro';

import { ReactNode } from 'react';
import { ArrowRight } from '@onefish/icons-react';
import { useHistory } from 'react-router-dom';

import BottomSheet from 'components/BottomSheet';

import { Button } from './styles';

type Props = {
  urlHash?: string;
  buttonLabel: string;
  heading?: string;
  children: ReactNode;
  testId?: string;
};

const Guide = ({
  urlHash = 'bottom-sheet-guide',
  buttonLabel,
  heading,
  children,
  testId,
}: Props) => {
  const { location, push, goBack } = useHistory();

  return (
    <>
      <Button
        type="button"
        onClick={() => push({ ...location, hash: urlHash })}
        data-testid={`btn_guide_${testId}`}
      >
        {buttonLabel}
        <ArrowRight size={24} />
      </Button>

      <BottomSheet
        isOpen={location.hash === `#${urlHash}`}
        closeModal={goBack}
        title={heading || buttonLabel}
        testId="guide"
      >
        {children}
        <div tw="h-4" />
      </BottomSheet>
    </>
  );
};

export default Guide;

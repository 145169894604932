import { capitalizeWord, extracDataFromAddressString } from 'utils/helpers/string';

export const normalizeCityList = (datas) => {
  return datas.map((data) => ({
    id: data?.city_id,
    name: data?.city_name,
  }));
};

export const normalizeDistrictList = (datas) => {
  return datas.map((data) => ({
    id: data?.district_id,
    name: data?.district_name,
  }));
};

export const normalizeSearch = (datas, keyword) => {
  return datas.map((data) => {
    const formattingName = `${data?.subdistrict_name}, ${data?.district_name}, ${data?.city_name}, ${data?.province_name}`;

    return {
      name: capitalizeWord(
        formattingName
          .toLowerCase()
          .split(keyword.toLowerCase())
          .join(`<span> ${capitalizeWord(keyword)}</span>`),
      ),
      province: {
        id: Number(data?.province_id),
        name: data?.province_name,
      },
      city: {
        id: Number(data?.city_id),
        name: data?.city_name,
      },
      district: {
        id: Number(data?.district_id),
        name: data?.district_name,
      },
      subDistrict: {
        id: Number(data?.subdistrict_id),
        name: data?.subdistrict_name,
        zip_code: data?.zip_code,
      },
    };
  });
};

export const normalizePostAddress = (rawData) => {
  const name = 'Alamat Utama';

  const labels = rawData?.addressLabels
    ? [rawData.addressLabels === 'Lainnya' ? rawData.addressLabelsOther : rawData.addressLabels]
    : [];

  const rtrw = Number(rawData?.rt) && Number(rawData.rw) ? `; RT${rawData.rt}/RW${rawData.rw}` : '';
  const address = `${rawData?.postalAddress || ''}${rtrw}`;

  return {
    name,
    pointId: rawData?.pointId || '',
    receiverName: rawData?.name || '',
    phone: rawData?.phone || '',
    address,
    zipCode: rawData?.postalCode || '',
    provinceId: rawData?.province?.id || 0,
    cityId: rawData?.city?.id || 0,
    districtId: rawData?.district?.id || 0,
    subdistrictId: String(rawData?.subDistrict?.id) || '',
    note: rawData?.note || '',
    roadAccess: rawData?.streetWidth || '',
    labels,
  };
};

const addressNormalizer = (data) => {
  const norm = {
    id: data?.id || 0,
    name: data?.receiverName || '',
    addressName: data?.name || '',
    addressLabels: data?.labels || '',
    postalAddress: data?.address || '',
    postalCode: data?.zipCode || '',
    phone: data?.phone || '',
    streetWidth: data?.roadAccess || '',
    note: data?.note || '',
    province: {
      id: data?.provinceId || 0,
      name: data?.provinceName || '',
    },
    city: {
      id: data?.cityId || 0,
      name: data?.cityName || '',
    },
    district: {
      id: data?.districtId || 0,
      name: data?.districtName || '',
    },
    subDistrict: {
      id: data?.subdistrictId || 0,
      name: data?.subdistrictName || '',
    },
  };

  const { postalAddress, rt, rw } = extracDataFromAddressString(data?.address);
  norm.rt = rt;
  norm.rw = rw;
  norm.postalAddress = postalAddress;

  return norm;
};

export const normalizeAddresses = (datas) => {
  const newDatas = datas.map((data) => addressNormalizer(data));
  return newDatas;
};

export const normalizeAddress = (data) => addressNormalizer(data);

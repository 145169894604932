import { SAWARNA_V2_SERVICES } from 'env';

import { dataLayer, dataLayerMerkle } from 'utils/helpers/tracking';

import { NOTIFICATION_TYPE_LIST } from '../constants';

export const MUTATION_TRACKING_SAWARNA_V2 = `
mutation membershipTracking($input:  beluga_notificationTrackingNotifReq_Input!) {
  beluga_TrackingNotif(input: $input){
    message
  }
}`;

export const trackPushNotificationBeluga = async (input) => {
  const url = SAWARNA_V2_SERVICES || '';
  const query = MUTATION_TRACKING_SAWARNA_V2;
  const inputVariable = { ...input, trackingId: input.tracking_id, tracking_id: undefined };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          input: inputVariable,
        },
      }),
    });
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error in trackingPushNotifBeluga:', error);
    throw error;
  }
};

export const trackNotificationForeground = (title, group) => {
  const tagManagerArgs = {
    dataLayer: {
      event: `web_notification_foreground`,
      event_category: 'Push Notification',
      button_name: title,
      message_title: title,
      message_group: group,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const trackNotificationBackground = (title, group) => {
  const tagManagerArgs = {
    dataLayer: {
      event: `web_notification_background`,
      event_category: 'Push Notification',
      button_name: title,
      message_title: title,
      message_group: group,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const trackNotificationOpen = (title, group) => {
  const tagManagerArgs = {
    dataLayer: {
      event: `web_notification_open`,
      event_category: 'Push Notification',
      button_name: title,
      message_title: title,
      message_group: group,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const trackClickedNotification = (type) => {
  const featLabel = NOTIFICATION_TYPE_LIST[type];
  if (!featLabel) return;

  const tagManagerArgs = {
    dataLayer: {
      event: `Notification ${featLabel} Clicked`,
      eventCategory: 'Notification',
      eventlabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackNotificationPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: `Inbox Page Loaded`,
      eventCategory: 'Home',
      eventlabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackClickedInAppNotification = (type, title) => {
  const tagManagerArgs = {
    dataLayer: {
      event: `In App Notif Clicked`,
      eventCategory: 'In App Notif',
      eventlabel: `[${type}] ${title}`,
    },
  };
  dataLayer(tagManagerArgs);
};

export const WHITE_COLOR = '#ffffff';

// Observatory
export const OBSERVATORY_50 = '#F3F9F7';
export const OBSERVATORY_100 = '#CDE7E1';
export const OBSERVATORY_200 = '#9ACFC2';
export const OBSERVATORY_300 = '#68B7A4';
export const OBSERVATORY_400 = '#359F85';
export const OBSERVATORY_500 = '#038767';
export const OBSERVATORY_600 = '#026C52';
export const OBSERVATORY_700 = '#02513E';

// Aqua
export const AQUA_50 = '#F5FFFA';
export const AQUA_100 = '#DBF4EC';
export const AQUA_200 = '#B7E9D9';
export const AQUA_300 = '#94DDC7';
export const AQUA_400 = '#70D2B4';
export const AQUA_500 = '#4CC7A1';
export const AQUA_600 = '#3D9F81';
export const AQUA_700 = '#2E7761';

// Green
export const GREEN_50 = '#F3F9F2';
export const GREEN_100 = '#D1E7CC';
export const GREEN_200 = '#A4D099';
export const GREEN_300 = '#76B866';
export const GREEN_400 = '#49A133';
export const GREEN_500 = '#1B8900';
export const GREEN_600 = '#166E00';
export const GREEN_700 = '#105200';

// Fresh Green
export const FRESHGREEN_50 = '#FDFEF5';
export const FRESHGREEN_100 = '#F6FCD7';
export const FRESHGREEN_200 = '#EDF9AE';
export const FRESHGREEN_300 = '#E5F686';
export const FRESHGREEN_400 = '#DCF35D';
export const FRESHGREEN_500 = '#D3F035';
export const FRESHGREEN_600 = '#A9C02A';
export const FRESHGREEN_700 = '#7F9020';

// Blue
export const BLUE_50 = '#F6FBFD';
export const BLUE_100 = '#DFF4F9';
export const BLUE_200 = '#BEE9F4';
export const BLUE_300 = '#9EDDEE';
export const BLUE_400 = '#7DD2E9';
export const BLUE_500 = '#5DC7E3';
export const BLUE_600 = '#4A9FB6';
export const BLUE_700 = '#387788';

// Red
export const RED_50 = '#FDF5F5';
export const RED_100 = '#F6D9D9';
export const RED_200 = '#EEB3B3';
export const RED_300 = '#E58D8C';
export const RED_400 = '#DD6766';
export const RED_500 = '#D44140';
export const RED_600 = '#AA3433';
export const RED_700 = '#7F2726';

// orange
export const ORANGE_50 = '#FFF9F5';
export const ORANGE_100 = '#FDE9D9';
export const ORANGE_200 = '#FBD4B3';
export const ORANGE_300 = '#F8BE8E';
export const ORANGE_400 = '#F49342';
export const ORANGE_500 = '#C05717';
export const ORANGE_600 = '#9E430B';
export const ORANGE_700 = '#763208';

// YellowColor
export const YELLOW_50 = '#FFFDF2';
export const YELLOW_100 = '#FFF6CC';
export const YELLOW_200 = '#FFE466';
export const YELLOW_300 = '#FFED99';
export const YELLOW_400 = '#FFDB33';
export const YELLOW_500 = '#FFD200';
export const YELLOW_600 = '#CCA800';
export const YELLOW_700 = '#997E00';

// Black
export const BLACK_50 = '#F6F6F6';
export const BLACK_100 = '#D2D2D2';
export const BLACK_200 = '#A5A5A5';
export const BLACK_300 = '#777777';
export const BLACK_400 = '#4A4A4A';
export const BLACK_500 = '#1D1D1D';
export const BLACK_600 = '#171717';
export const BLACK_700 = '#111111';

// Custom Colors
export const GREY_BG = '#F8F8F8';

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const LimitDevicePage = loadable(
  () => import(/* webpackChunkName: "LimitDevicePage" */ './LimitDevicePage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const limitDeviceRoutes: Route[] = [
  {
    routeProps: {
      exact: true,
      path: PATH.LIMIT_DEVICE,
      component: LimitDevicePage,
    },
    isPublic: true,
    name: '',
  },
];

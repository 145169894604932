import { toCamelCase, toSnakeCase } from './string';

export { default as mergeWith } from 'lodash.mergewith';

/**
 *
 * @param {{}} object
 * @param {string[]} keys
 * @return {{}}
 */
export function omit(object, keys) {
  const result = {};

  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) return;
    result[key] = object[key];
  });

  return result;
}

/**
 *
 * @param {{}} object
 * @param {string[]} keys
 * @return {{}}
 */
export function pick(object, keys) {
  const result = {};

  keys.forEach((key) => {
    if (key in object) {
      result[key] = object[key];
    }
  });

  return result;
}

/**
 *
 * @param {{}} object
 * @param {string[]} keys
 * @return {{}[]}
 */
export function split(object, keys) {
  const picked = {};
  const omitted = {};

  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) {
      picked[key] = object[key];
    } else {
      omitted[key] = object[key];
    }
  });

  return [picked, omitted];
}

/**
 * Get value from a deeply nested object using a string path.
 * Memoizes the value.
 * @param {{}} obj - the object
 * @param {string|number} path - the string path
 * @param {*=} fallback  - the fallback value
 * @param {number=} index
 */
// eslint-disable-next-line no-unused-vars
export function get(obj, path, fallback) {
  const key = typeof path === 'string' ? path.split('.') : [path];
  let object = obj;

  for (let i = 0; i < key.length; i += 1) {
    if (!object) break;
    object = obj[key[i]];
  }

  return object === undefined ? fallback : object;
}

/**
 * @typedef {{ obj: Readonly<object>, path: string|number, fallback: *=, index: number= }} Get
 */

/**
 * @callback FilterFn
 * @param {*} value
 * @param {string} key
 * @param {{}} object
 * @return {boolean}
 */

/**
 *
 * @param {Get} fn
 * @return {Get}
 */
export const memoize = (fn) => {
  const cache = new WeakMap();

  return (obj, path, fallback, index) => {
    if (typeof obj === 'undefined') {
      return fn(obj, path, fallback);
    }

    if (!cache.has(obj)) {
      cache.set(obj, new Map());
    }

    const map = cache.get(obj);

    if (map.has(path)) {
      return map.get(path);
    }

    const value = fn(obj, path, fallback, index);

    map.set(path, value);

    return value;
  };
};

export const memoizedGet = memoize(get);

/**
 * Get value from deeply nested object, based on path
 * It returns the path value if not found in object
 *
 * @param {*} path - the string path or value
 * @param {*} scale - the string path or value
 * @return {Get}
 */
export function getWithDefault(path, scale) {
  return memoizedGet(scale, path, path);
}

/**
 * Returns the items of an object that meet the condition specified in a callback function.
 *
 * @param {{}} object the object to loop through
 * @param {FilterFn} fn The filter function
 * @return {{}}
 */
export function objectFilter(object, fn) {
  const result = {};

  Object.keys(object).forEach((key) => {
    const value = object[key];
    const shouldPass = fn(value, key, object);
    if (shouldPass) {
      result[key] = value;
    }
  });

  return result;
}

/**
 *
 * @param {{}} object
 * @return {{}}
 */
export const filterUndefined = (object) =>
  objectFilter(object, (val) => val !== null && val !== undefined);

/**
 *
 * @param {{}} obj
 * @return {string[]}
 */
export const objectKeys = (obj) => Object.keys(obj);

/**
 * Object.entries polyfill for Nodev10 compatibility
 *
 * @param {[string,*][]} entries
 * @return {[string,*][]}
 */
export const fromEntries = (entries) =>
  entries.reduce((carry, [key, value]) => {
    const a = carry;
    a[key] = value;
    return a;
  }, {});

/**
 * Get the CSS variable ref stored in the theme
 *
 * @param {*} theme
 * @param {string} scale
 * @param {*} value
 * @return {*}
 */
export const getCSSVar = (theme, scale, value) =>
  // eslint-disable-next-line no-underscore-dangle
  theme.__cssMap[`${scale}.${value}`]?.varRef ?? value;

/**
 * Get new object with all keys prefixed.
 * Example input: { foo: 1, bar: true } and prefix is "my_"
 * Output: { my_foo: 1, my_bar: true }
 */
export const addPrefixOnObjKeys = (obj, prefix) => {
  return Object.entries(obj).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [`${prefix}${key}`]: value,
    }),
    {},
  );
};

/**
 * Get new object with all keys converted to snake_case.
 * Example input: { fooBar: 1 }
 * Output: { foo_bar: 1 }
 */
export const snakeCaseObjKeys = (obj) => {
  return Object.entries(obj).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [toSnakeCase(key)]: value,
    }),
    {},
  );
};

export const camelCaseObjKeys = (obj) => {
  if (obj instanceof Array) {
    return obj.map((o) => {
      if (typeof o === 'object') {
        o = camelCaseObjKeys(o);
      }
      return o;
    });
  }

  const newObj = {};
  for (const key in obj) {
    const newKey = toCamelCase(key);
    let value = obj[key];
    if ((value !== null && value.constructor === Object) || value instanceof Array) {
      value = camelCaseObjKeys(value);
    }
    newObj[newKey] = value;
  }
  return newObj;
};

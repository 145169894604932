import {
  FEATURE_DEVICE_ID_SD,
  FEATURE_HEADER_PLATFORM,
  FEATURE_NEW_DEVICE_ID,
  GRAPH_API,
  SAWARNA_V2_SERVICES,
} from 'env';
import { GraphQLClient } from 'graphql-request';

import { CLIENT_ID } from 'routes/constants';
import { getToken } from 'utils/token';

import { HTTP_CLIENT_ID, HTTP_CLIENT_VERSION } from './constants/gqlCodes';
import { ga } from './helpers/ga';

export const catfishClient = new GraphQLClient(GRAPH_API || '', {
  headers: { 'X-Client-Id': HTTP_CLIENT_ID },
});

export const sawarnaV2Client = new GraphQLClient(SAWARNA_V2_SERVICES || '');

type StringMap = {
  [key: string]: string;
};

export const sawarnaV2Fetcher =
  <TData, TVariables>(
    operation: string,
    variables?: TVariables,
    headers?: StringMap,
  ): (() => Promise<TData>) =>
  () =>
    sawarnaV2Client
      .setHeaders({
        authorization: `Bearer ${getToken(operation)}`,
        'X-Client-Id': HTTP_CLIENT_ID,
        'X-Client-Version': HTTP_CLIENT_VERSION,
        'X-Device-Id': window.customDeviceId,
        'X-Device-Datetime': new Date().toISOString(),
        'X-Page-Location': window.location.href,
        'X-Signature': window.signature,
        'GA-Session-Id': ga.getSessionId(),
        'GA-Client-Id': window.postMessageTwa
          ? localStorage.getItem(CLIENT_ID) || ''
          : ga.getClientId(),
        ...(FEATURE_HEADER_PLATFORM
          ? { 'X-Platform-Id': window.postMessageTwa ? 'EFISHERYKU-APP' : 'EFISHERYKU-WEB' }
          : {}),
        ...(headers || {}),
        ...(FEATURE_NEW_DEVICE_ID && {
          'X-New-Device-Id': window.customDeviceIdNew,
        }),
        ...(FEATURE_DEVICE_ID_SD && {
          'X-Device-Id-Sd': window.customDeviceIdSD,
        }),
      })
      .request<TData, TVariables>(operation, variables)
      .catch((err) => {
        if (err?.response?.errors.length) {
          err.response.errors.forEach(
            (item: { message?: string; extensions?: Record<string, any> }) => {
              if (item.extensions && !item.extensions.responseBody) {
                item.extensions.responseBody = item.extensions.responseJson;
              }
            },
          );
        }
        throw err;
      });

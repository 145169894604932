import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const ReferralPageMF = loadable(
  () => import(/* webpackChunkName: "ReferralPage" */ './ReferralPageMF'),
  {
    fallback: <LoadingScreen />,
  },
);

export const referralRoute: Route = {
  name: 'Referral',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.REFERRAL,
    component: ReferralPageMF,
  },
};

import { MerkleTrakerProps } from 'types/Merkle';
import {
  Currency,
  getItemListName,
  ItemListId,
  ItemListName,
  ProductDetail,
} from 'types/Merkle/commerce';
import { dataLayer, dataLayerMerkle, normalizerCommerceItem } from 'utils/helpers/tracking';

const eventCategory = 'Product Catalogue';

export const trackProductCatalogueClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Product Catalogue Clicked',
      eventCategory,
      eventLabel: '',
    },
  };

  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };

  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueCategory = (category) => {
  const tagManagerArgs = {
    dataLayer: {
      event: `btn_${category}_beli_pakan`,
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };

  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueBrandClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Filter Merek Pakan Clicked',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueBrandClickedFromBeliPakan = (brandName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_merk_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: brandName,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueBrandAllClickedFromBeliPakan = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_merk_all_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueBackToHomeClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductListLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Product List Page Loaded',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPagePakanLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPagePakanBack = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPagePakanSearch = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'input_search_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueBrandClickedFromSearchPakan = (brandName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_merk_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: brandName,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueFilterProductListClicked = (type) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_filter_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: type,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Order Product - Lihat Product Clicked',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductListViewed = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Product List Viewed',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductDetailLoaded = (
  product: ProductDetail,
  widgetId: ItemListId,
) => {
  const price = parseInt(product.price.replace('Rp', '').replace('.', ''));
  const tagManagerArgs: MerkleTrakerProps = {
    dataLayer: {
      event: 'view_item',
      event_category: 'ecommerce',
      items: [
        {
          item_id: product.id,
          item_name: product.productName,
          affiliation: product.supplier?.name,
          index: 0,
          item_brand: product.brandName,
          item_category: product.category,
          item_list_id: widgetId,
          item_list_name: getItemListName(widgetId as ItemListId) as string,
          item_variant: `1 ${product.unit} / ${product.quantityUnit} Kg`,
          price,
          quantity: product.quantityUnit,
        },
      ],
      currency: Currency.IDR,
      value: price,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const trackPageDetailPakanScrolled = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'scroll_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPageDetailPakanBack = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pakan',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductDetailViewed = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Product Detail Viewed',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCatalogueProductDetailShareClicked = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_share_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pakan',
    },
  };
  dataLayer(tagManagerArgs);
};
export const trackProductCatalogueOrderClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Order - Beli via  Whatsapp Clicked',
      eventCategory,
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPesanProductClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Pesan Product Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackContinueToCartClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_checkout_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductDetailClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Product Detail Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCardClickedFromLastBought = (product) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_detail_reorder_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: product?.name,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCardBtnAddClickedFromLastBought = (product) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_reorder_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: product?.name,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCardClickedFromRecommendation = (
  sectionId: ItemListId,
  sectionName: ItemListName,
  data: any[],
) => {
  const items = normalizerCommerceItem(data, sectionId, sectionName);
  const tagManagerArgs: MerkleTrakerProps = {
    dataLayer: {
      event: 'select_item',
      event_category: 'ecommerce',
      item_list_id: sectionId,
      item_list_name: sectionName,
      items,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const trackProductCardBtnAddClickedFromRecommendation = (product) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_rekomendasi_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: product?.name,
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCardClicked = (product, widgetId = '') => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: product?.name,
      pageTitle: 'Beli Pakan Home',
      widgetId,
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductCardBtnAddClicked = (product, widgetId = '') => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: product?.name,
      pageTitle: 'Beli Pakan Home',
      widgetId,
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPilihanTempoClicked = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tempo_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pakan',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackMoreDescriptionClicked = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_deskripsi_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pakan',
    },
  };

  dataLayer(tagManagerArgs);
};
export const trackMoreNutritionClicked = (sku) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_informasi_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: sku,
      pageTitle: 'Detail Pakan',
    },
  };

  dataLayer(tagManagerArgs);
};

export const trackTambahPakanDetail = (sku, widgetId = '') => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_detail_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: sku,
      pageTitle: 'Detail Pakan',
      widgetId,
    },
  };

  dataLayer(tagManagerArgs);
};

export const trackConfirmChangeBrandClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Ganti Merek Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackCancelChangeBrandClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Batal Ganti Merek Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackCartPageViewed = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Cart Page Viewed',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackCartPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackDeleteItemCartClicked = (sku) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_hapus_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: sku,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackAddItemOnCartClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackBackButtonOnCartClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackBackButtonKonfirmasi = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_konfirmasi_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Konfirmasi Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPickOnAgentClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_ambil_metode_pengiriman',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackDeliverToHomeClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_dikirim_metode_pengiriman',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackToPromoPageClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tambah_promo',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackChoosePromoLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_promo_checkout',
      eventCategory: 'Promo',
      eventLabel: '',
      pageTitle: 'Pilih Promo',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackChoosePromoBackClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_promo_checkout',
      eventCategory: 'Promo',
      eventLabel: '',
      pageTitle: 'Pilih Promo',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackChoosePromoCardClicked = (promoName, isUsed) => {
  const tagManagerArgs = {
    dataLayer: {
      event: isUsed ? 'btn_batal_promo_checkout' : 'btn_pakai_promo_checkout',
      eventCategory: 'Promo',
      eventLabel: promoName,
      pageTitle: 'Pilih Promo',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackChoosePromoUseBtnClicked = (data = []) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_gunakan_promo_checkout',
      eventCategory: 'Promo',
      eventLabel: data.join(','),
      pageTitle: 'Pilih Promo',
    },
  };
  dataLayer(tagManagerArgs);
};
export const trackKabayanOptionClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_kabayan_jenis_pembayaran',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackChooseTenorCardClicked = (tenor) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Pilih Tempo Kabayan Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: tenor,
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackUseTenorClicked = (tenor) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'card_tenor_detail_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: tenor,
      pageTitle: 'Detail Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackKabayanTenorDetailViewed = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_detail_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Detail Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackKabayanTenorDetailBackClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_detail_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Detail Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackKabayanTenorDetailResetClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_atur_detail_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Detail Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackToPaymentPageClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Lanjut ke Pembayaran Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackToConfirmationPageClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_beli_sekarang',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPaymentPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Pembayaran Page on Transaction Loaded',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackDetailOnPaymentPageClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_rincian_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackBackButtonPaymentPageClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackViewPurchaseStatusClicked = (orderId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_status_pembelian_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: orderId,
      pageTitle: 'Success Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackPayWithKabayanClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_bayar_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackConfirmPayWithKabayanClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Ya, Lanjut Bayar Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackRecheckPayWithKabayanClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Cek Lagi on Bayar Clicked',
      eventCategory: 'Self Transaction',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackSuccessPageLoaded = (orderId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_success_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: orderId,
      pageTitle: 'Success Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackSuccessPageBackClicked = (orderId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_success_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: orderId,
      pageTitle: 'Success Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackShopAgainClicked = (orderId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_belanja_lagi_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: orderId,
      pageTitle: 'Success Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackCashMethodClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_tunai_jenis_pembayaran',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackCoachmarkBeliPakan = (coachmarkTitle) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Tracking Coachmark Self Transaction Journey',
      eventCategory: 'Coachmark Self Transaction Journey',
      eventLabel: coachmarkTitle,
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackSearchFocus = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'input_search_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackSuggestionClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'Using Suggest Search eFisheryKu',
      eventCategory: 'Search Box eFisheryKu',
      eventLabel: '',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackBeliPakanPageScrolled = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'scroll_beli_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackAllBrandPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_semua_merk',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Semua Merk',
    },
  };
  dataLayer(tagManagerArgs);
};
export const trackModalDeleteItemLoaded = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'bts_hapus_barang_confirmation',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackAllBrandPageBackClicked = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_semua_merk',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Semua Merk',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackModalDeleteItemClosed = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_close_hapus_barang_confirmation',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackAllBrandPageCardClicked = (brand) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'card_merk_pakan',
      eventCategory: 'Beli Pakan',
      eventLabel: brand,
      pageTitle: 'Semua Merk',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackDecreaseItemCart = (sku) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_reduce_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: sku,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackModalDeleteItemCancelClicked = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_batal_hapus_barang_confirmation',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackIncreaseItemCart = (sku) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_add_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: sku,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackModalDeleteItemYesClicked = (productName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_ya_hapus_barang_confirmation',
      eventCategory: 'Beli Pakan',
      eventLabel: productName,
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackScrollCartPage = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'scroll_detail_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Detail Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackScrollConfirmationPage = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'scroll_konfirmasi_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Konfirmasi Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackKonfirmasiPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_konfirmasi_pembelian',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Konfirmasi Pembelian',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackKabayanConfirmationPageLoaded = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'page_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackBackButtonKonfirmasiKabayan = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'btn_back_pembayaran_tenor',
      eventCategory: 'Tenor Payment',
      eventLabel: '',
      pageTitle: 'Pembayaran Tenor',
    },
  };
  dataLayer(tagManagerArgs);
};

export const trackProductWidgetAppear = (
  sectionId: ItemListId,
  sectionName: ItemListName,
  data: any[],
) => {
  const items = normalizerCommerceItem(data, sectionId, sectionName);
  const tagManagerArgs: MerkleTrakerProps = {
    dataLayer: {
      event: 'view_item_list',
      event_category: 'ecommerce',
      item_list_id: sectionId,
      item_list_name: sectionName,
      items,
    },
  };
  dataLayerMerkle(tagManagerArgs);
};

export const openCatalogPakanTracker = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'order_buka_katalog_clicked',
      eventCategory: 'Beli Pakan',
      eventLabel: '',
      pageTitle: 'Beli Pakan Home',
    },
  };
  dataLayer(tagManagerArgs);
};

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const ProfilePages = loadable(() => import(/* webpackChunkName: "ProfilePages" */ './Profile'), {
  fallback: <LoadingScreen />,
});

const EditProfilePages = loadable(
  () => import(/* webpackChunkName: "ProfilePages" */ './EditProfilePage'),
  {
    fallback: <LoadingScreen />,
  },
);

const ChangePhonePages = loadable(
  () => import(/* webpackChunkName: "ChangePhonePages" */ './ChangePhonePage'),
);

export const profileRoutes: Route[] = [
  {
    name: 'Profile',
    isGuest: false,
    routeProps: {
      exact: true,
      path: PATH.PROFILE,
      component: ProfilePages,
    },
  },
  {
    name: 'EditProfile',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.EDIT_PROFILE,
      component: EditProfilePages,
    },
  },
  {
    name: 'ChangePhone',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.SETTING_CHANGE_NUMBER,
      component: ChangePhonePages,
    },
  },
];

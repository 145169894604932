import { Button, ButtonAnchor } from './style';

/**
 * NOTE:
 * https://stackoverflow.com/questions/58094415/styling-react-router-dom-link-using-styled-components-getting-warning-when-passi
 * https://styled-components.com/docs/api#transient-props
 */
const renameButtonAnchorProps = ({
  isGhost,
  isSecondary,
  disabled,
  isSquare,
  isLarge,
  isSmall,
  isOrange,
  isAnchor,
  isWidthAuto,
  isLoading,
  isCircle,
  isRed,
  isDotted,
  ...rest
}: IButtonProps) => {
  return {
    $isGhost: isGhost,
    $isSecondary: isSecondary,
    $disabled: disabled,
    $isSquare: isSquare,
    $isLarge: isLarge,
    $isSmall: isSmall,
    $isOrange: isOrange,
    $isAnchor: isAnchor,
    $isWidthAuto: isWidthAuto,
    $isLoading: isLoading,
    $isCircle: isCircle,
    $isRed: isRed,
    $isDotted: isDotted,
    ...rest,
  };
};

const ButtonComp: React.FunctionComponent<
  IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>
> = (props) => {
  const { isAnchor, to, ...rest } = props;

  return isAnchor ? (
    <ButtonAnchor to={to || ''} {...renameButtonAnchorProps(rest)} />
  ) : (
    <Button {...rest} />
  );
};

export interface IButtonProps {
  isGhost?: boolean;
  isSecondary?: boolean;
  disabled?: boolean;
  isSquare?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isOrange?: boolean;
  isAnchor?: boolean;
  isWidthAuto?: boolean;
  isLoading?: boolean;
  isCircle?: boolean;
  isRed?: boolean;
  isDotted?: boolean;
  to?: string;
  css?: any;
}

export interface IButtonAnchorProps {
  $isGhost?: boolean;
  $isSecondary?: boolean;
  $disabled?: boolean;
  $isSquare?: boolean;
  $isLarge?: boolean;
  $isSmall?: boolean;
  $isOrange?: boolean;
  $isAnchor?: boolean;
  $isWidthAuto?: boolean;
  $isLoading?: boolean;
  $isCircle?: boolean;
  $isRed?: boolean;
  $isDotted?: boolean;
  to?: string;
  css?: any;
}

export default ButtonComp;

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const HelpPage = loadable(() => import(/* webpackChunkName: "HelpPage" */ './Help'), {
  fallback: <LoadingScreen />,
});

export const helpRoutes: Route[] = [
  {
    name: 'Help',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.HELP,
      component: HelpPage,
    },
  },
];

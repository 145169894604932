import { CULTIVATION_FORM } from 'env';
import { Cookies } from 'react-cookie';
import { render } from 'react-dom';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import {
  isTokenExpiresChanged as isTokenExpiresChangedKey,
  isTokenExpiresChangedExpires,
  tokenAuth as tokenAuthKey,
  tokenAuthExpires,
  tokenAuthKolamku,
} from 'utils/constants/cookies';
import { registerFirebase } from 'utils/helpers/firebase';

import App from './App';
import GlobalStyles from './GlobalStyles';
import { initialize as initializeSentry, reportAxiosError } from './utils/helpers/errorReporter';

initializeSentry();
reportAxiosError();

//  #region Update Expires Token (TODO: Delete if needs any more)
const checkTokenAuth = () => {
  const cookies = new Cookies();
  const token = cookies.get(tokenAuthKey);
  const isTokenExpiresChanged = cookies.get(isTokenExpiresChangedKey) === 'true';

  if (token && !isTokenExpiresChanged) {
    cookies.set(tokenAuthKey, token, {
      path: '/',
      expires: tokenAuthExpires,
    });
    if (CULTIVATION_FORM) {
      cookies.set(tokenAuthKolamku, token, {
        path: '/',
        domain: '.efishery.com',
        expires: tokenAuthExpires,
      });
    }

    cookies.set(isTokenExpiresChangedKey, true, {
      path: '/',
      expires: isTokenExpiresChangedExpires,
    });
  }
};
checkTokenAuth();
// #endregion

// GTM
const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CODE || '',
};
TagManager.initialize(tagManagerArgs);

/**
 * This function will be called when
 * the element is Renderred (put in `ref`).
 */
const renderCallback = () => {
  registerFirebase();
};

render(
  <main id="wrapper" ref={renderCallback}>
    <GlobalStyles />
    <App />
  </main>,
  document.getElementById('root'),
);

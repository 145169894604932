export const getFirstWord = (str) => {
  if (!str) return '';
  const tempStr = str.split(' ');
  return tempStr[0] || '';
};

export const capitalizeWord = (str) => {
  if (!str) return '';
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(' ');
  return str2;
};

export const capitalizeBrand = (str, sliced = true) => {
  if (!str) return '';
  const arr = str.toLowerCase().split(' ');

  if (arr.length > 1) return capitalizeWord(str);
  if (str.length <= 3) return str.toUpperCase();

  let brand = capitalizeWord(str);
  if (sliced) brand = brand.length > 7 ? `${brand.slice(0, 7)}...` : brand;
  return brand;
};

export const capitalizeAll = (str) => {
  if (!str) return '';
  return str.toUpperCase();
};

export const eliminateRegionCode = (str) => {
  if (!str) return '';
  if (str.startsWith('62')) return str.slice(2);
  return str;
};

export const zeroEightNormalizer = (str) => {
  if (str.startsWith('62')) return `0${str.slice(2)}`;
  return str;
};

export const eliminateDoubleRegion = (str) => {
  if (!str) return '';

  const arr = str.toString().substring(0, 4);
  if (arr === '6262') return str.toString().substring(2, str.length);
  return str;
};

export const addRegionCode = (str) => {
  if (!str) return '';
  if (str.startsWith('08')) {
    return `628${str.slice(2)}`;
  }
  return str;
};

export const getInitialName = (str) => {
  if (!str) return 'A';

  return str.toString().substr(0, 1);
};

export const convertCurrencyToNumber = (str) => {
  if (!str) return '';

  return str.replace(/[^0-9.-]+/g, '');
};

export const convertStringCurrencyToInt = (str) => {
  if (!str) return 0;
  return +str.replace(/[^0-9-]+/g, '');
};

export const isUrl = (url) => {
  const regex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return regex.test(url);
};

export const sliceString = (str, length) => {
  if (!str) return '';
  return `${str.slice(0, length)}${str.length > length ? '...' : ''}`;
};

export const lowerCase = (str) => {
  if (!str) return str;
  return str.toLowerCase();
};

export const removeUnnecessaryChar = (string) => {
  if (!string) return '';
  return string
    .replace(' ', '')
    .replace(/\./g, '')
    .replace(/,/g, '.')
    .replace('Rp', '')
    .replace('kg', '')
    .replace('/', '')
    .replace('Bulan', '');
};

export const randomString = (length = 16) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const removeCharacters = (str, char) => {
  if (!str) return '';
  return String(str).replace(char, '');
};

export const truncateString = (str, minLength) => {
  if (!str) return '';
  if (str.length > minLength) {
    return `${str.substring(0, minLength)}...`;
  }
  return str;
};

export const extracDataFromAddressString = (address) => {
  const [, postalAddress = address, rt = '', rw = ''] =
    /^(.+); RT(\d+)\/RW(\d+)$/.exec(address) || [];
  return { postalAddress, rt, rw };
};

/**
 * Apply value to template string.
 *
 * Example:
 * applyValueToTemplate('{{name}} has {{color}} book', { name: 'John Doe', color: 'blue' });
 * Will return 'John Doe has blue book'
 */
export const applyToTemplate = (str, obj = {}) => str.replace(/{{(\w+)}}/g, (_, k) => obj[k]);

export const toSnakeCase = (str) => {
  if (!str) return '';
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');
};

export const toCamelCase = (str) => {
  if (!str) return '';
  return str.replace(/[_-]([a-z]|[A-Z])/g, (_match, p1) => p1.toUpperCase());
};

export const isEmptyOrSpaces = (str) => {
  return (
    str == null ||
    typeof str !== 'string' ||
    (typeof str === 'string' && str.match(/^ *$/) !== null)
  );
};

export const parseStringToDecimal = (val, isNegative = false) => {
  if (typeof val === 'number') return val;
  const regex = isNegative ? /[^0-9.-]/g : /[^0-9.]/g;
  return !isEmptyOrSpaces(val)
    ? parseFloat(val.split('.').join('').split(',').join('.').replace(regex, ''))
    : 0;
};

export const parseStringToInt = (val) => {
  if (typeof val === 'number') return val;
  return !isEmptyOrSpaces(val)
    ? parseInt(
        val
          .split('.')
          .join('')
          .replace(/[^0-9.]/g, ''),
      )
    : 0;
};

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const ProductDetailPages = loadable(
  () => import(/* webpackChunkName: "ProductDetailPages" */ './ProductDetailPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const productDetailRoutes: Route[] = [
  {
    name: 'ProductDetail',
    isPublic: true,
    routeProps: {
      exact: false,
      path: PATH.PRODUCT_DETAIL,
      component: ProductDetailPages,
    },
  },
];

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const SearchPages = loadable(() => import(/* webpackChunkName: "SearchPages" */ './SearchPage'), {
  fallback: <LoadingScreen />,
});

export const searchRoutes: Route[] = [
  {
    name: 'Search',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.SEARCH,
      component: SearchPages,
    },
  },
];

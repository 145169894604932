import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';

import { sawarnaV2Fetcher } from 'utils/fetcher';

import * as Types from '../../../types/sawarna-v2.gen';

export const LogoutDocument = `
    mutation Logout {
  seluang_Logout {
    message
    success
  }
}
    `;
export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.LogoutMutation,
    TError,
    Types.LogoutMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.LogoutMutation, TError, Types.LogoutMutationVariables, TContext>(
    ['Logout'],
    (variables?: Types.LogoutMutationVariables) =>
      sawarnaV2Fetcher<Types.LogoutMutation, Types.LogoutMutationVariables>(
        LogoutDocument,
        variables,
      )(),
    options,
  );
export const UserProfileOldDocument = `
    query UserProfileOld {
  userProfile: seluang_UserProfile {
    isNewFarmer
    isBlacklist
    name
    isKabayan
    phone
    birthDate
    location
    joinDate
    leadId
    provinceName
    provinceId
    cityName
    cityId
    districtName
    districtId
    subdistrictName
    subdistrictId
    address
    postalNumber
    latitude
    longitude
    ktpNumber
    countNotifLbl
    countNotif
    point {
      code
      name
    }
    commodities {
      id
      name
    }
    user {
      email
      picture
    }
  }
}
    `;
export const useUserProfileOldQuery = <TData = Types.UserProfileOldQuery, TError = unknown>(
  variables?: Types.UserProfileOldQueryVariables,
  options?: UseQueryOptions<Types.UserProfileOldQuery, TError, TData>,
) =>
  useQuery<Types.UserProfileOldQuery, TError, TData>(
    variables === undefined ? ['UserProfileOld'] : ['UserProfileOld', variables],
    sawarnaV2Fetcher<Types.UserProfileOldQuery, Types.UserProfileOldQueryVariables>(
      UserProfileOldDocument,
      variables,
    ),
    options,
  );
export const useInfiniteUserProfileOldQuery = <TData = Types.UserProfileOldQuery, TError = unknown>(
  pageParamKey: keyof Types.UserProfileOldQueryVariables,
  variables?: Types.UserProfileOldQueryVariables,
  options?: UseInfiniteQueryOptions<Types.UserProfileOldQuery, TError, TData>,
) => {
  return useInfiniteQuery<Types.UserProfileOldQuery, TError, TData>(
    variables === undefined ? ['UserProfileOld.infinite'] : ['UserProfileOld.infinite', variables],
    (metaData) =>
      sawarnaV2Fetcher<Types.UserProfileOldQuery, Types.UserProfileOldQueryVariables>(
        UserProfileOldDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

export const UserProfileNewDocument = `
    query UserProfileNew {
  userProfile: seluang_UserProfile {
    isNewFarmer
    isBlacklist
    name
    isKabayan
    phone
    birthDate
    location
    joinDate
    leadId
    provinceName
    provinceId
    cityName
    cityId
    districtName
    districtId
    subdistrictName
    subdistrictId
    address
    postalNumber
    latitude
    longitude
    ktpNumber
    countNotifLbl
    countNotif
    mainCommodity
    point {
      code
      name
    }
    commodities {
      id
      name
    }
    user {
      email
      picture
    }
  }
}
    `;
export const useUserProfileNewQuery = <TData = Types.UserProfileNewQuery, TError = unknown>(
  variables?: Types.UserProfileNewQueryVariables,
  options?: UseQueryOptions<Types.UserProfileNewQuery, TError, TData>,
) =>
  useQuery<Types.UserProfileNewQuery, TError, TData>(
    variables === undefined ? ['UserProfileNew'] : ['UserProfileNew', variables],
    sawarnaV2Fetcher<Types.UserProfileNewQuery, Types.UserProfileNewQueryVariables>(
      UserProfileNewDocument,
      variables,
    ),
    options,
  );
export const useInfiniteUserProfileNewQuery = <TData = Types.UserProfileNewQuery, TError = unknown>(
  pageParamKey: keyof Types.UserProfileNewQueryVariables,
  variables?: Types.UserProfileNewQueryVariables,
  options?: UseInfiniteQueryOptions<Types.UserProfileNewQuery, TError, TData>,
) => {
  return useInfiniteQuery<Types.UserProfileNewQuery, TError, TData>(
    variables === undefined ? ['UserProfileNew.infinite'] : ['UserProfileNew.infinite', variables],
    (metaData) =>
      sawarnaV2Fetcher<Types.UserProfileNewQuery, Types.UserProfileNewQueryVariables>(
        UserProfileNewDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

export const UserKabayanDocument = `
    query UserKabayan {
  userKabayan: seluang_GetKabayanBalance {
    customerId
    formattedSaldo
    isKabayan
    isKabayanProgress
    noPks
    plafon
    plafonFeeder
    saldo
    kabayanTypeId
    status
    histories {
      status
    }
    isHasPks
    isPksActive
    lastPksKabayanStatus
    ktpNumber
    isKabexSubmitted
    provision
    kabayanTypeCode
    funderName
  }
}
    `;
export const useUserKabayanQuery = <TData = Types.UserKabayanQuery, TError = unknown>(
  variables?: Types.UserKabayanQueryVariables,
  options?: UseQueryOptions<Types.UserKabayanQuery, TError, TData>,
) =>
  useQuery<Types.UserKabayanQuery, TError, TData>(
    variables === undefined ? ['UserKabayan'] : ['UserKabayan', variables],
    sawarnaV2Fetcher<Types.UserKabayanQuery, Types.UserKabayanQueryVariables>(
      UserKabayanDocument,
      variables,
    ),
    options,
  );
export const useInfiniteUserKabayanQuery = <TData = Types.UserKabayanQuery, TError = unknown>(
  pageParamKey: keyof Types.UserKabayanQueryVariables,
  variables?: Types.UserKabayanQueryVariables,
  options?: UseInfiniteQueryOptions<Types.UserKabayanQuery, TError, TData>,
) => {
  return useInfiniteQuery<Types.UserKabayanQuery, TError, TData>(
    variables === undefined ? ['UserKabayan.infinite'] : ['UserKabayan.infinite', variables],
    (metaData) =>
      sawarnaV2Fetcher<Types.UserKabayanQuery, Types.UserKabayanQueryVariables>(
        UserKabayanDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

export const UserKabayanV2Document = `
    query UserKabayanV2 {
  userKabayan: seluang_GetKabayanBalance {
    customerId
    formattedSaldo
    isKabayan
    isKabayanProgress
    noPks
    plafon
    plafonFeeder
    saldo
    kabayanTypeId
    status
    histories {
      status
    }
    isHasPks
    isPksActive
    lastPksKabayanStatus
    ktpNumber
    isKabexSubmitted
    provision
    kabayanTypeCode
    isHardRejected
    isRejectedOnCallVerif
    nominationId
    rejectedDate
    funderName
  }
}
    `;
export const useUserKabayanV2Query = <TData = Types.UserKabayanV2Query, TError = unknown>(
  variables?: Types.UserKabayanV2QueryVariables,
  options?: UseQueryOptions<Types.UserKabayanV2Query, TError, TData>,
) =>
  useQuery<Types.UserKabayanV2Query, TError, TData>(
    variables === undefined ? ['UserKabayanV2'] : ['UserKabayanV2', variables],
    sawarnaV2Fetcher<Types.UserKabayanV2Query, Types.UserKabayanV2QueryVariables>(
      UserKabayanV2Document,
      variables,
    ),
    options,
  );
export const useInfiniteUserKabayanV2Query = <TData = Types.UserKabayanV2Query, TError = unknown>(
  pageParamKey: keyof Types.UserKabayanV2QueryVariables,
  variables?: Types.UserKabayanV2QueryVariables,
  options?: UseInfiniteQueryOptions<Types.UserKabayanV2Query, TError, TData>,
) => {
  return useInfiniteQuery<Types.UserKabayanV2Query, TError, TData>(
    variables === undefined ? ['UserKabayanV2.infinite'] : ['UserKabayanV2.infinite', variables],
    (metaData) =>
      sawarnaV2Fetcher<Types.UserKabayanV2Query, Types.UserKabayanV2QueryVariables>(
        UserKabayanV2Document,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

export const UserKabayanV3Document = `
    query UserKabayanV3 {
  userKabayan: seluang_GetKabayanBalance {
    customerId
    formattedSaldo
    isKabayan
    isKabayanProgress
    noPks
    plafon
    plafonFeeder
    saldo
    kabayanTypeId
    status
    histories {
      status
    }
    isHasPks
    isPksActive
    lastPksKabayanStatus
    ktpNumber
    isKabexSubmitted
    provision
    kabayanTypeCode
    isHardRejected
    isRejectedOnCallVerif
    nominationId
    rejectedDate
    funderName
    submisionType
  }
}
    `;
export const useUserKabayanV3Query = <TData = Types.UserKabayanV3Query, TError = unknown>(
  variables?: Types.UserKabayanV3QueryVariables,
  options?: UseQueryOptions<Types.UserKabayanV3Query, TError, TData>,
) =>
  useQuery<Types.UserKabayanV3Query, TError, TData>(
    variables === undefined ? ['UserKabayanV3'] : ['UserKabayanV3', variables],
    sawarnaV2Fetcher<Types.UserKabayanV3Query, Types.UserKabayanV3QueryVariables>(
      UserKabayanV3Document,
      variables,
    ),
    options,
  );
export const useInfiniteUserKabayanV3Query = <TData = Types.UserKabayanV3Query, TError = unknown>(
  pageParamKey: keyof Types.UserKabayanV3QueryVariables,
  variables?: Types.UserKabayanV3QueryVariables,
  options?: UseInfiniteQueryOptions<Types.UserKabayanV3Query, TError, TData>,
) => {
  return useInfiniteQuery<Types.UserKabayanV3Query, TError, TData>(
    variables === undefined ? ['UserKabayanV3.infinite'] : ['UserKabayanV3.infinite', variables],
    (metaData) =>
      sawarnaV2Fetcher<Types.UserKabayanV3Query, Types.UserKabayanV3QueryVariables>(
        UserKabayanV3Document,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

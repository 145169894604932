import 'twin.macro';

import React, { FunctionComponent, useEffect, useState } from 'react';
import { CheckCircle } from '@onefish/icons-react';

import { Timer } from '../../utils';
import {
  Button,
  SnackbarAction,
  SnackbarCapsule,
  SnackbarContainer,
  SnackbarContent,
} from './style';

const duration = 250;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  transform: `translateY(0px)`,
};

const transitionStyles = {
  entering: { opacity: 0, transform: `translateY(20px)` },
  entered: { opacity: 1, transform: `translateY(0px)` },
  exiting: { opacity: 0, transform: `translateY(20px)` },
  exited: { opacity: 0, transform: `translateY(20px)` },
};

type ITransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

interface SnackbarProps {
  onClose: () => void;
  onClickButton?: () => void;
  content: React.ReactNode;
  autoDismiss?: boolean;
  autoDismissTimeout: number;
  isActionBlock?: boolean;
  transitionState: ITransitionState;
  buttonText?: string;
  buttonTextWhite?: boolean;
  isSuccess: boolean;
  testId?: string;
}

const Snackbar: FunctionComponent<SnackbarProps> = (props) => {
  const [, setRunning] = useState(false);
  const [timeout, setTimer] = useState<any>(null);
  const {
    onClickButton,
    onClose,
    content,
    autoDismiss,
    autoDismissTimeout,
    buttonText,
    buttonTextWhite,
    transitionState,
    isActionBlock,
    isSuccess,
    testId,
  } = props;

  const startTimer = () => {
    setRunning(true);
    if (!autoDismiss) return;
    setTimer(new (Timer as any)({ callback: onClose, delay: autoDismissTimeout }));
  };

  const clearTimer = () => {
    if (timeout) timeout.clear();
  };

  const handleMouseEnter = () => {
    if (!timeout) return;

    setRunning(false);
    timeout.pause();
  };

  const handleMouseLeave = () => {
    if (!timeout) return;

    setRunning(true);
    timeout.resume();
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearTimer();
    };
  }, [autoDismiss]);

  return (
    <SnackbarContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isActionBlock={isActionBlock}
      style={{
        ...defaultStyle,
        ...transitionStyles[transitionState],
      }}
    >
      <SnackbarCapsule isSuccess={isSuccess}>
        {isSuccess && <CheckCircle weight="fill" size={24} tw="mr-1" />}
        <SnackbarContent>{content}</SnackbarContent>
        <SnackbarAction isActionBlock={isActionBlock}>
          <Button
            type="button"
            onClick={() => {
              if (onClickButton && typeof onClickButton === 'function') onClickButton();
              onClose();
            }}
            isSmall
            isGhost
            textWhite={isSuccess || buttonTextWhite}
            data-testid={`btn_snackbar_${testId}`}
          >
            {buttonText}
          </Button>
        </SnackbarAction>
      </SnackbarCapsule>
    </SnackbarContainer>
  );
};

Snackbar.defaultProps = {
  buttonText: 'OK',
  buttonTextWhite: false,
  isActionBlock: false,
  autoDismiss: true,
};

export default Snackbar;

import { FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Modal from 'react-modal';

import AppContainer from './containers/AppContainer';
import Routing from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 1000, // 3 seconds
    },
  },
});

const App: FunctionComponent = () => (
  <div id="modalWrapper">
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppContainer>
        <Routing />
      </AppContainer>
    </QueryClientProvider>
  </div>
);

Modal.setAppElement('#root');

export default App;

import loadable from '@loadable/component';

import { PATH } from '../../../routes/constants';

const PrivacyPolicyPages = loadable(() =>
  import(/* webpackChunkName: "PrivacyPolicyPages" */ './PrivacyPolicy'),
);
export const privacyPolicyRoutes = [
  {
    name: 'Privacy Policy',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.PRIVACY_POLICY,
      component: PrivacyPolicyPages,
    },
  },
];

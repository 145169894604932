import { CUSTOMER_SERVICE_PHONE_NUMBER } from 'env';

export const PHONE_NUMBER = CUSTOMER_SERVICE_PHONE_NUMBER;

export const DEFAULT_SUPPORT_INFO = {
  _useNewHelpPage: true,
  companyName: 'PT. Multidaya Teknologi Nusantara',
  email: 'cs@efishery.com',
  callCenter: '622230509969',
  sms: '6281188818832',
  wa: '6281188818832',
  otherInformations: [
    {
      body: [
        'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
        'Kementerian Perdagangan Republik Indonesia',
      ],
      footer: {
        label: 'WhatsApp Ditjen PKTN',
        value: '0853 1111 1010',
      },
      name: 'pktn',
    },
  ],
};

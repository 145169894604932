import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const maskPhoneNumber = [
  /0/,
  /8/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const maskIDCard = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const maskNumber = createNumberMask({
  prefix: '',
  suffix: '',
});

export const maskNumberOnly = createNumberMask({
  prefix: '',
  suffix: '',
  thousandsSeparatorSymbol: '',
});

export const maskNumberDot = createNumberMask({
  prefix: '',
  suffix: '',
  thousandsSeparatorSymbol: '.',
});

export const maskNumbers =
  ({
    prefix = '',
    suffix = '',
    allowDecimal = false,
    allowLeadingZeroes = false,
    allowNegative = false,
    disableStartZero = false,
  } = {}) =>
  (rawValue: string) => {
    const numberMask = createNumberMask({
      prefix: prefix ? `${prefix} ` : '',
      suffix: suffix ? ` ${suffix}` : '',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal,
      allowLeadingZeroes,
      allowNegative,
    });
    const mask: any = numberMask(rawValue);
    if (disableStartZero) {
      mask[0] = /[1-9]/;
    }
    return mask;
  };

export const maskNumberDotWithSuffix = (suffix) =>
  createNumberMask({
    prefix: '',
    suffix: ` ${suffix}`,
    thousandsSeparatorSymbol: '.',
  });

export const maskCurrency = createNumberMask({
  prefix: 'Rp ',
  suffix: '',
  thousandsSeparatorSymbol: '.',
});

export const maskCurrencyPerUnit = (unit) =>
  createNumberMask({
    prefix: 'Rp ',
    suffix: `/${unit}`,
    thousandsSeparatorSymbol: '.',
  });

export const maskUnit = (unit?: string, integerLimit?: number, allowDecimal = false) =>
  createNumberMask({
    prefix: '',
    suffix: ` ${unit}`,
    integerLimit,
    allowDecimal,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
  });

export const maskFCR = [/[0-9]/, /[,.]/, /[0-9]/, /[0-9]/];

export const maskPool = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskDigit = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const onlyAlphabet = (s) => Array.from(s).map(() => /([a-zA-Z\s])+$/i);

import tw, { theme } from 'twin.macro';

import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IButtonAnchorProps, IButtonProps } from '.';
import Loading from './loading.svg';
import LoadingSecondary from './loadingSecondary.svg';

const baseButton = css`
  ${tw`
  focus:outline-none  bg-observatory-500 border rounded-lg w-full border-solid border-observatory-500
  hover:bg-observatory-600 hover:border-observatory-600
  active:bg-observatory-400 active:border-observatory-400 
  text-base flex items-center justify-center transition-all
  text-white cursor-pointer relative px-4 
`}
  padding-top: 7px;
  padding-bottom: 7px;

  & svg {
    margin-bottom: 3px;
  }
`;

const orangeButton = tw`bg-orange-400 hover:bg-orange-500 hover:border-orange-500  border-orange-400 active:bg-orange-400 active:border-orange-400 `;
const redButton = tw`bg-red-500 border-red-500  hover:bg-red-600 hover:border-red-600 active:bg-red-500 active:border-red-500`;
const secondaryRedButton = tw`bg-white border-solid border-red-500 text-red-500 hover:bg-red-100 active:bg-red-200`;

const baseButtonDisabled = tw`
bg-black-200 border-black-200
hover:bg-black-200 hover:border-black-200
`;

const secondaryButton = css`
  ${tw`
  bg-white border-solid border-observatory-500 text-observatory-500
  hover:bg-observatory-100 
  active:bg-observatory-200
`}
`;

const secondaryButtonDisabled = css`
  color: ${theme`colors.black.200`};
  ${tw`
  bg-transparent hover:bg-transparent
`};
`;

const ghostButton = css`
  ${tw`border-transparent bg-transparent text-observatory-500
  hover:bg-observatory-100 hover:border-observatory-100
    active:bg-transparent active:border-transparent
  `}
`;

const ghostButtonDisabled = tw`bg-transparent hover:bg-transparent border-transparent hover:border-transparent text-black-200`;

const smallButton = css`
  ${tw`text-sm`}
  padding-top: 5px;
  padding-bottom: 5px;
`;

const largeButton = css`
  padding-top: 11px;
  padding-bottom: 11px;
`;

const squareButton = css`
  ${tw`p-2`}
  width: 38px;
  height: 38px;
`;

const squareButtonLarge = css`
  width: 46px;
  height: 46px;
`;

const squareButtonSmall = css`
  width: 32px;
  height: 32px;
`;

const marginSvg = css`
  & svg {
    margin-right: 10px;
  }
`;

const loadingButton = css`
  font-size: 0px;

  & svg {
    width: 0px;
  }

  &::before {
    content: '';
    background-image: url(${Loading});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-size: 30px;
  }
`;

const loadingSecondary = css`
  &::before {
    background-image: url(${LoadingSecondary});
  }
`;

const baseWidthAuto = css`
  width: auto;
`;

const circleButton = css`
  border-radius: 9999px;
`;

const dottedButton = css`
  border-style: dashed;
`;

export const Button = styled.button<IButtonProps>(
  ({
    isGhost,
    isSecondary,
    disabled,
    isSquare,
    isLarge,
    isSmall,
    isLoading,
    isCircle,
    isRed,
    isWidthAuto,
    isOrange,
    isDotted,
  }) => [
    baseButton,

    // Type
    isSecondary && secondaryButton,
    isGhost && ghostButton,

    // Color
    isOrange && orangeButton,
    isRed && redButton,
    isRed && isSecondary && secondaryRedButton,

    // Size Button
    isSmall && smallButton,
    isLarge && largeButton,

    !isSquare && marginSvg,

    // Square Button
    isSquare && squareButton,
    isSquare && isLarge && squareButtonLarge,
    isSquare && isSmall && squareButtonSmall,

    // Disabled
    disabled && baseButtonDisabled,
    disabled && isSecondary && secondaryButtonDisabled,
    disabled && isGhost && ghostButtonDisabled,

    isLoading && loadingButton,
    isLoading && isSecondary && loadingSecondary,
    isLoading && isGhost && loadingSecondary,

    isCircle && circleButton,

    isWidthAuto && baseWidthAuto,
    isDotted && dottedButton,
  ],
);

export const ButtonAnchor: FunctionComponent<IButtonAnchorProps> = styled(Link)<IButtonAnchorProps>(
  ({
    $isGhost,
    $isSecondary,
    $disabled,
    $isSquare,
    $isLarge,
    $isSmall,
    $isWidthAuto,
    $isOrange,
  }) => [
    baseButton,

    // Colors
    $isOrange && orangeButton,

    // Type
    $isSecondary && secondaryButton,
    $isGhost && ghostButton,

    // Size Button
    $isSmall && smallButton,
    $isLarge && largeButton,

    !$isSquare && marginSvg,

    // Square Button
    $isSquare && squareButton,
    $isSquare && $isLarge && squareButtonLarge,
    $isSquare && $isSmall && squareButtonSmall,

    // Disabled
    $disabled && baseButtonDisabled,
    $disabled && $isSecondary && secondaryButtonDisabled,
    $disabled && $isGhost && ghostButtonDisabled,

    $isWidthAuto && baseWidthAuto,
  ],
);

import 'twin.macro';

import React, { FunctionComponent } from 'react';
import { ArrowLeft } from '@onefish/icons-react';

import Button from '../Button';
import { Content, Header, HeaderContainer, HeaderContainerType, RightButton } from './style';

const HeaderComp: FunctionComponent<IHeaderComp> & StaticProps = (props) => {
  const {
    children,
    bottom,
    buttonCSS,
    onClickBack,
    isBackButton,
    gapTop,
    position,
    direction,
    testId,
    ...rest
  } = props;

  return (
    <HeaderContainer gapTop={gapTop} position={position} direction={direction}>
      <Header {...rest}>
        {isBackButton && (
          <Button
            css={buttonCSS}
            type="button"
            isGhost
            isSquare
            onClick={onClickBack}
            data-testid={testId && `btn_header_back_${testId}`}
          >
            <ArrowLeft size={24} />
          </Button>
        )}
        <Content data-testid={testId && `txt_header_${testId}`}>{children}</Content>
      </Header>
      {bottom && <Header>{bottom}</Header>}
    </HeaderContainer>
  );
};

// eslint-disable-next-line react/display-name
HeaderComp.RightButton = (props: any) => <RightButton {...props} />;

interface IHeaderComp extends HeaderContainerType {
  children?: React.ReactNode;
  bottom?: React.ReactNode;
  buttonCSS?: any;
  isBackButton?: boolean;
  isTransparent?: boolean;
  testId?: string;
  onClickBack?: () => void;
}

interface StaticProps {
  RightButton: any;
}

HeaderComp.defaultProps = {
  children: null,
  onClickBack: () => {},
  buttonCSS: [],
  isBackButton: true,
  gapTop: '25px',
  isTransparent: false,
  position: 'fixed',
};

export default HeaderComp;

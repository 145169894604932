export const TOP_LEFT = 'topLeft';
export const TOP_CENTER = 'topCenter';
export const TOP_RIGHT = 'topRight';

export const BOTTOM_LEFT = 'bottomLeft';
export const BOTTOM_RIGHT = 'bottomRight';
export const BOTTOM_CENTER = 'bottomCenter';

export const LIST_POSITION = [
  TOP_LEFT,
  TOP_CENTER,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  BOTTOM_CENTER,
];

import { gql } from 'graphql-request';

const postAddressSawarnaV2 = gql`
  mutation postAddress($userCreateLocationRequestInput: seluang_userCreateLocationRequest_Input!) {
    response: seluang_CreateLocation(input: $userCreateLocationRequestInput) {
      message
      success
    }
  }
`;

export default postAddressSawarnaV2;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const RegisterPages = loadable(() => import(/* webpackChunkName: "RegisterPages" */ './Register'), {
  fallback: <LoadingScreen />,
});

export const registerRoute: Route = {
  name: 'Register',
  isGuest: true,
  routeProps: {
    exact: false,
    path: PATH.REGISTER,
    component: RegisterPages,
  },
};

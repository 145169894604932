import { Sales, ShoppingCart } from '@onefish/icons-react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import Header from 'components/Header';
import { trackProductCatalogueBackToHomeClicked } from 'features/beli-pakan/utils/trackers';
import { PATH } from 'routes/constants';
import useBack from 'utils/hooks/useBack';
import useFlagrContext from 'utils/hooks/useFlagrContext';

import BannerTop from '../../assets/belanja-lewat-aplikasi-img.webp';
import BannerBottom from '../../assets/belanja-lewat-chat-img.webp';
import { Banner, Button, Container, Content, Divider, Link, SubTitle, Title } from './style';

export const CaraBelanjaPakan = () => {
  const history = useHistory();
  const { handleBack } = useBack();
  const { isFlagrFetched, shopViaChatUrl: waMeUrl } = useFlagrContext();

  const toBeliPakan = () => {
    history.push(PATH.BELI_PAKAN);
  };

  return (
    <Container>
      <Header
        testId="beli_pakan_home"
        onClickBack={() => {
          trackProductCatalogueBackToHomeClicked();
          handleBack();
        }}
      />
      <Content>
        {isFlagrFetched ? (
          <>
            <Title>Pilih Cara Belanja</Title>
            <SubTitle>Kamu bisa berbelanja sendiri atau dibantu oleh tim kami (chatbot)</SubTitle>

            {/* Top Banner */}
            <Banner src={BannerTop} alt="Belanja lewat aplikasi" />
            <Button onClick={toBeliPakan}>
              <ShoppingCart size={24} />
              Belanja Lewat Aplikasi
            </Button>

            {/* Divider */}
            <div
              style={{
                display: 'flex',
                margin: '20px 0',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Divider />
              <span style={{ color: '#A5A5A5' }}>atau</span>
              <Divider />
            </div>

            {/* Bottom Banner */}
            <Link href={waMeUrl}>
              <Sales size={24} />
              Belanja Lewat Chat
            </Link>
            <Banner src={BannerBottom} alt="Belanja lewat chat" />
          </>
        ) : (
          <div tw="space-x-6">
            <Skeleton width="100%" height={100} />
          </div>
        )}
      </Content>
    </Container>
  );
};

import tw, { css, styled } from 'twin.macro';

export const notifReport = css`
  .hello-icon {
    display: none;
  }
`;

export const containerFullHeight = (crop, isMinimum) => {
  return isMinimum
    ? css`
        min-height: ${process.env.REACT_APP_MODE === 'staging'
          ? `calc(100vh - 25px - ${crop || 0}px)`
          : `calc(100vh - ${crop || 0}px)`};
      `
    : css`
        height: ${process.env.REACT_APP_MODE === 'staging'
          ? `calc(100vh - 25px - ${crop || 0}px)`
          : `calc(100vh - ${crop || 0}px)`};
      `;
};

export const fullWidthFixed = css`
  width: 480px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const LoadingSpinner = styled.div`
  ${tw`flex items-center justify-center width[30px] height[30px]`};

  &:after {
    ${tw`block w-full h-full border-radius[50%] border-width[3px]`}
    content: " ";
    border-color: #a8a8a8 transparent #a8a8a8 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SpaceHeader = tw.div`w-full height[56px]`;
export const Background = styled.div`
  ${tw`fixed w-full h-full bg-white`};
  ${fullWidthFixed};
`;

export const shadowTop = tw`box-shadow[0 -2px 5px 0 rgba(0,0,0,0.1)]`;

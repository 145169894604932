import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SAWARNA_V2_SERVICES } from 'env';
import { FormikProps, useFormik } from 'formik';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';

import { useSnackbar } from 'components/Snackbar';
import { PATH } from 'routes/constants';
import { isGeolocationEnabled } from 'utils/helpers/navigator';
import useAuthContext from 'utils/hooks/useAuthContext';
import useBack from 'utils/hooks/useBack';
import useQuery from 'utils/hooks/useQuery';
import useRequest from 'utils/hooks/useRequest';

import { FORM_DEFAULT_VALUE, FORM_FIELD_NAME, getValidationSchema } from '../constants';
import postAddressMutation from '../graphql/mutations/postAddress';
import updateAddressMutation from '../graphql/mutations/updateAddress';
import { normalizePostAddress } from '../utils/normalizer';

export type AddressValue = {
  name: string;
  phone: string;
  province: string;
  city: string;
  district: string;
  subDistrict: string;
  postalCode: string;
  postalAddress: string;
  note: string;
  streetWidth: string;
  addressName: string;
  addressLabels: string;
};

export type TAddressContext = {
  formik?: FormikProps<AddressValue>;
  initialDetail: string;
  isAfterSearch: boolean;
  isLoadingPostAddress: boolean;
  isGeolocEnabled: boolean;
  hasErrors: (name: string) => string;
  setIsAfterSearch: Dispatch<SetStateAction<boolean>>;
  setCurrentDetail: (name: string) => void;
  onChangeAddressIdentity: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const initialValue = {
  initialDetail: '',
  isAfterSearch: false,
  isLoadingPostAddress: false,
  isGeolocEnabled: false,
  hasErrors: () => '',
  setIsAfterSearch: () => {},
  setCurrentDetail: () => {},
  onChangeAddressIdentity: () => {},
};
export const AddressContext = createContext<TAddressContext>(initialValue);

const url = SAWARNA_V2_SERVICES;

export const AddressProvider = ({ children }) => {
  const [initialDetail, setInitialDetail] = useState('');
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [isLoadingPostAddress, setIsLoadingPostAddress] = useState(false);
  const [isGeolocEnabled, setIsGeolocEnabled] = useState(false);

  const getQuery = useQuery();
  const { handleBack } = useBack();
  const { request } = useRequest();
  const { userProfile } = useAuthContext();
  const { addToast } = useSnackbar();
  const location = useLocation();

  const id = Number(getQuery.get('id'));
  const isEdit = Boolean(getQuery.get('edit'));

  const validationSchema = () => {
    const validations = getValidationSchema() as Record<string, Lazy<any>>;
    return Yup.object(validations);
  };

  const formik = useFormik<AddressValue>({
    initialValues: FORM_DEFAULT_VALUE as Record<keyof AddressValue, any>,
    validationSchema,
    onSubmit: async (values) => {
      const rawValues = {
        ...values,
        pointId: userProfile.point?.code,
      };
      const normalized = normalizePostAddress(rawValues);
      if (!isEdit) {
        await postAddress(normalized);
      } else {
        await updateAddress(normalized);
      }
    },
  });

  const postAddress = async (payload) => {
    try {
      setIsLoadingPostAddress(true);
      const variables = { userCreateLocationRequestInput: payload };
      const response = await request({ query: postAddressMutation, variables, url });
      if (response.response) {
        handleBack();
        setInitialDetail('');
      }
      setIsLoadingPostAddress(false);
      addToast('Alamat berhasil ditambahkan', {
        isSuccess: true,
        position: 'topCenter',
        buttonText: '',
        addMargin: true,
      });
    } catch (error) {
      setIsLoadingPostAddress(false);
      throw Error(error as string);
    }
  };

  const updateAddress = async (payload) => {
    try {
      setIsLoadingPostAddress(true);
      const variables = {
        id,
        v1LocationInput: payload,
      };
      const response = await request({ query: updateAddressMutation, url, variables });
      if (response.response) {
        handleBack();
        setInitialDetail('');
      }
      setIsLoadingPostAddress(false);
      addToast('Alamat berhasil diperbarui', {
        position: 'topCenter',
        addMargin: true,
        buttonText: '',
      });
    } catch (error) {
      setIsLoadingPostAddress(false);
      throw Error(error as string);
    }
  };

  const hasErrors = (name) => {
    if (name === FORM_FIELD_NAME.addressLabels) {
      const addressLabels = formik.values[FORM_FIELD_NAME.addressLabels];
      const addressLabelsOther = formik.values[FORM_FIELD_NAME.addressLabelsOther];
      return addressLabels === 'Lainnya' && addressLabelsOther === ''
        ? 'Harus diisi.'
        : formik.touched[name] && formik.errors[name];
    }
    return formik.touched[name] && formik.errors[name];
  };

  const setCurrentDetail = (type) => {
    setInitialDetail(type);
  };

  const onChangeAddressIdentity = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const currentIdentity = formik.values[FORM_FIELD_NAME.addressLabels] || [];
    let newValue: any[] | string = [...currentIdentity];
    const isExist = newValue.includes(value);
    if (!isExist) {
      newValue.push(value);
    } else {
      const idx = currentIdentity.indexOf(value);
      newValue.splice(idx, 1);
    }
    if (newValue.length === 0) newValue = '';
    formik.setFieldValue(FORM_FIELD_NAME.addressLabels, newValue);
  };

  const resetForm = () => {
    const paths = [
      PATH.ADDRESS,
      PATH.ADDRESS_REGISTER,
      PATH.ADDRESS_REGISTER_DETAIL,
      PATH.ADDRESS_REGISTER_POSTAL_CODE,
    ];
    if (!paths.includes(location.pathname)) {
      formik.resetForm();
      setIsAfterSearch(false);
    }
  };

  const checkGeolocation = async () => {
    const geolocAccess = await isGeolocationEnabled();
    setIsGeolocEnabled(geolocAccess);
  };

  useEffect(() => {
    resetForm();
  }, [location.pathname]);

  useEffect(() => {
    checkGeolocation();
  }, []);

  return (
    <AddressContext.Provider
      value={{
        formik,
        initialDetail,
        isAfterSearch,
        isLoadingPostAddress,
        isGeolocEnabled,
        hasErrors,
        setCurrentDetail,
        setIsAfterSearch,
        onChangeAddressIdentity,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const useAddressContext = () => {
  const {
    formik,
    initialDetail,
    isAfterSearch,
    isLoadingPostAddress,
    isGeolocEnabled,
    hasErrors,
    setIsAfterSearch,
    setCurrentDetail,
    onChangeAddressIdentity,
  } = useContext(AddressContext);

  return {
    formik,
    initialDetail,
    isAfterSearch,
    isLoadingPostAddress,
    isGeolocEnabled,
    hasErrors,
    setIsAfterSearch,
    setCurrentDetail,
    onChangeAddressIdentity,
  };
};

AddressProvider.propTypes = {
  children: PropType.any,
};

AddressProvider.defaultProps = {
  children: PropType.any,
};

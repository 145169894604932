import React from 'react';
import loadable from '@loadable/component';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const VerifPages = loadable(
  () => import(/* webpackChunkName: "VerificationPages" */ './Verification'),
  {
    fallback: <LoadingScreen />,
  },
);

const SuccessRegisterPagesV2 = loadable(
  () => import(/* webpackChunkName: "SuccessRegisterPages" */ './RegisterSuccessV2'),
  {
    fallback: <LoadingScreen />,
  },
);

export const SuccessRegisterPageSwitch = () => {
  return <SuccessRegisterPagesV2 />;
};

export const verificationRoutes = [
  {
    name: 'Verification',
    isGuest: true,
    routeProps: {
      exact: true,
      path: PATH.VERIFICATION,
      component: VerifPages,
    },
  },
  {
    name: 'Success Register',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.REGISTER_SUCCESS,
      component: SuccessRegisterPageSwitch,
    },
  },
];

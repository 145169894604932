import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { asyncLocalStorage as localStorage } from '.';

const queryClient = new QueryClient();

const Provider = ({ children }: { children?: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    {children}
  </QueryClientProvider>
);

const useLocalStorage = <Value,>(key: string) => {
  const client = useQueryClient();

  const queryKey = ['useLocalStorage', key];

  const { data: value, refetch } = useQuery(queryKey, () => localStorage.getItem<Value>(key), {
    keepPreviousData: true,
    suspense: false,
    retry: false,
    refetchOnMount: 'always',
  });

  const { mutateAsync: setValue } = useMutation(
    (newValue: Value) => localStorage.setItem(key, newValue),
    {
      onMutate: (mutatedData) => {
        const current = value;
        client.setQueryData(queryKey, mutatedData);
        return current;
      },
      onSuccess: () => {
        refetch();
      },
      onError: (_, __, rollBack) => {
        client.setQueryData([key], rollBack);
      },
    },
  );

  const remove = () => {
    localStorage.removeItem(key).then(() =>
      client.resetQueries({
        queryKey,
        exact: true,
      }),
    );
  };

  return { value, setValue, remove };
};

export { queryClient, Provider as QueryClientProvider, useLocalStorage as useLocalStorageQuery };

export const monthMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'Mei',
  6: 'Jun',
  7: 'Jul',
  8: 'Ags',
  9: 'Sep',
  10: 'Okt',
  11: 'Nov',
  12: 'Des',
};

export const monthMapFull = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
};

export const dateConfig = {
  date: {
    format: 'D',
    caption: 'Day',
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
};

import { getHourFromNow, getMonthFromNow } from 'utils/helpers/date';

export const isRegisterKabayan = 'isRegisterKabayan';
export const isVisited = 'isVisited';
export const tokenAuth = 'tokenAuth';
export const tokenAuthKolamku = 'token';
export const tokenLanding = 'tokenLanding';
export const isTokenExpiresChanged = 'isTokenExpiresChanged';
export const isReferralVisited = 'isReferralVisited';
export const isLoyaltyActivated = 'isLoyaltyActivated';
export const isHomeCoachmarkVisited = 'isHomeCoachmarkVisited';
export const isLapakIkanVisited = 'isLapakIkanVisited';
export const isLapakIkanPayBill = 'isLapakIkanPayBill';
export const isBillHistoryVisited = `isBillHistoryVisited`;
export const isBeliPakanDashboardCoachmarkVisited = 'isBeliPakanDashboardCoachmarkVisited';
export const isCartCoachmarkVisited = 'isCartCoachmarkVisited';
export const customDeviceId = 'customDeviceId';
export const customDeviceIdNew = 'customDeviceIdNew';
export const customDeviceIdSD = 'customDeviceIdSD';
export const appVersion = 'appVersion';
export const pushNotifTokenNative = 'pushNotifTokenNative';
export const userProfileData = 'userProfileData';
export const userKabayanData = 'userKabayanData';
export const fcmToken = 'fcmToken';

export const tokenAuthExpires = getHourFromNow(24 * 14); // 2 weeks
export const isTokenExpiresChangedExpires = getMonthFromNow(12); // 1 year

export const _hasBlockNotif = '_hasBlockNotif';
export const _hasSubscribeCT = '_hasSubscribeCT';

// Complaint handling
export const cfaComplaintToken = 'cfa_complaint_token';

import tw from 'twin.macro';

import styled from 'styled-components';

import { containerFullHeight } from 'utils/helpers/styles';

// types
interface IContainer {
  custHeight: number | string;
}

// styles
export const Container = styled.div<IContainer>(({ custHeight }) => [
  containerFullHeight(),
  tw`flex flex-col justify-center items-center px-3`,
  custHeight && `height: ${custHeight};`,
]);
export const Illustration = tw.img`width[207.3px] mb-3`;
export const Title = tw.div`text-black-700 font-semibold text-lg text-align[center] mb-4`;
export const Desc = tw.div`text-black-200 font-normal text-sm text-align[center] mb-6`;

import { DocumentNode } from 'graphql';

const guestmodeQueries = [
  // sawarna v1
  // 'commerce_responseGetCategoriesReponse',
  'commerce_responseGetBrandsReponse',
  // 'commerce_responseAutoCompleteResponse',
  // 'commerce_responseGetPricelistsResponse',
  // 'commerce_getV1PricelistsRecommendation',

  // sawarna v2
  'commerce_v1_product_categories',
  'commerce_v1_product_brands',
  'commerce_v1_pricelists_auto_complete',
  'commerce_v1_pricelists',
  'commerce_v1_pricelists_recommendation',
  'commerce_v2_pricelists_recommendation',
];

export const getToken = (operation: string | DocumentNode) => {
  // handle `graphql-request`
  if (typeof operation === 'object') {
    operation = (operation as DocumentNode)?.loc?.source?.body || '';
  }

  let token = window.token || '';
  if (!token && guestmodeQueries.some((v) => (operation as string).includes(v))) {
    token = 'guestmode';
  }
  return token;
};

// Global styles get added in the wrong order when using styled-components.
// Exporting the styles from this file is the current workaround to this issue.
// https://github.com/ben-rogerson/twin.macro/issues/277#issuecomment-754240911
import { createGlobalStyle } from 'styled-components';

import { BLACK_500, GREY_BG, WHITE_COLOR } from 'utils/styles/colors';
import { poppinsFont } from 'utils/styles/fonts';
import { BASE_WIDTH } from 'utils/styles/size';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  * {
    font-family: ${poppinsFont};
  }

  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid;
  }


  body, html {
    /* position: absolute; */
    /* right: 0; */
    /* left: 0; */
    /* bottom: 0; */
    /* top: 0; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    background: ${GREY_BG};
    font-size: 16px;
    color:  ${BLACK_500};
    letter-spacing: 0.5px;
    scroll-behavior: smooth;
    min-height: 100%;
    overscroll-behavior: none;
  }

  html {
    background: ${WHITE_COLOR};
  }

  body {
    margin: auto;
    max-height: 100%;
    ${BASE_WIDTH}
  }

  #modalWrapper {
    height: 100%;
  }

  @media (max-width: 480px) {
    body {
      width: 100%;
      min-width: 360px;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }

  input {
    color:  ${BLACK_500};
  }

  a {
    text-decoration: none;
    color: #038767;
  }

  label {
    display: inline-block;
  }

  #wrapper {
    background: ${GREY_BG};
    min-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  button {
    padding: 0px;
    letter-spacing: 0.5px;
  }


  .confirmCustom .confirmCustom-buttons {
  display: flex;
  flex-direction: column;
}

.confirmCustom .confirmCustom-content {
  padding: 24px !important;
}

.confirmCustom-head > h5 {
  display: none;
}
.confirmCustom-head > div {
  text-align: left !important;
  padding: 0px !important;
  line-height: inherit !important;
  margin: 0px 0px 24px 0px !important;
}

.confirmCustom .confirmCustom-buttons > a {
  width: 100% !important;
  padding: 11px !important;
  line-height: 1.5rem !important;
  border-color: rgba(3, 135, 103, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  margin: 0px !important;
  margin-bottom: 14px !important;
}

.notifModal > div.notifModal-content {
  padding: 18px !important;
}

.notifModal > .notifModal-content > .notifModal-title {
  border-bottom: none;
  margin: 0 0 20px;
  padding: 0px;
  margin-top: -15px;
}

.notifModal > .notifModal-content > .notifModal-message {
  padding: 0px;
}

.notifModal > .notifModal-content > .notifModal-title {
  text-align: left;
}

.notifModal > .notifModal-content > .notifModal-button {
  position: absolute;
  top: 8px;
  right: 0px;
  background: transparent !important;
  color: black !important;
}

.Modal-Portal .datepicker-modal {
  color: #038767;
  position: fixed;
  z-index: 10000
}

.Modal-Portal .datepicker-modal > .datepicker {
  max-width: 480px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.datepicker-modal > .datepicker.default .datepicker-header {
  color: #038767;
  padding: 10px 20px;
  text-align: left;
  font-weight: 500;
  border-bottom: 2px solid #038767;
}

.datepicker-modal > .datepicker.default .datepicker-navbar-btn {
  color: #038767;
}

.datepicker-modal > .datepicker.default .datepicker-wheel {
  border-top: 2px solid #038767;
  border-bottom: 2px solid #038767;
}

.datepicker-modal > .datepicker .datepicker-scroll>li {
  font-size: 0.875rem;
  color: #1D1D1D;
}

.no-padding {
  padding: 0px !important;
}

.no-margin-x {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.transparent {
  background-color: transparent !important;
}

.rdrDateRangeWrapper {
  width: 100% !important;
  font-size: 13px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.diskon {
  color: #D44140;
}

.stackgrid {
  width: 100%;
}

.stackgrid > span {
  opacity: 1 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
`;

export default GlobalStyles;

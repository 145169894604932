import 'twin.macro';

import React, { FunctionComponent } from 'react';
import { ArrowClockwise, SignOut } from '@onefish/icons-react';

import Button from 'components/Button';
import Header from 'components/Header';
import { useLogout } from 'features/auth/hooks/useLogout';
import useBack from 'utils/hooks/useBack';

import efiErrSys from './assets/err_sys.svg';

import { Container, Desc, Illustration, Title } from './styles';

const RetryPage: FunctionComponent<IRetryPage> = ({
  onRefresh,
  logout,
  headerTitle,
  custHeight,
}) => {
  const { handleBack } = useBack();

  const [handleLogout] = useLogout();

  return (
    <Container custHeight={custHeight}>
      <Header onClickBack={handleBack}>{headerTitle}</Header>
      <Illustration src={efiErrSys} />
      <Title>
        Mohon Maaf.
        <br />
        Telah Terjadi Kesalahan.
      </Title>
      <Desc>Silakan kembali ke halaman sebelumnya atau muat ulang.</Desc>
      <Button onClick={onRefresh} tw="width[200px]">
        <ArrowClockwise size={24} />
        Muat Ulang
      </Button>
      {logout && (
        <Button isSecondary onClick={handleLogout} tw="width[200px] mt-3">
          <SignOut size={24} />
          Keluar Akun
        </Button>
      )}
    </Container>
  );
};

interface IRetryPage {
  logout: boolean;
  headerTitle: string;
  custHeight: number | string;
  onRefresh: () => void;
}

export default RetryPage;

import { FEATURE_MANDATORY_PATOKAN } from 'env';
import * as Yup from 'yup';

import { arrayMoveElement } from 'utils/helpers/array';
import { maskIDCard, maskNumber, maskPhoneNumber, onlyAlphabet } from 'utils/helpers/mask';

export const FORM_FIELD_NAME = {
  name: 'name',
  phone: 'phone',
  province: 'province',
  city: 'city',
  district: 'district',
  subDistrict: 'subDistrict',
  postalCode: 'postalCode',
  postalAddress: 'postalAddress',
  note: 'note',
  streetWidth: 'streetWidth',
  addressName: 'addressName',
  addressLabels: 'addressLabels',
};

const VALIDATION_MESSAGE = {
  nameRequired: 'Nama Kontak yang Dapat Dihubungi harus diisi',
  nameMin: 'Nama Kontak yang Dapat Dihubungi terlalu pendek, min. 3 karakter',
  phoneRequired: 'Nomor HP harus diisi',
  phoneMin: 'Nomor HP terlalu pendek, min. 10 angka',
  provinceRequired: 'Provinsi harus diisi',
  cityRequired: 'Kota harus diisi',
  districtRequired: 'Kecamatan harus diisi',
  subDistrictRequired: 'Kelurahan harus diisi',
  postalCodeRequired: 'Kode pos harus diisi',
  postalAddressRequired: 'Alamat Lengkap harus diisi',
  postalAddressMin: 'Alamatmu terlalu pendek, min. 3 karakter',
  addressNameRequired: 'Nama Alamat harus diisi',
  addressNameMin: 'Nama Alamat terlalu pendek, min. 3 karakter',
  addressLabelsRequired: 'Pilih salah satu',
  rtRequired: 'RT harus diisi',
  rwRequired: 'RW harus diisi',
  noteRequired: 'Info patokan wajib diisi.',
  noteMin: 'Info patokan wajib min 25 karakter.',
};

export const FORM_SCHEME = {
  detail: {
    title: 'Detail Alamat',
    desc: 'Lengkapi alamat kamu dengan provinsi, kota/kabupaten, kecamatan, dan kode pos.',
    inputs: [
      {
        name: FORM_FIELD_NAME.province,
        type: 'search-multi',
        label: 'Provinsi',
        placeholder: 'Masukkan nama provinsi',
        required: true,
        isMasking: true,
        mask: onlyAlphabet,
        validation: Yup.object().required(VALIDATION_MESSAGE.provinceRequired).nullable(),
      },
      {
        name: FORM_FIELD_NAME.city,
        type: 'search-multi',
        label: 'Kota/Kabupaten',
        placeholder: 'Masukkan nama kota/kabupaten',
        required: true,
        isMasking: true,
        mask: onlyAlphabet,
        validation: Yup.object().required(VALIDATION_MESSAGE.cityRequired).nullable(),
      },
      {
        name: FORM_FIELD_NAME.district,
        type: 'search-multi',
        label: 'Kecamatan',
        placeholder: 'Masukkan nama kecamatan',
        required: true,
        isMasking: true,
        mask: onlyAlphabet,
        validation: Yup.object().required(VALIDATION_MESSAGE.districtRequired).nullable(),
      },
      {
        name: FORM_FIELD_NAME.subDistrict,
        type: 'search-multi',
        label: 'Kelurahan',
        placeholder: 'Masukkan nama kelurahan',
        required: true,
        isMasking: true,
        mask: onlyAlphabet,
        validation: Yup.object().required(VALIDATION_MESSAGE.subDistrictRequired).nullable(),
      },
      {
        name: FORM_FIELD_NAME.postalCode,
        type: 'list',
        label: 'Kode Pos (Opsional)',
        placeholder: 'Masukkan kode pos',
        isMasking: true,
        mask: maskIDCard,
        maxLength: 5,
        guide: false,
      },
      {
        name: FORM_FIELD_NAME.postalAddress,
        type: 'textarea',
        label: 'Nama Jalan/Desa, No. Rumah',
        placeholder: 'Contoh: DUSUN CILUNCAT NO. 07, RT03/RW01 DESA MADIASARI ',
        required: true,
        validation: Yup.string()
          .min(3, VALIDATION_MESSAGE.postalAddressMin)
          .required(VALIDATION_MESSAGE.postalAddressRequired)
          .nullable(),
      },
      ...(FEATURE_MANDATORY_PATOKAN
        ? [
            {
              name: FORM_FIELD_NAME.note,
              required: true,
              type: 'textarea',
              label: 'Patokan/Detail Lainnya ',
              placeholder: 'Contoh: 200 m dari gapura, di depan masjid',
              subLabel: 'Biar alamatmu lebih mudah ditemukan',
              validation: Yup.string()
                .min(25, VALIDATION_MESSAGE.noteMin)
                .required(VALIDATION_MESSAGE.noteRequired),
              maxLength: 255,
            },
          ]
        : []),
      {
        name: FORM_FIELD_NAME.streetWidth,
        type: 'radio',
        label: 'Akses Lebar Jalan (Opsional)',
        smallMessage: 'Besarnya akses jalan di lokasi',
        options: [
          {
            value: 'Lebar (2 Mobil)',
            label: 'Lebar (2 Mobil)',
          },
          {
            value: 'Sedang (1 Mobil) ',
            label: 'Sedang (1 Mobil) ',
          },
          {
            value: 'Sempit (Hanya Motor)',
            label: 'Sempit (Hanya Motor)',
          },
        ],
      },
    ],
  },
  contact: {
    title: 'Tentang Alamat Ini',
    desc: 'Informasi nama dan jenis, agar alamat ini dapat lebih mudah ditemukan.',
    inputs: [
      {
        name: FORM_FIELD_NAME.addressName,
        type: 'text',
        label: 'Nama Alamat',
        placeholder: 'Contoh: Alamat Rumah',
        required: true,
      },
      {
        name: FORM_FIELD_NAME.addressLabels,
        type: 'radio',
        label: 'Tandai Alamat Ini Sebagai:',
        required: true,
        validation: Yup.string().required(VALIDATION_MESSAGE.addressLabelsRequired).nullable(),
        options: [
          {
            value: 'Rumah',
            label: 'Rumah',
          },
          {
            value: 'Kolam',
            label: 'Kolam',
          },
          {
            value: 'Waduk',
            label: 'Waduk',
          },
          {
            value: 'Lainnya',
            label: 'Lainnya',
          },
        ],
      },
      {
        name: FORM_FIELD_NAME.name,
        type: 'text',
        label: 'Nama Penerima',
        placeholder: 'Masukkan nama lengkap',
        required: true,
        isMasking: true,
        mask: onlyAlphabet,
        guide: false,
        validation: Yup.string()
          .min(3, VALIDATION_MESSAGE.nameMin)
          .required(VALIDATION_MESSAGE.nameRequired)
          .nullable(),
      },
      {
        name: FORM_FIELD_NAME.phone,
        type: 'text',
        label: 'Nomor HP yang Dapat Dihubungi',
        placeholder: 'Masukkan nomor telepon',
        required: true,
        inputMode: 'numeric',
        isMasking: true,
        mask: maskPhoneNumber,
        guide: false,
        validation: Yup.string()
          .min(10, VALIDATION_MESSAGE.phoneMin)
          .required(VALIDATION_MESSAGE.phoneRequired)
          .nullable(),
      },
    ],
  },
};

const labelInput = FORM_SCHEME.contact.inputs[1];
const detailInputs = FORM_SCHEME.detail.inputs;
const contactInputs = FORM_SCHEME.contact.inputs;
FORM_SCHEME.detail.inputs.splice(detailInputs.length - 1, 0, labelInput); // put label input to detail section

for (const [i, input] of contactInputs.entries()) {
  if (input.name === FORM_FIELD_NAME.addressLabels) {
    FORM_SCHEME.contact.inputs.splice(i, 1); // remove label input from contact section
  }
}

FORM_FIELD_NAME.lat = 'lat';
FORM_FIELD_NAME.long = 'long';
FORM_FIELD_NAME.rt = 'rt';
FORM_FIELD_NAME.rw = 'rw';
FORM_FIELD_NAME.isSameAsOwner = 'isSameAsOwner';
FORM_FIELD_NAME.addressLabelsOther = 'addressLabelsOther';

const rtAndRwInput = {
  type: 'double',
  inputs: [
    {
      name: FORM_FIELD_NAME.rt,
      type: 'text',
      label: 'RT',
      placeholder: '0',
      isMasking: true,
      mask: maskNumber,
      guide: false,
      maxLength: 2,
      col: 'right',
    },
    {
      name: FORM_FIELD_NAME.rw,
      type: 'text',
      label: 'RW',
      placeholder: '0',
      isMasking: true,
      mask: maskNumber,
      guide: false,
      maxLength: 2,
      col: 'left',
    },
  ],
};

for (const [i, input] of detailInputs.entries()) {
  if (input.name === FORM_FIELD_NAME.postalAddress) {
    arrayMoveElement(detailInputs, i, 0); // move postal address input from contact section
  }
}
FORM_SCHEME.detail.inputs.splice(1, 0, rtAndRwInput);

export const FORM_DEFAULT_VALUE = Object.keys(FORM_FIELD_NAME).reduce(
  (finalObj, cur) => ({ ...finalObj, [cur]: '' }),
  {},
);

export const LIST_TITLE = {
  [FORM_FIELD_NAME.province]: 'Provinsi',
  [FORM_FIELD_NAME.city]: 'Kota/Kabupaten',
  [FORM_FIELD_NAME.district]: 'Kecamatan',
  [FORM_FIELD_NAME.subDistrict]: 'Kelurahan',
};

export const getValidationSchema = () => {
  const sectionKeys = Object.keys(FORM_SCHEME);
  const validations = {};
  sectionKeys.forEach((sectionKey) => {
    FORM_SCHEME[sectionKey].inputs.forEach((input) => {
      if (input.inputs) {
        input.inputs.forEach((input2) => {
          validations[input2.name] = input2.validation;
        });
      } else {
        validations[input.name] = input.validation;
      }
    });
  });
  return validations;
};

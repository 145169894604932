import 'twin.macro';

import React from 'react';
import { Button } from 'components';

import BottomSheet from 'components/BottomSheet';

import { Container, Description, Image } from './styles';

const BlacklistModal: React.FC<TBlacklistModal> = ({
  isOpen,
  title,
  closeModal,
  btnTitle,
  onClick,
  illustration,
  description,
  testId,
}) => {
  return (
    <BottomSheet
      isOpen={isOpen}
      title={title}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      testId={`blacklist_${testId}`}
    >
      <Container>
        <Image src={illustration} data-testid={`img_blacklist_${testId}`} />
        <Description data-testid={`txt_blacklist_desc_${testId}`}>{description}</Description>
      </Container>
      <Button onClick={onClick} tw="mb-4" data-testid={`btn_blacklist_${testId}`}>
        {btnTitle}
      </Button>
    </BottomSheet>
  );
};

type TBlacklistModal = {
  isOpen: boolean;
  title: string;
  description: string;
  illustration: string;
  btnTitle: string;
  onClick: () => void;
  closeModal: () => void;
  testId?: string;
};

export default BlacklistModal;

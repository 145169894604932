import React from 'react';
import loadable from '@loadable/component';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const KabayanResubmissionPage = loadable(
  () => import(/* webpackChunkName: "KabayanResubmissionPage" */ './Resubmission'),
  {
    fallback: <LoadingScreen />,
  },
);

export const kabayanResubmissionRoute = {
  name: 'Kabayan Resubmission',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.KABAYAN_RESUBMISSION,
    component: KabayanResubmissionPage,
  },
};

import tw from 'twin.macro';

import { ReactNode } from 'react';
import styled from 'styled-components';

import { BOTTOM_LEFT, BOTTOM_RIGHT, TOP_CENTER, TOP_LEFT, TOP_RIGHT } from '../../constants';

const isStaging = process.env.REACT_APP_MODE === 'staging';
const marginTop = isStaging ? 70 : 45;
const marginBotom = 80;

const getStylePosition = (pos: string) => {
  switch (pos) {
    case BOTTOM_LEFT:
      return tw`bottom-0 justify-start`;
    case BOTTOM_RIGHT:
      return tw`bottom-0 justify-end`;
    case TOP_LEFT:
      return tw`top-0 justify-start pl-2 pt-2`;
    case TOP_RIGHT:
      return tw`top-0 justify-end pr-2 pt-2`;
    case TOP_CENTER:
      return tw`top-0 justify-center pt-2`;
    default:
      return tw`bottom-0 justify-center`;
  }
};

export interface ContProps {
  position: string;
  addMargin?: boolean;
  children?: ReactNode;
}
export const Container = styled.div(({ position, addMargin }: ContProps) => [
  tw`fixed left-0 pointer-events-none w-full flex justify-center z-99999`,
  getStylePosition(position),
  addMargin && `margin: ${marginTop}px 0 ${marginBotom}px;`,
]);

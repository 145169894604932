import { useEffect } from 'react';
import loadable from '@loadable/component';
import { AnimatePresence } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';
import { Router, Switch } from 'react-router-dom';

import { OTPMethodModalContainer } from 'components/OTPMethodModal';
import RetryPage from 'components/RetryPage';
import Route from 'components/Route';
import { SnackbarProvider } from 'components/Snackbar';
import { AddressProvider } from 'features/address-management/contexts/AddressContext';
import { BlacklistProvider } from 'features/blacklist/contexts/BlacklistContext';
import { AuthProvider } from 'utils/context/AuthContext';
import { CoachmarkProvider } from 'utils/context/CoachmarkContext';
import { FlagrProviderV2 } from 'utils/context/FlagrContextV2';
import { VersionProvider } from 'utils/context/VersionContext';
import useFlagrContext from 'utils/hooks/useFlagrContext';

import PrivateRoute from './components/PrivateRoute';
import { CLIENT_ID } from './constants';
import history from './history';
import routes from './routes';

const Maintenance = loadable(() => import('features/maintenance/pages/Maintenance'));
const NotFoundPage = loadable(() => import('features/not-found/components'));

const SwitchRoute = () => {
  const { isMaintenance } = useFlagrContext();
  const params = new URLSearchParams(window.location.search);
  const clientId = params.get(CLIENT_ID);
  const clientIdStorage = localStorage.getItem(CLIENT_ID);

  useEffect(() => {
    if (window.postMessageTwa && clientId && clientIdStorage !== clientId) {
      localStorage.setItem(CLIENT_ID, clientId);
    }
  }, []);

  return (
    <Switch>
      {isMaintenance && <Route path="*" component={Maintenance} />}
      {!isMaintenance &&
        routes.map((obj) => {
          const { routeProps } = obj;
          if (obj.isPublic) {
            return <Route key={routeProps.path} {...routeProps} />;
          }
          return <PrivateRoute isGuest={obj.isGuest} key={routeProps.path} {...routeProps} />;
        })}
      <Route path="/maintenance" component={Maintenance} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

const Routing = () => {
  return (
    <Router history={history}>
      <ErrorBoundary
        fallbackRender={() => {
          let onRefresh = () => location.reload();
          const url = location.href;
          const regexVerificationPage = /^(.*\/verification\?phone_number=)[^&]+(&method=)[^&]+/;
          // handle onRefresh on verification OTP
          if (regexVerificationPage.test(url)) {
            if (!url.includes('&token=')) {
              // login
              onRefresh = () => location.replace('/auth/login');
            } else {
              // register
              const searchParams = new URLSearchParams(new URL(url).search);
              const phoneNumber = searchParams.get('phone_number');
              const token = searchParams.get('token');
              onRefresh = () =>
                location.replace(
                  `/register?phone_number=${phoneNumber}&data_query=${token}&confirmation=true`,
                );
            }
          }
          return <RetryPage headerTitle="Terjadi Kesalahan" onRefresh={onRefresh} />;
        }}
      >
        <AuthProvider>
          <FlagrProviderV2>
            <SnackbarProvider>
              <CoachmarkProvider>
                <OTPMethodModalContainer>
                  <AddressProvider>
                    <VersionProvider>
                      <AnimatePresence exitBeforeEnter>
                        <BlacklistProvider>
                          <SwitchRoute />
                        </BlacklistProvider>
                      </AnimatePresence>
                    </VersionProvider>
                  </AddressProvider>
                </OTPMethodModalContainer>
              </CoachmarkProvider>
            </SnackbarProvider>
          </FlagrProviderV2>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};
export default Routing;

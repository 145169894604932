import { Cookies } from 'react-cookie';

const cookies = new Cookies(document.cookie);

function getClientId() {
  const value = cookies.get('_ga');

  if (!value) return '';

  // The client ID, extracted from the value "GA1.1.1517550509.1704708098," is "1517550509."
  const clientId = String(value).replace('GA1.1.', '');

  return clientId;
}

function getSessionId() {
  const measurementKey = Object.keys(cookies.getAll()).find((key) => key.startsWith('_ga_'));
  const value = cookies.get(measurementKey || '');

  if (!value) return '';

  // The session ID, extracted from the value "GS1.1.1704717156.3.1.1704717798.0.0.0," is "1704717156."
  const sessionId = String(value).replace('GS1.1.', '').split('.')[0];

  return sessionId;
}

export const ga = {
  clientId: getClientId(),
  sessionId: getSessionId(),
  getClientId,
  getSessionId,
};

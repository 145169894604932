// Number assertions
/**
 * Check if given value is number
 *
 * @param {*} value - given value
 * @return {boolean}
 */
export function isNumber(value) {
  return typeof value === 'number';
}

/**
 * Check if given value is not number
 *
 * @param {*} value - given value
 * @return {boolean}
 */
export function isNotNumber(value) {
  return typeof value !== 'number' || Number.isNaN(value) || !Number.isFinite(value);
}

/**
 * Check if value is numeric
 *
 * @param {*} value - given value
 * @return {boolean}
 */
export function isNumeric(value) {
  return value != null && value - parseFloat(value) + 1 >= 0;
}

// Array assertions
/**
 * Check if given value is array
 *
 * @param {*} value
 * @return {arg is any[]}
 */
export function isArray(value) {
  return Array.isArray(value);
}

/**
 * Check if given value is an empty array
 *
 * @param {*} value
 * @return {boolean}
 */
export function isEmptyArray(value) {
  return isArray(value) && value.length === 0;
}

// Function assertions
/**
 * Check if given value is a function
 *
 * @param {*} value
 * @return {boolean}
 */
export function isFunction(value) {
  return typeof value === 'function';
}

// Generic assertions
/**
 * Check if given value is defined
 *
 * @param {*} value
 * @return {boolean}
 */
export function isDefined(value) {
  return typeof value !== 'undefined' && value !== undefined;
}

/**
 * Check if given value is undefined
 *
 * @param {*} value
 * @return {boolean}
 */
export function isUndefined(value) {
  return typeof value === 'undefined' || value === undefined;
}

// Object assertions
/**
 * Check if given value is an object
 *
 * @param {*} value
 * @return {boolean}
 */
export function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function') && !isArray(value);
}

/**
 * Check if given value is an empty object
 *
 * @param {8} value
 * @return {boolean}
 */
export function isEmptyObject(value) {
  return isObject(value) && Object.keys(value).length === 0;
}

/**
 * Check if given value is not an empty object
 *
 * @param {*} value
 * @return {boolean}
 */
export function isNotEmptyObject(value) {
  return value && !isEmptyObject(value);
}

/**
 * Check if given value is a null
 *
 * @param {*} value
 * @return {boolean}
 */
export function isNull(value) {
  return value == null;
}

// String assertions
/**
 * Check if given value is a string
 *
 * @param {*} value
 * @return {boolean}
 */
export function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
}

/**
 * Check if given value is a css variable. Example: `var(--blue)`.
 * @param value
 * @return {boolean}
 */
export function isCssVar(value) {
  return /^var\(--.+\)$/.test(value);
}

// Empty assertions
/**
 * Check if given value is empty
 * This function is a combination of many function to check empty.
 *
 * @param {*} value
 * @return {boolean}
 */
export function isEmpty(value) {
  if (isArray(value)) return isEmptyArray(value);
  if (isObject(value)) return isEmptyObject(value);
  return value == null || value === '';
}

/**
 * Check if current is development environment
 *
 * @type {boolean}
 */
export const isDevEnvironment = process.env.NODE_ENV !== 'production';

/**
 * Check if current is test environment
 *
 * @type {boolean}
 */
export const isTestEnvironment = process.env.NODE_ENV === 'test';

/**
 * Check if given value is a react ref object
 *
 * @param {*} val
 * @return {boolean}
 */
export function isRefObject(val) {
  return 'current' in val;
}

/**
 * Check if given value is an input event
 *
 * @param {*} value
 * @return {boolean}
 */
export function isInputEvent(value) {
  return value && isObject(value) && isObject(value.target);
}

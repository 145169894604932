import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';
import { CaraBelanjaPakan } from '../components/cara-belanja-pakan';

const BeliPakanPages = loadable(
  () => import(/* webpackChunkName: "BeliPakanPages" */ './BeliPakanPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const beliPakanRoutes: Route[] = [
  {
    name: 'BeliPakan',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.BELI_PAKAN,
      component: BeliPakanPages,
    },
  },
  {
    name: 'CaraBelanjaPakan',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.CARA_BELANJA_PAKAN,
      component: CaraBelanjaPakan,
    },
  },
];

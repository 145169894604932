/* eslint-disable no-shadow */
import 'twin.macro';

import React, { FunctionComponent, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import SnackbarContainer from '../components/Container';
import Snackbar from '../components/Snackbar';
import { generateUEID, NOOP } from '../utils';

interface ISnackbarContext {
  showToast: (content: string, options?: IToastOptions) => void;
  removeAll: () => void;
}

export const SnackbarContext = React.createContext<ISnackbarContext>({
  showToast: () => {},
  removeAll: () => {},
});

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export interface IToastOptions {
  buttonText?: string;
  buttonTextWhite?: boolean;
  onClickButton?: () => void;
  isActionBlock?: boolean;
  transitionDuration?: number;
  onDismiss?: () => void;
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
  position?: string;
  isSuccess?: boolean;
  addMargin?: boolean;
  dataTestId?: string;
}

export const SnackbarProvider: FunctionComponent<SnackbarProviderProps> = ({ children }) => {
  const [snackbars, setSnackbar] = useState<any[]>([]);

  const show = (content: string, options?: IToastOptions) => {
    let tempSnackbars = [...snackbars];
    const id = generateUEID();

    const {
      buttonText = 'OK',
      buttonTextWhite = false,
      onClickButton = null,
      isActionBlock = false,
      transitionDuration = 250,
      onDismiss,
      autoDismiss = true,
      autoDismissTimeout = 3 * 1000, // 3 seconds
      position: pos = 'bottomCenter',
      isSuccess = false,
      addMargin = false,
      dataTestId = '',
    } = options || {};

    tempSnackbars = [
      {
        content,
        buttonText,
        buttonTextWhite,
        onDismiss,
        transitionDuration,
        autoDismissTimeout,
        isActionBlock,
        autoDismiss,
        id,
        position: pos,
        isSuccess,
        onClickButton,
        addMargin,
        dataTestId,
      },
      ...tempSnackbars,
    ];
    setSnackbar(tempSnackbars);

    return id;
  };

  const removeAll = () => {
    setSnackbar([]);
  };

  const dismiss = (id: string, onDismiss = NOOP) => {
    setSnackbar((snack) => {
      let tempSnackbars = [...snack];
      tempSnackbars = tempSnackbars.filter((obj) => obj.id !== id);
      return tempSnackbars;
    });

    onDismiss();
  };

  const value = {
    showToast: show,
    removeAll,
  };

  const renderSnackbars = () => {
    return snackbars.reverse().map((obj) => {
      const {
        id: objId,
        onDismiss,
        transitionDuration,
        position,
        addMargin,
        dataTestId,
        ...propsObj
      } = obj;
      return (
        <SnackbarContainer
          key={objId}
          position={position}
          addMargin={addMargin}
          data-testid={`wrp_snackbar_${dataTestId}`}
        >
          <TransitionGroup tw="w-full">
            <Transition appear timeout={transitionDuration} mountOnEnter unmountOnExit>
              {(transitionState) => (
                <Snackbar
                  key={objId}
                  testId={dataTestId}
                  id={objId}
                  transitionState={transitionState}
                  onClose={() => dismiss(objId, onDismiss)}
                  {...propsObj}
                />
              )}
            </Transition>
          </TransitionGroup>
        </SnackbarContainer>
      );
    });
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {renderSnackbars()}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.defaultProps = {};

import { G } from '@mobily/ts-belt';

import { parseJSON } from '.';

const setItem = <Value>(key: string, value: Value) =>
  Promise.resolve().then(() => localStorage.setItem(key, JSON.stringify(value)));

const getItem = <Value>(key: string) =>
  Promise.resolve()
    .then(() => localStorage.getItem(key))
    .then((value) => (G.isString(value) ? parseJSON<Value>(value) : value))
    .catch(() => null);

const removeItem = (key: string) => Promise.resolve().then(() => localStorage.removeItem(key));

const asyncLocalStorage = { setItem, getItem, removeItem };

export { asyncLocalStorage };

import { FEATURE_KABAYAN_RESUBMISSION } from 'env';

import { addressManagementRoutes } from 'features/address-management/pages/routes';
import { bankAccountRoutes } from 'features/bank-account/pages/routes';
import { beliPakanRoutes } from 'features/beli-pakan/pages/routes';
import { billRoutes } from 'features/bill/pages/routes';
import { cartRoute } from 'features/cart/pages/routes';
import { complaintListRoutes } from 'features/complaint/pages/routes';
import { cultivationFormRoutes } from 'features/cultivation-form/pages/routes';
import { helpRoutes } from 'features/help/pages/routes';
import { homeRoutes } from 'features/home/pages/routes';
import { kabayanRoutes } from 'features/kabayan/pages/routes';
import { kblRoute } from 'features/kabayan-kbl/pages/routes';
import { kbrRoute } from 'features/kabayan-kbr/pages/routes';
import { kbxRoute } from 'features/kabayan-kbx/pages/routes';
import { kabayanResubmissionRoute } from 'features/kabayan-resubmission/pages/routes';
import { lapakIkanRoutes } from 'features/lapak-ikan/pages/routes';
import { limitDeviceRoutes } from 'features/limit-device/pages/routes';
import { loginRoutes } from 'features/login/pages/routes';
import { loyaltyRoutes } from 'features/loyalty/pages/routes';
import { notificationRoute } from 'features/notification/pages/routes';
import { orderTrackRoutes } from 'features/order-tracker-standalone/pages/routes';
import { paymentRoutes } from 'features/payment/pages/routes';
import { privacyPolicyRoutes } from 'features/privacy-policy/pages/routes';
import { productDetailRoutes } from 'features/product-detail/pages/routes';
import { profileRoutes } from 'features/profile/pages/routes';
import { promoRoutes } from 'features/promo/pages/routes';
import { referralRoute } from 'features/referral/pages/routes';
import { registerRoute } from 'features/register/pages/routes';
import { searchRoutes } from 'features/search/pages/routes';
import { transactionHistoryRoutes } from 'features/transaction-history/pages/routes';
import { verificationRoutes } from 'features/verification/pages/routes';

const routes = [
  // Static
  ...homeRoutes,
  ...helpRoutes,
  ...privacyPolicyRoutes,

  // Auth
  ...loginRoutes,
  registerRoute,
  ...verificationRoutes,

  // Complaint List
  ...complaintListRoutes,

  // Cultivation Form
  ...cultivationFormRoutes,

  // User-related
  ...profileRoutes,
  ...bankAccountRoutes,
  ...addressManagementRoutes,
  referralRoute,
  notificationRoute,

  // Kabayan
  ...kabayanRoutes,
  kbxRoute,
  kblRoute,
  kbrRoute,
  ...(FEATURE_KABAYAN_RESUBMISSION ? [kabayanResubmissionRoute] : []),

  // Product → Transaction
  ...beliPakanRoutes,
  ...productDetailRoutes,
  ...searchRoutes,
  cartRoute,
  ...transactionHistoryRoutes,
  ...billRoutes,
  ...paymentRoutes,

  // Misc
  ...lapakIkanRoutes,
  ...orderTrackRoutes,
  ...limitDeviceRoutes,

  // Growth Related
  ...promoRoutes,
  ...loyaltyRoutes,
];

export default routes;

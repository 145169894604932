import tw from 'twin.macro';

import styled, { createGlobalStyle } from 'styled-components';

export const QuestionContainer = tw.div`flex justify-between items-center mb-2`;
export const Question = tw.div`flex flex-col text-xl font-semibold`;

export const Description = tw.div`color[#848484] text-sm mb-6`;

export const Btn = styled.button`
  ${tw`w-full text-base font-medium`}
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #038767;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const BorderButton = styled(Btn)`
  ${tw`bg-white text-observatory-500 flex justify-center items-center`}

  &:disabled {
    ${tw`border-gray-300 text-gray-400 bg-gray-50 cursor-not-allowed`}
    img {
      filter: grayscale(1);
    }
  }
`;

export const Icon = tw.img`mr-2`;

export const GlobalStyles = createGlobalStyle`
  .ReactModal__Content {
    transition: all 250ms ease-in-out;
    transform: translateY(250px);
  }

  .ReactModal__Content--after-open{
    transform: translateY(0px);
  }

  .ReactModal__Content--before-close{
    transform: translateY(250px);
  }

  .ReactModal__Overlay {
    transition: all 250ms ease-in-out;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`;

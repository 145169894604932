import { F } from '@mobily/ts-belt';

import eFeederIcon from '../assets/feature-efeeder.png';
import lapakIkanIcon from '../assets/feature-lapak-ikan.png';
import promokuIcon from '../assets/feature-promoku.png';
import tokBudIcon from '../assets/feature-tokbud.png';

export const FEAT_LIST = {
  LELANG_IKAN: 'lelangIkan',
  PROMOSI: 'promosi',
  PENCATATAN: 'pencatatan',
  BELI_PAKAN: 'beliPakan',
  E_FEEDER: 'eFeeder',
};

export enum FEAT_ENUM {
  TOKO_BUDIDAYA = 'Toko Budidaya',
  BELI_PAKAN = 'Beli Pakan',
  E_FEEDER = 'eFeeder',
  LELANG_IKAN = 'Lapak Ikan',
  PROMOSI = 'PromoKu',
}

export const Coach = {
  one: 'coach-home-1',
  two: 'coach-home-2',
  three: 'coach-home-3',
  four: 'coach-home-4',
  five: 'coach-home-5',
};

export const toClassHTML = (class_) => `.${class_}`;

export const classNameWhenGuestMode = (coach) => coach;

export const FEATURE_LIST = [
  {
    icon: tokBudIcon,
    type: FEAT_LIST.BELI_PAKAN,
    href: '/beli-pakan',
    label: FEAT_ENUM.TOKO_BUDIDAYA,
    coachClass: Coach.two,
    testId: 'btn_home_beli_pakan',
  },
  {
    icon: eFeederIcon,
    type: FEAT_LIST.E_FEEDER,
    href: `${process.env.REACT_APP_FEEDEROPS_URL}/efeeder`,
    label: FEAT_ENUM.E_FEEDER,
    badge: 'Baru',
    testId: 'btn_home_feeder',
  },
  {
    icon: lapakIkanIcon,
    type: FEAT_LIST.LELANG_IKAN,
    href: '/lapak-ikan',
    label: FEAT_ENUM.LELANG_IKAN,
    coachClass: Coach.three,
    testId: 'btn_home_lapak_ikan',
  },
  {
    icon: promokuIcon,
    href: '/promo',
    type: FEAT_LIST.PROMOSI,
    label: FEAT_ENUM.PROMOSI,
    coachClass: Coach.four,
    testId: 'btn_home_promoku',
  },
].map((feat) =>
  F.when(
    feat,
    () => true,
    ({ coachClass: _, ...f }) => f,
  ),
);

export const steps = [
  {
    target: toClassHTML(Coach.one),
    title: 'Kamu Sudah Punya Akun?',
    content:
      'Kalau belum, kamu bisa daftar dengan mudah, atau masuk ke akun yang terdaftar untuk pakai semua fitur di eFisheryKu.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    target: toClassHTML(Coach.two),
    title: 'Fitur Unggulan eFisheryKu',
    content:
      'Untuk mempermudah kamu dalam urusan pembelian pakan, sampai penjualan hasil panen. Bisa dipakai sesuai kebutuhan!',
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: toClassHTML(Coach.three),
    title: 'Makin Pintar dengan Belajar!',
    content: 'Beragam video bimbingan untuk memperkaya pengetahuanmu bisa diakses di sini.',
    disableBeacon: true,
    placement: 'top',
  },
];

export const VIDEO_TYPES = {
  STORY: 'story',
  TUTORIAL: 'tutorial',
};
export const supportResetNotifPermissionURL = 'https://support.google.com/chrome/answer/114662';

export const NPWP_TYPE = [
  {
    id: 1,
    label: 'NPWP Pribadi',
    value: 'individu',
  },
  {
    id: 2,
    label: 'NPWP Badan Usaha',
    value: 'badan',
  },
];

export enum ModalType {
  UPDATE_NPWP = 'updateNpwp',
  POPUP_HOMEPAGE = 'popupHomepage',
  ORDER_REMINDER = 'orderReminder',
}

import React from 'react';
import loadable from '@loadable/component';

import LoadingScreen from 'components/LoadingScreen';

const BillCreate = loadable(() => import(/* webpackChunkName: "BillCreate" */ './index'), {
  fallback: <LoadingScreen />,
});

export default BillCreate;

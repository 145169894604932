import { useEffect, useMemo, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  FEATURE_ADJUSTMENT_STATUS_KABAYAN,
  FEATURE_BLOCK_SHRIMP_USER,
  FEATURE_ENABLED_ENHANCE_PERFORMANCE,
  FEATURE_KABAYAN_RESUBMISSION,
} from 'env';
import { useCookies } from 'react-cookie';

import {
  useUserKabayanQuery,
  useUserKabayanV2Query,
  useUserKabayanV3Query,
  useUserProfileNewQuery,
  useUserProfileOldQuery,
} from 'features/auth/graphql/auth.v2.gen';
import { removeCookiesAndRedirect } from 'features/auth/hooks/useLogout';
import {
  appVersion as cookieAppVersion,
  customDeviceId as cookieCustomDeviceId,
  customDeviceIdNew as cookieCustomDeviceIdNew,
  customDeviceIdSD as cookieCustomDeviceIdSD,
  tokenAuth,
  userKabayanData,
  userProfileData,
} from 'utils/constants/cookies';
import { getMonthFromNow } from 'utils/helpers/date';
import { refreshToken } from 'utils/helpers/firebase';
import { dataLayerMerkleSetUserProperty } from 'utils/helpers/tracking';

import useQuery from './useQuery';

export function getCookieManual(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export interface IUserProfile {
  name: string;
  phone: string;
  birthDate: string;
  location: string;
  joinDate: string;
  leadId: string;
  cityName: string;
  districtName: string;
  ktpNumber: string;
  countNotifLbl: string;
  countNotif: number;
  point: {
    code: string;
    name: string;
  };
  commodities: { id: string; name: string }[];
  user: {
    email: string;
    picture: string;
  };
  isKabayan: boolean;
  kabayan?: {
    customerId: string;
    formattedSaldo: string;
    isKabayan: boolean;
    kabayanTypeCode: string;
    kabayanTypeId?: number;
    isKabayanProgress: boolean;
    noPks: string;
    plafon: number;
    plafonFeeder: number;
    saldo: number;
    isHasPks: boolean;
    isPksActive: boolean;
    histories: { status: string }[];
    nominationId: string;
    status: string;
    isHardRejected: boolean;
    isRejectedOnCallVerif: boolean;
    funderName: string;
  };
  provinceId: number;
  provinceName: string;
  cityId: number;
  districtId: number;
  subdistrictId: string;
  subdistrictName: string;
  address: string;
  postalNumber: number;
  latitude: number;
  longitude: number;
  isBlacklist: boolean;
  isNewFarmer: boolean;
  mainCommodity: string;
  isShrimpUser: boolean;
}

export interface IUseAuth {
  isLogin: boolean;
  isFetched: boolean;
  isLoading: boolean;
  userProfile: Partial<IUserProfile>;
  token: string;
  error: ApolloError | boolean;
  getProfile: () => void;
  refreshProfile: () => void;
  refreshKabayan: () => void;
}

const useUserProfileQuery = FEATURE_BLOCK_SHRIMP_USER
  ? useUserProfileNewQuery
  : useUserProfileOldQuery;

const useAuth: () => IUseAuth = () => {
  const [isFetched, setFetched] = useState(false);

  const customDeviceId = useQuery().get('custom_device_id') || '';
  const customDeviceIdNew = useQuery().get('custom_device_id_new') || '';
  const customDeviceIdSD = useQuery().get('custom_device_id_sd') || '';
  const appVersion = useQuery().get('appVersion') || '';
  const [cookies, setCookie] = useCookies([
    tokenAuth,
    cookieCustomDeviceId,
    cookieCustomDeviceIdNew,
    cookieCustomDeviceIdSD,
    cookieAppVersion,
    userProfileData,
    userKabayanData,
  ]);

  const {
    data: resProfile,
    refetch: getProfile,
    isFetching: isFetchingProfile,
    isFetched: isFetchedProfile,
  } = useUserProfileQuery(undefined, {
    enabled: false,
    onSuccess(data) {
      const { name, email, phone, pointCode, pointName, leadId, isKabayan } = data.userProfile;

      if (FEATURE_ENABLED_ENHANCE_PERFORMANCE) {
        setCookie(userProfileData, data.userProfile, {
          path: '/',
          expires: getMonthFromNow(120),
        });
      }

      dataLayerMerkleSetUserProperty({
        name,
        email,
        phone,
        point_code: pointCode,
        point_name: pointName,
        lead_id: leadId,
        is_kabayan: isKabayan,
      });

      if (!FEATURE_ENABLED_ENHANCE_PERFORMANCE) setFetched(true);
    },
    retry: (failureCount, err: any) => {
      const isClientError = String(err.response?.status).startsWith('4');
      if (isClientError) return false; // No retry
      return failureCount === 0; // Only retry once
    },
    onError(err: any) {
      const isForceLogout = err.response?.status === 401;
      if (isForceLogout) {
        removeCookiesAndRedirect('/?force-logout=1');
      }
    },
    initialData: {},
  });

  const getKabayanQuery = () => {
    const queryOptions = {
      enabled: false,
      onSuccess(data: any) {
        if (FEATURE_ENABLED_ENHANCE_PERFORMANCE) {
          setCookie(userKabayanData, data.userKabayan, {
            path: '/',
            expires: getMonthFromNow(1),
          });
        }

        if (!FEATURE_ENABLED_ENHANCE_PERFORMANCE) setFetched(true);
      },
      initialData: {},
    };

    if (FEATURE_ADJUSTMENT_STATUS_KABAYAN) {
      return useUserKabayanV3Query(undefined, queryOptions);
    } else if (FEATURE_KABAYAN_RESUBMISSION) {
      return useUserKabayanV2Query(undefined, queryOptions);
    }
    return useUserKabayanQuery(undefined, queryOptions);
  };

  const {
    data: resKabayan,
    refetch: getKabayan,
    isFetching: isFetchingKabayan,
    isFetched: isFetchedKabayan,
  } = getKabayanQuery();

  const token = cookies.tokenAuth || '';
  const isLogin = !!cookies.tokenAuth;
  const isFetchedQuery =
    (isFetchedProfile && isFetchedKabayan) ||
    (!!getCookieManual(userProfileData) && !!getCookieManual(userKabayanData));

  const userProfile = useMemo(() => {
    const profileData =
      (FEATURE_ENABLED_ENHANCE_PERFORMANCE ? cookies.userProfileData : resProfile.userProfile) ||
      {};

    const kabayanData =
      (FEATURE_ENABLED_ENHANCE_PERFORMANCE ? cookies.userKabayanData : resKabayan.userKabayan) ||
      {};

    const isShrimpUser = FEATURE_BLOCK_SHRIMP_USER && profileData?.mainCommodity === 'shrimp';

    const { provision: provPercent } = kabayanData;

    let provisionPercentage = 1;
    if (provPercent) {
      provisionPercentage = provPercent;
    }

    return {
      ...profileData,
      isShrimpUser,
      kabayan: { ...kabayanData, provisionPercentage },
      isKabayan: kabayanData.isKabayan,
      isKabayanProgress: kabayanData.isKabayanProgress,
    };
  }, [
    resProfile.userProfile,
    resKabayan.userKabayan,
    cookies.userProfileData,
    cookies.userKabayanData,
  ]);

  if (window) {
    window.token = token;
    window.customDeviceId = cookies.customDeviceId || '';
    window.customDeviceIdNew = cookies.customDeviceIdNew || '';
    window.customDeviceIdSD = cookies.customDeviceIdSD || '';
    window.appVersion = cookies.appVersion || '';
  }

  const refreshKabayan = () => getKabayan();

  const setCookieVariables = () => {
    if (customDeviceId) {
      setCookie(cookieCustomDeviceId, customDeviceId, {
        path: '/',
        expires: getMonthFromNow(120),
      });
    }

    if (customDeviceIdNew) {
      setCookie(cookieCustomDeviceIdNew, customDeviceIdNew, {
        path: '/',
        expires: getMonthFromNow(120),
      });
    }

    if (customDeviceIdSD) {
      setCookie(cookieCustomDeviceIdSD, customDeviceIdSD, {
        path: '/',
        expires: getMonthFromNow(120),
      });
    }

    if (appVersion) {
      setCookie(cookieAppVersion, appVersion, {
        path: '/',
        expires: getMonthFromNow(120),
      });
    }
  };

  useEffect(() => {
    setCookieVariables();
  }, []);

  const getProfileAttemt = useRef(0);

  useEffect(() => {
    if (FEATURE_ENABLED_ENHANCE_PERFORMANCE) {
      if (isLogin && getProfileAttemt.current <= 3) {
        if (!getCookieManual(userProfileData)) getProfile(); // refetch profile data when userProfileData not available in cookie
        if (!getCookieManual(userKabayanData)) getKabayan(); // refetch kabayan data when userKabayanData not available in cookie
        getProfileAttemt.current += 1;
        refreshToken();
      }
    } else {
      if (
        isLogin &&
        !isFetchingProfile &&
        !resProfile.userProfile &&
        getProfileAttemt.current <= 3
      ) {
        getProfile();
        getKabayan();
        getProfileAttemt.current += 1;
        refreshToken();
      }
    }
  }, [isLogin, isFetchingProfile, resProfile.userProfile]);

  return {
    isLogin,
    isFetched: FEATURE_ENABLED_ENHANCE_PERFORMANCE ? isFetchedQuery : isFetched,
    isLoading: isFetchingProfile || isFetchingKabayan,
    userProfile,
    token,
    error: resProfile.error || resKabayan.loading,
    getProfile,
    refreshProfile: getProfile,
    refreshKabayan,
  };
};

export default useAuth;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const KBLSubmissionPage = loadable(
  () => import(/* webpackChunkName: "KBLSubmissionPage" */ './PageRegister'),
  {
    fallback: <LoadingScreen />,
  },
);

export const kblRoute: Route = {
  name: 'KBLSubmission',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.KABAYAN_LITE_SUBMISSION,
    component: KBLSubmissionPage,
  },
};

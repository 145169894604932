import flagrs from '../constants/flagr';

export const getMaintenanceContent = (data) => {
  const attachment = data?.variants?.[0]?.attachment || {};
  const content = {
    title: attachment?.title || '',
    description: attachment?.description || '',
  };

  return content;
};

export const getMaintenanceContentV2 = (data) => {
  const {
    title = '',
    description = '',
    illustration = null,
    hideHeader = false,
  } = data.variantAttachment || {};
  return {
    title,
    description,
    illustration,
    hideHeader,
  };
};

export const getAllFlagr = () =>
  Object.values(flagrs)
    .filter((id) => id)
    .map(Number);

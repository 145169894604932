import tw from 'twin.macro';

import styled, { createGlobalStyle } from 'styled-components';

// types
type ButtonContainerType = {
  isTitled: boolean;
  withHeader?: boolean;
};

type ContentContType = {
  noPadding?: boolean;
};

type ContentContainerType = {
  grayed?: boolean;
};

type SpacerTopType = {
  withHeader?: boolean;
};

// styles
export const Container = tw.div``;
export const ChildrenCont = styled.div<ContentContType>(({ noPadding }) => [
  tw`px-4`,
  noPadding && tw`px-0`,
]);

export const GlobalStyles = createGlobalStyle`
  .ReactModal__Content {
    transition: all 250ms ease-in-out;
    transform: translateY(250px);
  }

  .ReactModal__Content--after-open{
    transform: translateY(0px);
  }

  .ReactModal__Content--before-close{
    transform: translateY(250px);
  }

  .ReactModal__Overlay {
    transition: all 250ms ease-in-out;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`;

export const ContentContainer = styled.div<ContentContainerType>(({ grayed }) => [
  tw`flex-1 overflow-x[scroll] bg-white mb-4`,
  grayed && tw`bg-black-50`,
]);

export const ButtonContainer = styled.div<ButtonContainerType>(({ isTitled, withHeader }) => [
  tw`flex flex-col w-full justify-center pt-3 rounded-t-md bg-white z-index[1000]`,
  isTitled && tw`sticky top-0 w-full px-4 pb-3 pt-4`,
  withHeader && tw`border-bottom-width[1px] border-black-100 pt-2`,
]);
export const SpacerTop = styled.div<SpacerTopType>(({ withHeader }) => [
  tw`w-full height[56px]`,
  withHeader && tw`height[80px]`,
]);

export const TitleContainer = tw.div`flex items-center justify-between w-full`;
export const ModalTitle = tw.div`font-semibold text-lg text-black-500`;
export const ButtonSlide = tw.div`bg-black-100 self-center w-14 h-1 rounded-xl cursor-pointer`;

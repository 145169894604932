import tw, { theme } from 'twin.macro';

import styled, { css, CSSProperties } from 'styled-components';

import { ZINDEX_100 } from 'utils/styles/zindex';

// types
type HeaderType = {
  isTransparent?: boolean;
  isPrimaryBackground?: boolean;
};

export type HeaderContainerType = {
  gapTop?: string;
  position?: CSSProperties['position'];
  direction?: 'col' | 'row';
};

// styles
const baseHeader = css`
  ${tw`bg-white flex items-center text-lg`}
  min-height: 56px;
  padding: 10px 16px;
  max-width: 480px;
  width: 100%;
  color: ${theme`colors.observatory.500`};
`;

const headerTransparent = css`
  ${tw`bg-transparent`}
  color: ${theme`colors.white`};
`;

const headerPrimaryBg = tw`bg-aqua-500 text-white`;

const baseHeaderCont = ({ gapTop, position, direction }: HeaderContainerType) => css`
  ${direction === 'col' && tw`flex-col`}
  ${direction === 'row' && tw`flex-row`}
  ${tw`flex items-center left-0 top-0 text-lg w-full justify-center`}
  z-index: ${ZINDEX_100};
  position: ${position};
  ${process.env.REACT_APP_MODE === 'staging' ? `top: ${gapTop}` : ''}
`;

export const Header = styled.div<HeaderType>(({ isTransparent, isPrimaryBackground }) => [
  baseHeader,
  isPrimaryBackground && headerPrimaryBg,
  isTransparent && headerTransparent,
]);

export const HeaderContainer = styled.header<HeaderContainerType>(
  ({ gapTop, position, direction }) => [baseHeaderCont({ gapTop, position, direction })],
);

export const Content = tw.div`flex items-center w-full`;

const baseButton = css`
  cursor: pointer;
`;

export const Button = styled.button(() => [baseButton]);

const rightButton = tw`ml-auto cursor-pointer`;

export const RightButton = styled.div(() => [rightButton]);

import { EffectCallback, useEffect } from 'react';

type EffectProps = {
  callback: EffectCallback;
};

/**
 * @description run callback on the first render (on mount component once)
 * @example
 * ```typescript
 * const tracker = () => trackingMe()
 * const Component = () => (
 *  <OnFirstRender callback={tracker} />
 * )
 * ```
 */
export const OnFirstRender = ({ callback: firstRender }: EffectProps) => {
  useEffect(firstRender, []);
  return null;
};

import { differenceInDays, isValid, parse } from 'date-fns';
import format from 'date-fns/format';
import { id } from 'date-fns/locale';

import { monthMap, monthMapFull } from '../../components/DatePickerScroll/config';

export const getHourFromNow = (hour = 1) => {
  const today = new Date();
  today.setHours(today.getHours() + hour);

  return today;
};

export const getMonthFromNow = (month = 1) => {
  const today = new Date();
  today.setMonth(today.getMonth() + month);

  return today;
};

export const getDayFromNow = (day = 1) => {
  const today = new Date();
  today.setDate(today.getDate() + day);

  return today;
};

export const formatDate = (value, _format = 'dd LLLL yyyy', opt = { locale: id }) => {
  return value && format(new Date(value), _format, opt);
};

export const getDay = (date) => date.getDate();
export const getMonth = (date) => date.getMonth() + 1;
export const getYear = (date) => date.getFullYear();

export const formatFullDateID = (date, isFull) => {
  const dateInstance = new Date(date);
  const day = getDay(dateInstance);
  const month = isFull ? monthMapFull[getMonth(dateInstance)] : monthMap[getMonth(dateInstance)];
  const year = getYear(dateInstance);
  return `${day} ${month} ${year}`;
};

export const parseExifToDate = (exifObj) => {
  if (exifObj.Exif[36867]) {
    const dateTime = exifObj.Exif[36867];
    let date = dateTime.split(' ')[0].split(':').join('-');
    const time = dateTime.split(' ')[1];
    date = formatDate(new Date(date));
    return `${date} ${time}`;
  }
  return '';
};

export const changeDateFormatOCR = (input) => {
  const formatedDate = parse(input, 'dd-MM-yyyy', new Date());
  return formatedDate;
};

export const isDatesDifferent = (date1, date2, formatString = 'yyyy-MM-dd') => {
  // Check if both dates are valid
  if (!isValid(date1) || !isValid(date2)) {
    return false;
  }

  // Format the dates and compare
  const formattedDate1 = format(date1, formatString);
  const formattedDate2 = format(date2, formatString);

  return formattedDate1 !== formattedDate2;
};

export const getDifferenceDaysFromNow = (date) => {
  if (!isValid(date)) return 0;

  return differenceInDays(date, new Date());
};

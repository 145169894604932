export const DATA_QUERY = 'data_query';
export const formatDate = 'dd/mm/yyyy';
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const EFISHERYKU_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;
export const isSupportNotification = (() =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window)();
export * from './android';
export { default as flagr } from './flagr';

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const BankAccountPage = loadable(
  () => import(/* webpackChunkName: "BankAccountPage" */ './BankAccount'),
  {
    fallback: <LoadingScreen />,
  },
);

export const bankAccountRoutes: Route[] = [
  {
    name: 'Bank Account',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.BANK_ACCOUNT,
      component: BankAccountPage,
    },
  },
];

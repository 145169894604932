import {
  FLAGR_ACTIVE_PRODUCT_CATEGORIES,
  FLAGR_BELANJA_PAKAN_VIA_CHAT,
  FLAGR_BELI_PAKAN_V2,
  FLAGR_BLACKBOX,
  FLAGR_COMPLAINT_FORM_V2,
  FLAGR_COMPLAINT_HANDLING_V2,
  FLAGR_COMPLAINT_REQUEST_TOKEN_V2,
  FLAGR_CULTIVATION_FORM,
  FLAGR_DEMO_ACCOUNT_V2,
  FLAGR_E_NOTA_V2,
  FLAGR_EARLY_PAYMENT_V2,
  FLAGR_ENABLE_FILTER_POINT_ACTIVE,
  FLAGR_ENABLE_POPUP_HOMEPAGE,
  FLAGR_ENABLE_POPUP_UPDATE_NPWP,
  FLAGR_FARMER_BALANCE,
  FLAGR_FEEDBACK_CUSTOMER_V2,
  FLAGR_FILTER_PRODUCT_CATEGORIES_V2,
  FLAGR_FILTER_SUPPLIER_V2,
  FLAGR_FMS_V2,
  FLAGR_FORCE_UPDATE_V2,
  FLAGR_HIDE_STATUS,
  FLAGR_KABAYAN_ELLIGIBLE_CHECK_V2,
  FLAGR_KABAYAN_EXPRESS_V2,
  FLAGR_KABAYAN_STATUS_V2,
  FLAGR_KBR_FORM,
  FLAGR_KBR_SUBMISSION_POINTS_V2,
  FLAGR_KBR_SUBMISSION_V2,
  FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN,
  FLAGR_LAPAK_IKAN_V2,
  FLAGR_LOYALTY_ENTRY,
  FLAGR_MAINTENANCE_BELI_PAKAN_V2,
  FLAGR_MAINTENANCE_EFEEDER_V2,
  FLAGR_MAINTENANCE_LAPAK_IKAN_V2,
  FLAGR_MAINTENANCE_V2,
  FLAGR_OCR_KK_V2,
  FLAGR_OCR_KTP,
  FLAGR_ON_REAPPLY,
  FLAGR_OTP_SMS_V2,
  FLAGR_OTP_WA_V2,
  FLAGR_PAY_KABAYAN_V2,
  FLAGR_PAYMENT_EXPIRED_DURATION_V2,
  FLAGR_PCFA_CONTACT_V2,
  FLAGR_PRIVACY_POLICY,
  FLAGR_PRODUCT_SOLD_THRESHOLD,
  FLAGR_REGISTER_POINT,
  FLAGR_REPAYMENT_V2,
  FLAGR_SELF_TRANSACTION_V2,
  FLAGR_SUPPORT_INFO_V2,
} from 'env';

const FLAGRS = {
  FLAGR_BELI_PAKAN: FLAGR_BELI_PAKAN_V2,
  FLAGR_DEMO_ACCOUNT: FLAGR_DEMO_ACCOUNT_V2,
  FLAGR_LAPAK_IKAN: FLAGR_LAPAK_IKAN_V2,
  FLAGR_REPAYMENT: FLAGR_REPAYMENT_V2,
  FLAGR_KABAYAN_EXPRESS: FLAGR_KABAYAN_EXPRESS_V2,
  FLAGR_SELF_TRANSACTION: FLAGR_SELF_TRANSACTION_V2,
  FLAGR_FILTER_SUPPLIER: FLAGR_FILTER_SUPPLIER_V2,
  FLAGR_PAYMENT_EXPIRED_DURATION: FLAGR_PAYMENT_EXPIRED_DURATION_V2,
  FLAGR_PAY_KABAYAN: FLAGR_PAY_KABAYAN_V2,
  FLAGR_E_NOTA: FLAGR_E_NOTA_V2,
  FLAGR_MAINTENANCE: FLAGR_MAINTENANCE_V2,
  FLAGR_MAINTENANCE_BELI_PAKAN: FLAGR_MAINTENANCE_BELI_PAKAN_V2,
  FLAGR_MAINTENANCE_EFEEDER: FLAGR_MAINTENANCE_EFEEDER_V2,
  FLAGR_MAINTENANCE_LAPAK_IKAN: FLAGR_MAINTENANCE_LAPAK_IKAN_V2,
  FLAGR_LAPAK_IKAN_ENABLE_PAY_KABAYAN,
  FLAGR_FILTER_PRODUCT_CATEGORIES: FLAGR_FILTER_PRODUCT_CATEGORIES_V2,
  FLAGR_FORCE_UPDATE: FLAGR_FORCE_UPDATE_V2,
  FLAGR_PCFA_CONTACT: FLAGR_PCFA_CONTACT_V2,
  FLAGR_KABAYAN_STATUS: FLAGR_KABAYAN_STATUS_V2,
  FLAGR_OTP_WA: FLAGR_OTP_WA_V2,
  FLAGR_OTP_SMS: FLAGR_OTP_SMS_V2,
  FLAGR_SUPPORT_INFO: FLAGR_SUPPORT_INFO_V2,
  FLAGR_KBR_SUBMISSION: FLAGR_KBR_SUBMISSION_V2,
  FLAGR_KBR_SUBMISSION_POINTS: FLAGR_KBR_SUBMISSION_POINTS_V2,
  FLAGR_KABAYAN_ELLIGIBLE_CHECK: FLAGR_KABAYAN_ELLIGIBLE_CHECK_V2,
  FLAGR_COMPLAINT_FORM: FLAGR_COMPLAINT_FORM_V2,
  FLAGR_CULTIVATION_FORM,
  FLAGR_FEEDBACK_CUSTOMER: FLAGR_FEEDBACK_CUSTOMER_V2,
  FLAGR_COMPLAINT_REQUEST_TOKEN: FLAGR_COMPLAINT_REQUEST_TOKEN_V2,
  FLAGR_OCR_KK: FLAGR_OCR_KK_V2,
  FLAGR_OCR_KTP,
  FLAGR_BELANJA_PAKAN_VIA_CHAT,
  FLAGR_EARLY_PAYMENT: FLAGR_EARLY_PAYMENT_V2,
  FLAGR_PRODUCT_SOLD_THRESHOLD,
  FLAGR_LOYALTY_ENTRY,
  FLAGR_BLACKBOX,
  FLAGR_REGISTER_POINT,
  FLAGR_KBR_FORM,
  FLAGR_FARMER_BALANCE,
  FLAGR_PRIVACY_POLICY,
  FLAGR_ACTIVE_PRODUCT_CATEGORIES,
  FLAGR_COMPLAINT_HANDLING_V2,
  FLAGR_FMS_V2,
  FLAGR_ON_REAPPLY,
  FLAGR_ENABLE_FILTER_POINT_ACTIVE,
  FLAGR_ENABLE_POPUP_UPDATE_NPWP,
  FLAGR_HIDE_STATUS,
  FLAGR_ENABLE_POPUP_HOMEPAGE,
};

export default FLAGRS;

import { useContext } from 'react';

import { AuthContext } from '../context/AuthContext';

const useAuthContext = () => {
  const authCtx = useContext(AuthContext);

  if (!authCtx) {
    throw new Error('Something error with authContext');
  }

  return authCtx;
};

export default useAuthContext;

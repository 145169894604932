// Add route path here and sorting ascending a-z.
export const PATH = {
  ADDRESS: '/address',
  ADDRESS_REGISTER: '/address/register',
  ADDRESS_REGISTER_DETAIL: '/address/register/detail',
  ADDRESS_REGISTER_POSTAL_CODE: '/address/register/postal-code',
  AUTH_LOGIN: '/auth/login',
  BANK_ACCOUNT: '/bank-account',
  BELI_PAKAN: '/beli-pakan',
  BILL_HISTORY: '/bill-history',
  BILL_ONPAYMENT: '/bill-onpayment',
  BILL_CREATE: '/bill-create',
  CART: '/cart',
  CARA_BELANJA_PAKAN: '/cara-belanja-pakan',
  COMPLAINT_LIST: '/list-complaint',
  COMPLAINT_DETAIL: '/list-complaint/:id',
  CULTIVATION_FORM: '/cultivation-form',
  EDIT_PROFILE: '/me/edit',
  HELP: '/help',
  HOME: '/',
  KABAYAN: '/kabayan',
  KABAYAN_REGISTER: '/kabayan/register',
  KABAYAN_REGULER_SUBMISSION: '/kabayan/reguler/register',
  KABAYAN_LITE_SUBMISSION: '/kabayan/lite/register',
  KABAYAN_STATUS: '/kabayan/status',
  KABAYAN_RESUBMISSION: '/kabayan/resubmission',
  LAPAK_IKAN: '/lapak-ikan',
  LAPAK_IKAN_REGISTER: '/lapak-ikan/register',
  LAPAK_IKAN_VERIFICATION: '/lapak-ikan/verification',
  LAPAK_IKAN_CONTRACT: '/lapak-ikan/:supplyId/contract-stock',
  LIMIT_DEVICE: '/limited-device',
  NOTIFICATION: '/notif',
  ORDER_PAYMENT: '/order/payment/:token',
  ORDER_PAYMENT_SUCCESS: '/order/success',
  ORDER_TRACK_STANDALONE: '/order-track',
  PRIVACY_POLICY: '/privacy-policy',
  PROFILE: '/me',
  PRODUCT_DETAIL: '/pdp/:slug',
  PROMO: '/promo',
  PROMO_DETAIL: '/promo/:id',
  REFERRAL: '/referral',
  LOYALTY: '/loyalty',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/register-success',
  SETTING_CHANGE_NUMBER: '/user/change-number',
  SEARCH: '/search',
  TRANSACTION_HISTORY: '/transaction-history',
  TRANSACTION_DETAIL: '/transaction-detail',
  TRANSACTION_DETAIL_TRACK: '/transaction-detail-track',
  TRANSACTION_INVOICE: '/transaction-invoice',
  SUCCESS_ORDER_VALIDATION: '/success-order-validation',
  VERIFICATION: '/verification',
};

export const PARAMS = {
  REDIRECT_URL: 'redirect_url',
};

export const TRANSACTIONAL_ROUTES = [
  PATH.BELI_PAKAN,
  PATH.BELI_PAKAN_BRAND,
  PATH.LAPAK_IKAN,
  PATH.BILL_HISTORY,
  PATH.BILL_CREATE,
  PATH.BILL_ONPAYMENT,
  PATH.CART,
  PATH.KABAYAN,
  PATH.KABAYAN_REGISTER,
  PATH.KABAYAN_REGULER_SUBMISSION,
  PATH.KABAYAN_LITE_SUBMISSION,
  PATH.LAPAK_IKAN_REGISTER,
  PATH.LAPAK_IKAN_VERIFICATION,
  PATH.LAPAK_IKAN_CONTRACT,
  PATH.NOTIFICATION,
  PATH.PRODUCT_DETAIL,
  PATH.PROMO,
  PATH.PROMO_DETAIL,
  PATH.REFERRAL,
  PATH.LOYALTY,
  PATH.SEARCH,
  PATH.TRANSACTION_HISTORY,
  PATH.TRANSACTION_DETAIL,
  PATH.TRANSACTION_INVOICE,
];

export const CLIENT_ID = 'client_id';

import tw, { theme } from 'twin.macro';

import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

// types
type ConditionalInputType = {
  $isLarge?: boolean;
  isSmall?: boolean;
  disabled?: boolean;
  $isError?: boolean;
  $isBoldStyle?: boolean;
};

type LabelType = {
  isRight?: boolean;
  disabled?: boolean;
  $isBoldStyle?: boolean;
};

type MessageType = {
  isError?: boolean;
  isChecking?: boolean;
  isSmall?: boolean;
  isGrayed?: boolean;
};

type IconRightType = {
  isLarge?: boolean;
  isSmall?: boolean;
};

type ContainerType = {
  shortBottom?: boolean;
  css?: any;
};

type TSubLabel = {
  isBold?: boolean;
};

// styles
const errorColor = css`
  color: ${theme`colors.red.500`};
`;

const checkingColor = css`
  color: #038767;
`;

export const baseInput = css`
  padding: 6px 16px;
  -webkit-appearance: none;

  ${tw`focus:outline-none  border focus:border-observatory-400  border-solid border-black-500
    w-full text-base block placeholder-black-100 rounded-lg mb-1`}
`;

export const baseDisabled = css`
  ${tw`bg-black-50 border-black-200  text-black-300`}
`;

const baseSmallInput = css`
  padding: 2px 16px;
`;

const baseLargeInput = css`
  padding: 11px 16px;
`;

const baseErrorInput = css`
  ${tw`border-red-500`}
`;

const boldStyleInput = css`
  ${tw`border-width[2px] border-observatory-500 height[46px]`}
`;

export const conditionalInput = ({
  $isLarge,
  isSmall,
  disabled,
  $isError,
  $isBoldStyle,
}: ConditionalInputType): FlattenSimpleInterpolation => {
  return css`
    ${baseInput}
    ${isSmall && baseSmallInput}
    ${$isLarge && baseLargeInput}
    ${disabled && baseDisabled}
    ${$isError && baseErrorInput}
    ${$isBoldStyle && boldStyleInput};
  `;
};

export const Input = styled.input((props: ConditionalInputType) => [conditionalInput(props)]);

const baseLabel = css`
  ${tw`text-base`}
`;

const rightLabel = css`
  ${tw`float-right font-light`}
`;

const boldStyleLabel = tw`font-medium mb-1`;

export const Label = styled.label<LabelType>(({ isRight, disabled, $isBoldStyle }) => [
  baseLabel,
  $isBoldStyle && boldStyleLabel,
  // Right Label
  isRight && rightLabel,
  disabled && tw`text-black-200`,
]);

export const SubLabel = styled.div<TSubLabel>(({ isBold }) => [
  tw`text-sm text-black-300 mt-1`,
  isBold && tw`font-semibold`,
]);

const baseMessage = css`
  ${tw`text-sm`}
`;
const smallMessage = css`
  ${tw`text-xs`}
`;
const grayedMessage = tw`text-black-300`;
export const Message = styled.span<MessageType>(({ isError, isChecking, isSmall, isGrayed }) => [
  baseMessage,
  isError && errorColor,
  isSmall && smallMessage,
  isGrayed && grayedMessage,
  isChecking && checkingColor,
]);

const baseIconRight = css`
  ${tw`text-xs`}
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  margin-right: 12px;
  align-items: center;
  padding: 7px 0px;
`;

const iconLarge = css`
  padding: 11px 0px;
`;

const iconSmall = css`
  padding: 4px 0px;
`;

export const IconRight = styled.div<IconRightType>(({ isLarge, isSmall }) => [
  baseIconRight,

  isLarge && iconLarge,
  isSmall && iconSmall,
]);

const baseIconLeft = css`
  ${tw`text-xs cursor-pointer absolute items-center flex ml-3 left-0 top-0`}
  padding: 7px 0px;
`;

export const IconLeft = styled.div<IconRightType>(({ isLarge, isSmall }) => [
  baseIconLeft,

  isLarge && iconLarge,
  isSmall && iconSmall,
]);

export const inputContainer = css`
  ${tw`relative`}
`;

export const LabelContainer = tw.div`mb-2`;
export const InputDesc = tw.div`mb-3 color[#777] font-size[14px]`;

export const InputContainer = styled.div`
  ${inputContainer}
`;

export const Container = styled.div<ContainerType>(({ shortBottom }) => [
  tw`mb-6`,
  shortBottom && tw`mb-2.5`,
]);

export const MessagesContainer = tw.div`flex flex-col`;

export const DescHtml = tw.div`color[#4A4A4A]  font-size[14px] font-weight[400] line-height[22px]`;

import { PATH } from 'routes/constants';
import history from 'routes/history';

import ImgBenih from '../assets/benih.webp';
import ImgPakan from '../assets/pakan.webp';

export const redirectToSearch = (params: {
  keyword?: string;
  brand?: string;
  category?: string;
  pointId?: string;
}) => {
  const params_ = Object.entries(params).reduce(
    (acc, [key, value]) => (value ? `${acc}${key}=${encodeURI(value)}&` : acc),
    '?',
  );
  history.push(`${PATH.SEARCH}${params_}`);
};

export const redirectToHome = () => history.replace(PATH.HOME);

export const redirectToLogin = () => history.replace(PATH.AUTH_LOGIN);

export const redirectToHelp = () => history.push(PATH.HELP);

export const redirectToProductDetail = (slug: string, widgetId?: string) =>
  history.push(
    `${PATH.PRODUCT_DETAIL.replace(':slug', slug)}${widgetId ? `?from_widget=${widgetId}` : ''}`,
  );

export const redirectToAuthProductDetail = () =>
  history.push(`${PATH.AUTH_LOGIN}?next=${encodeURIComponent(PATH.BELI_PAKAN)}`);

export const defaultCategories = ['pakan', 'benih'];

export const filterCategories = (category: string) => defaultCategories.includes(category);

export type Category = {
  category: string;
  imageUrl: string;
};

export const mapCategories = (category: string): Category => ({
  category,
  imageUrl: category === 'pakan' ? ImgPakan : ImgBenih,
});

export const FILTER_BRAND_CATEGS = ['pakan', 'fish feed'];

export const DEFAULT_SHOP_VIA_CHAT_URL =
  'https://wa.me/6285960372906?text=Halo%20mimin%20eFishery,%20saya%20mau%20pesan%20pakan';

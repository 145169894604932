import 'twin.macro';

import React, { createContext, FunctionComponent, useContext, useState } from 'react';
import { X } from '@onefish/icons-react';
import { FEATURE_ENHANCE_OTP_REQUEST } from 'env';
import Modal from 'react-modal';

import useFlagrContext from 'utils/hooks/useFlagrContext';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import SMSSvg from './assets/sms.svg';
import WASvg from './assets/wa.svg';
import {
  BorderButton,
  Description,
  GlobalStyles,
  Icon,
  Question,
  QuestionContainer,
} from './style';

const Icons = [SMSSvg, WASvg];

interface IOTPMethodModal {
  showModal: (opt: IButtons) => void;
  closeModal: () => void;
}

const OTPMethodModalContext = createContext<IOTPMethodModal>({
  showModal: () => {},
  closeModal: () => {},
});

interface IOTPModalAction {
  label: string;
  icon?: HTMLImageElement['src'];
  onClick: () => void;
}

interface IButtons {
  buttons: IOTPModalAction[];
}

interface IOTPMethodModalContainer {
  children: React.ReactNode;
}

const newOtpText = {
  title: 'Kode OTP akan Dikirimkan',
  description: (
    <>
      <p style={{ marginBlockStart: 0, marginBlockEnd: 8 }}>
        Pilih opsi pengiriman 6 digit kode OTP. Sebelum lanjut, pastikan:
      </p>
      <ul style={{ marginBlock: 0, paddingInlineStart: 20 }}>
        <li>Nomor HP sudah sesuai & aktif.</li>
        <li>Kotak pesan SMS tidak penuh.</li>
        <li>Koneksi internet/seluler tersedia.</li>
      </ul>
    </>
  ),
};

const OTPMethodModalContainer: FunctionComponent<IOTPMethodModalContainer> = ({ children }) => {
  const modalHeight = FEATURE_ENHANCE_OTP_REQUEST ? 310 : 250;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const [buttons, setButtons] = useState<IOTPModalAction[]>([]);

  const closeModal = () => setIsOpen(false);
  const showModal = (opt: IButtons) => {
    setButtons(opt.buttons || []);
    setIsOpen(true);
  };

  const { otpEnabled } = useFlagrContext();

  return (
    <OTPMethodModalContext.Provider value={{ showModal, closeModal }}>
      <GlobalStyles />
      <div>
        {children}
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          closeTimeoutMS={250}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(29, 29, 29, 0.30)',
              zIndex: 10000,
            },
            content: {
              width: '100%',
              maxWidth: 480,
              height: modalHeight,
              bottom: 0,
              top: `calc(100% - ${modalHeight}px)`,
              left: width && width > 480 ? `calc(50% - 240px)` : 0,
              borderRadius: `8px 8px 0 0`,
            },
          }}
        >
          <QuestionContainer>
            <Question data-testid="txt_popup_otp_option">
              {FEATURE_ENHANCE_OTP_REQUEST ? newOtpText.title : 'Verifikasi Kode OTP'}
            </Question>
            <X onClick={closeModal} size={24} tw="text-observatory-500 cursor-pointer" />
          </QuestionContainer>
          <Description>
            {FEATURE_ENHANCE_OTP_REQUEST
              ? newOtpText.description
              : 'Pilih opsi berikut untuk dapetin kode OTP berupa 6 angka.'}
          </Description>
          {buttons.map((b, i) => {
            return (
              <BorderButton
                key={i}
                onClick={() => {
                  if (b?.onClick) b?.onClick();
                  closeModal();
                }}
                data-testid={`btn_popup_otp_${b.label.toLowerCase()}`}
                disabled={b.label === 'SMS' ? !otpEnabled.sms : !otpEnabled.wa}
              >
                <Icon src={b.icon || Icons[i]} />
                <span>{b?.label}</span>
              </BorderButton>
            );
          })}
        </Modal>
      </div>
    </OTPMethodModalContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOTPMethodModal = () => useContext<IOTPMethodModal>(OTPMethodModalContext);
export { OTPMethodModalContainer, useOTPMethodModal };

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import { matchPath } from 'react-router-dom';

import history from 'routes/history';
import routes from 'routes/routes';

export const initialize = () => {
  if (process.env.REACT_APP_MODE === 'local') return;

  const integrations = [
    process.env.REACT_APP_MODE === 'production'
      ? new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
        })
      : null,
  ];

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: integrations.filter(Boolean),
    tracesSampleRate: 1.0,
    sampleRate: 1,
    release: process.env.REACT_APP_BITBUCKET_TAG || 'master',
    environment: process.env.REACT_APP_MODE,
  });
};

export const reportError = (useCase, content) => {
  Sentry.setContext('manual-error-reporting', typeof content === 'object' ? content : { content });
  Sentry.captureException(useCase instanceof Error ? useCase : new Error(useCase));
};

export const setUser = (userProfile) => {
  const user = {
    email: userProfile.email || '',
    name: userProfile.name || '',
  };
  Sentry.setUser(user);
};

export const reportAxiosError = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const isReturnsResponse = !!error.response;
      const { url, method, data, headers } = error.config;
      const request = { url, method, data, headers };
      Sentry.setContext('request', request);

      if (isReturnsResponse) {
        // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
        const { data, status } = error.response;
        const response = { data, status };
        Sentry.setContext('response', response);
      }

      return Promise.reject(error);
    },
  );
};

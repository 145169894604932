import { TwStyle } from 'twin.macro';

import React, { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import InputMask from 'react-text-mask';

import Guide from 'components/Guide';
import { omit } from 'utils/helpers/object';

import {
  conditionalInput,
  Container,
  DescHtml,
  IconLeft,
  IconRight,
  Input,
  InputContainer,
  InputDesc,
  Label,
  LabelContainer,
  Message,
  MessagesContainer,
  SubLabel,
} from './style';

// eslint-disable-next-line react/display-name
const InputComp = forwardRef<HTMLInputElement, IInputComp>((props, ref) => {
  const {
    label,
    description,
    descriptionHtml,
    labelStyle,
    rightlabel,
    required,
    children,
    isMasking,
    disabled,
    shortBottom,
    isBoldStyle,
    showLabel,
    containerCss,
    isError,
    isLarge,
    subLabel,
    subMessage,
    smallMessage,
    errorMessage,
    color,
    guideComponent,
    boldSubLabel,
    testId,
    subMessageStyle,
    isLoading,
    isDatePicker,
    fullTestId,
  } = props;

  const inputProps: any = omit(props, [
    'children',
    'showLabel',
    'isMasking',
    'rightLabel',
    'containerCss',
    'shortBottom',
    'isBoldStyle',
    'isError',
    'isLarge',
    'subLabel',
    'boldSubLabel',
    'labelStyle',
    'smallMessage',
    'subMessage',
    'errorMessage',
    'color',
    'subMessageStyle',
    'isLoading',
    'isDatePicker',
    'testId',
    'fullTestId',
  ]);

  if (color) {
    inputProps.style = { ...(inputProps.style || {}), ...{ color } };
  }

  const testIdPrefix = isDatePicker ? 'date_of' : 'txtfld';

  return (
    <Container shortBottom={shortBottom} css={containerCss}>
      <LabelContainer>
        {showLabel && label && (
          <Label
            $isBoldStyle={isBoldStyle}
            disabled={disabled}
            style={labelStyle || {}}
            data-testid={props.name ? `txt_label_${props.name}` : undefined}
          >
            {label}
            {required ? <span tw="text-red-500">*</span> : ''}
          </Label>
        )}
        {subLabel && <SubLabel isBold={boldSubLabel}>{subLabel}</SubLabel>}
        {rightlabel && <Label isRight>{rightlabel}</Label>}
      </LabelContainer>
      {!!description && <InputDesc>{description}</InputDesc>}
      {!!descriptionHtml && <DescHtml dangerouslySetInnerHTML={{ __html: descriptionHtml }} />}
      {!!guideComponent && (
        <Guide buttonLabel={guideComponent.label} heading={guideComponent.heading} testId={testId}>
          <guideComponent.content />
        </Guide>
      )}
      <InputContainer>
        {isLoading ? (
          <Skeleton height={48} />
        ) : (
          <>
            {!isMasking ? (
              <Input
                {...inputProps}
                $isError={isError}
                $isLarge={isLarge}
                ref={ref}
                data-testid={fullTestId || `${testIdPrefix}_${testId}`}
              />
            ) : (
              <>
                <InputMask
                  {...inputProps}
                  $isError={isError}
                  ref={ref}
                  css={conditionalInput({ ...inputProps, $isError: isError, $isLarge: isLarge })}
                  data-testid={fullTestId || `${testIdPrefix}_${testId}`}
                />
              </>
            )}
          </>
        )}
        {children}
        {(subMessage || errorMessage) && (
          <MessagesContainer>
            {subMessage && (
              <Message
                isSmall
                tw="mb-2 mt-1"
                css={subMessageStyle}
                isGrayed
                data-testid={`txt_sub_message_${props.name}`}
              >
                {' '}
                {subMessage}{' '}
              </Message>
            )}
            {errorMessage && (
              <Message isError data-testid={`txt_error_message_${props.name}`}>
                {errorMessage}
              </Message>
            )}
          </MessagesContainer>
        )}
        {smallMessage && (
          <Message isSmall data-testid={`txt_small_message_${props.name}`}>
            {smallMessage}
          </Message>
        )}
      </InputContainer>
    </Container>
  );
}) as CompoundedComponent;

InputComp.IconRight = IconRight;

InputComp.IconLeft = IconLeft;

InputComp.Message = Message;

InputComp.displayName = 'InputComp';

export interface CompoundedComponent
  extends React.ForwardRefExoticComponent<IInputComp & React.RefAttributes<HTMLInputElement>> {
  IconRight: (props: any) => any;
  IconLeft: (props: any) => any;
  Message: (props: any) => any;
}

export interface IInputComp {
  testId?: string;
  showLabel?: boolean;
  guide?: boolean;
  guideComponent?: {
    label: string;
    heading: string;
    content: () => JSX.Element;
  };
  label?: string;
  description?: string;
  descriptionHtml?: string;
  labelStyle?: object;
  rightlabel?: string;
  message?: string;
  isError?: boolean;
  required?: boolean;
  children?: any;
  isMasking?: boolean;
  disabled?: boolean;
  shortBottom?: boolean;
  isBoldStyle?: boolean;
  containerCss?: any;
  isLarge?: boolean;
  value?: string;
  placeholder?: string;
  readOnly?: boolean;
  mask?: any;
  subLabel?: string;
  onFocus?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  type?: string;
  name?: string;
  subMessage?: string;
  smallMessage?: string;
  errorMessage?: string;
  color?: string;
  boldSubLabel?: boolean;
  subMessageStyle?: TwStyle[];
  isLoading?: boolean;
  isDatePicker?: boolean;
  fullTestId?: string;
}

InputComp.defaultProps = {
  label: '',
  labelStyle: {},
  rightlabel: '',
  message: '',
  showLabel: true,
  isError: false,
  required: false,
  children: null,
  isMasking: false,
  disabled: false,
  shortBottom: false,
  isBoldStyle: false,
  containerCss: {},
  isLarge: false,
  subLabel: '',
  boldSubLabel: false,
  onChange: () => {},
};

export default InputComp;

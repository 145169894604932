import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [],
  active: false,
};

export const CoachmarkContext = createContext({
  tour: initialState,
  setTour: () => undefined,
});

CoachmarkContext.displayName = 'CoachmarkContext';

const useSetState = () => {
  const [state, set] = useState(initialState);
  const setState = useCallback((patch) => {
    set((prevState) => ({
      ...prevState,
      ...(patch instanceof Function ? patch(prevState) : patch),
    }));
  }, []);

  return [state, setState];
};

export const CoachmarkProvider = (props) => {
  const [tour, setTour] = useSetState(initialState);
  const value = useMemo(
    () => ({
      tour,
      setTour,
    }),
    [tour, setTour],
  );

  return <CoachmarkContext.Provider value={value} {...props} />;
};

export const useCoachmarkContext = () => {
  const context = useContext(CoachmarkContext);

  if (!context) {
    throw new Error('useCoachmarkContext must be used within a CoachmarkProvider');
  }

  return context;
};

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const HomePage = loadable(() => import(/* webpackChunkName: "HomePage" */ './Home'), {
  fallback: <LoadingScreen />,
});

export const homeRoutes: Route[] = [
  {
    name: 'Home',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.HOME,
      component: HomePage,
    },
  },
];

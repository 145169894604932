import axios, { AxiosError } from 'axios';
import { FLAGR_COMMODITY_LAPAK_IKAN_V2 } from 'env';

import { getAllFlagr } from 'utils/helpers/flagr';
import { IUserProfile } from 'utils/hooks/useAuthV2';

export const getFlagrCommodityLapakIkan = async (user: Partial<IUserProfile> = {}) => {
  try {
    const flagURL = `${process.env.REACT_APP_FLAGR_URL_V2}/api/v1/evaluation`;
    const response = await axios.post(flagURL, {
      entityID: user ? user.leadId : 'guest',
      entityType: user ? 'userLeadId' : 'user',
      entityContext: user || {},
      enableDebug: false,
      flagID: Number(FLAGR_COMMODITY_LAPAK_IKAN_V2),
    });
    const flag = response?.data;

    return {
      enabled: flag?.variantKey ? flag.variantKey !== 'off' : false,
      variants: (flag?.variantAttachment?.variant || []).map((e) => ({ key: e })),
    };
  } catch (error) {
    throw error as Error | AxiosError;
  }
};

export const getFlagrGeneral = async (id) => {
  const flagURL = `${process.env.REACT_APP_FLAGR_URL}/api/v1/flags/${id}`;
  const resp = await axios.get(flagURL);
  return resp.data;
};

export const getAllEfisheryKuFlagr = async () => {
  const flagURL = `${process.env.REACT_APP_FLAGR_URL}/api/v1/flags?tags=efisheryku&preload=true`;
  const resp = await axios.get(flagURL);
  return resp?.data;
};

export const getAllEfisheryKuFlagrV2 = async (user: Partial<IUserProfile> = {}) => {
  const flagrs = getAllFlagr();
  const flagURL = `${process.env.REACT_APP_FLAGR_URL_V2}/api/v1/evaluation/batch`;
  const resp = await axios.post(flagURL, {
    entities: user.leadId
      ? [
          {
            entityID: user.leadId,
            entityType: 'userLeadId',
            entityContext: { ...user, point_code: user.point ? parseInt(user.point.code) : null },
          },
        ]
      : [
          {
            entityID: 'guest',
            entityType: 'user',
            entityContext: {
              userType: 'guest',
              ...(user.point?.code ? { point_code: parseInt(user.point.code) } : {}),
            },
          },
        ],
    flagIDs: flagrs,
    enableDebug: false,
  });
  return resp?.data;
};

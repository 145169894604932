export const NOOP: () => void = () => {};

type NumOrStr = number | string;

export function generateUEID(): string {
  let first: NumOrStr = Math.random() * 46656 || 0;
  let second: NumOrStr = Math.random() * 46656 || 0;
  first = `000${first.toString(36)}`.slice(-3);
  second = `000${second.toString(36)}`.slice(-3);
  return first + second;
}

export function Timer(
  // eslint-disable-next-line
  this: any,
  { callback = () => {}, delay }: { callback: () => void; delay: number },
): void {
  let timerID: NodeJS.Timeout | string | number | undefined;
  let start = delay;
  let remaining = delay;

  this.clear = () => {
    clearTimeout(timerID);
  };

  this.pause = () => {
    clearTimeout(timerID);
    remaining -= Date.now() - start;
  };

  this.resume = () => {
    start = Date.now();
    clearTimeout(timerID);
    timerID = setTimeout(callback, remaining);
  };

  this.resume();
}

import React from 'react';
import loadable from '@loadable/component';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const KabayanPages = loadable(() => import(/* webpackChunkName: "KabayanPages" */ './Kabayan'), {
  fallback: <LoadingScreen />,
});

const KabayanStatusPages = loadable(
  () => import(/* webpackChunkName: "KabayanStatusPages" */ './KabayanStatus'),
  {
    fallback: <LoadingScreen />,
  },
);

export const kabayanRoutes = [
  {
    name: 'Kabayan',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.KABAYAN,
      component: KabayanPages,
    },
  },
  {
    name: 'Kabayan Status',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.KABAYAN_STATUS,
      component: KabayanStatusPages,
    },
  },
];

import 'twin.macro';

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { X } from '@onefish/icons-react';
import ReactModal, { Styles } from 'react-modal';

import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import {
  ButtonContainer,
  ButtonSlide,
  ChildrenCont,
  ContentContainer,
  GlobalStyles,
  ModalTitle,
  TitleContainer,
} from './styles';

const BottomSheet: FunctionComponent<IBottomSheet> = ({
  isOpen,
  children,
  title,
  noPadding,
  withHeader,
  transparency,
  closeModal,
  onClickButtonTick,
  isGrayed,
  testId,
  fullScreen = false,
  shouldCloseOnOverlayClick = true,
  hideClose = false,
  onOpen,
  styles,
}) => {
  const { width } = useWindowDimensions();

  const custom: Styles = useMemo(
    () => ({
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(29, 29, 29, ${transparency || 0.3})`,
        zIndex: 10000,
        ...styles?.overlay,
      },
      content: {
        width: '100%',
        maxWidth: 480,
        maxHeight: fullScreen ? '100%' : '90%',
        bottom: 0,
        left: width ? (width > 480 ? `calc(50% - 240px)` : 0) : 0,
        borderRadius: fullScreen ? 0 : `8px 8px 0 0`,
        padding: 0,
        top: fullScreen ? 0 : 'auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        ...styles?.content,
      },
    }),
    [width],
  );

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  return (
    <>
      <GlobalStyles />
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={250}
        style={custom}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <ContentContainer grayed={isGrayed} data-testid={`wrp_modal_${testId}`}>
          <ButtonContainer isTitled={!!title} withHeader={withHeader}>
            {title ? (
              <>
                {withHeader && <ButtonSlide onClick={onClickButtonTick} tw="mb-3" />}
                <TitleContainer>
                  <ModalTitle data-testid={`txt_modal_${testId}_title`}>{title}</ModalTitle>
                  {hideClose ? null : (
                    <a
                      tw="flex p-2 -m-2 cursor-pointer"
                      onClick={closeModal}
                      data-testid={`btn_modal_${testId}_close`}
                    >
                      <X size={25} tw="text-green-400" />
                    </a>
                  )}
                </TitleContainer>
              </>
            ) : (
              <ButtonSlide onClick={onClickButtonTick} />
            )}
          </ButtonContainer>
          <ChildrenCont noPadding={noPadding}>{children}</ChildrenCont>
        </ContentContainer>
      </ReactModal>
    </>
  );
};

interface IBottomSheet {
  isOpen: boolean;
  isGrayed?: boolean;
  children: any;
  title: string;
  noPadding?: boolean;
  withHeader?: boolean;
  transparency?: number;
  testId?: string;
  closeModal?: () => void;
  onClickButtonTick?: () => void;
  fullScreen?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  hideClose?: boolean;
  onOpen?: () => void;
  styles?: Styles;
}

export default BottomSheet;

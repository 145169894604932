import { MerkleBaseDataLayer } from 'types/Merkle';

export enum ItemListId {
  Promo = 'promo',
  Reorder = 'reorder',
  Recommendation = 'recommendation_product',
  Catalog = 'product_in_catalog_page',
  NewFarmer = 'new_farmer_recommendation',
  Other = 'other_recommendation',
}

export enum ItemListName {
  Promo = 'Pilihan Sesuai Promo',
  Reorder = 'Yang Terakhir Kamu Beli',
  Recommendation = 'Pakan Terlaris',
  Catalog = 'Catalog',
  NewFarmer = 'Sering Dipesan Pengguna Baru',
  Other = 'Pakan Lainnya',
}

export const getItemListName = (id: ItemListId) => {
  switch (id) {
    case ItemListId.Promo:
      return ItemListName.Promo;
    case ItemListId.Reorder:
      return ItemListName.Reorder;
    case ItemListId.Recommendation:
      return ItemListName.Recommendation;
    case ItemListId.Catalog:
      return ItemListName.Catalog;
    case ItemListId.NewFarmer:
      return ItemListName.NewFarmer;
    default:
      return null;
  }
};

export enum Currency {
  IDR = 'IDR',
}

export type Commerce = {
  currency?: Currency;
  value?: number;
  coupon?: string;
  shipping_tier?: string;
  items: CommerceItem[];
  item_list_id?: string;
  item_list_name?: string;
  payment_type?: string;
};

export type CommerceItem = {
  item_id: number;
  item_name: string;
  affiliation: string;
  index: number;
  item_brand: string;
  item_category: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  price: number;
  quantity: number;
};

export type ProductDetail = {
  id: number;
  itemId: number;
  supplier: Supplier;
  images: Image[];
  supplierImage: string;
  productName: string;
  price: string;
  supplierName: string;
  category: string;
  size: string;
  type: string;
  isReadyStock: boolean;
  productDescription: string;
  nutritions: any[];
  ingredients: string;
  howToUse: string;
  storageMethod: string;
  quantityUnit: number;
  brandName: string;
  brandImage: string;
  tenors: Tenor[];
  unit: string;
  commodities: string[];
  numStock: number;
  internalStock: number;
  isBlacklist: boolean;
  productDiscount: ProductDiscount;
  total_order: number;
};

export interface Supplier {
  id: number;
  name: string;
  imageUrl: string;
  supplierType: string;
  isInternalSupplier: boolean;
}

export interface Image {
  bannerImage: string;
}

export interface Tenor {
  price: number;
  tenor: number;
  formattedPrice: string;
  formattedTenor: string;
}

export interface ProductDiscount {
  promoType: string;
  promoUnit: string;
  discountAmount: number;
}

export type DataLayerCommerceEvent = MerkleBaseDataLayer & {
  event_category: 'ecommerce';
} & Commerce;

export type DataLayerCommerceNullEvent = {
  ecommerce: null;
};

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const OrderTrackPages = loadable(
  () => import(/* webpackChunkName: "OrderTrackPages" */ './OrderTrackPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const orderTrackRoutes: Route[] = [
  {
    routeProps: {
      exact: true,
      path: PATH.ORDER_TRACK_STANDALONE,
      component: OrderTrackPages,
    },
    isPublic: true,
    name: 'Lacak Pesanan',
  },
];

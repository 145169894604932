import tw from 'twin.macro';

import styled from 'styled-components';

import ButtonComp from 'components/Button';

export const SnackbarWrapper = tw.div`w-full`;

interface SnackbarContainerProps {
  isActionBlock?: boolean;
}

export const SnackbarContainer = styled.div<SnackbarContainerProps>(({ isActionBlock }) => [
  tw`w-auto flex justify-center mb-1 pointer-events-auto`,
  isActionBlock && tw`flex-col`,
]);

export const SnackbarContent = tw.div`mr-2`;

export const SnackbarAction = styled.div<SnackbarContainerProps>(({ isActionBlock }) => [
  tw`ml-auto flex`,
  isActionBlock && tw`mt-2`,
]);

interface SnackbarCapsuleProps {
  isSuccess?: boolean;
}

export const SnackbarCapsule = styled.div<SnackbarCapsuleProps>(({ isSuccess }) => [
  tw`width[90%] max-width[460px] background-color[#4A4A4A] justify-between text-white py-2.5 px-4 border-radius[8px] flex items-center content-start`,
  isSuccess && tw`background-color[#038767]`,
]);

interface ButtonProps {
  textWhite?: boolean;
}
export const Button = styled(ButtonComp)<ButtonProps>(({ textWhite }) => [
  tw`font-semibold w-auto py-0.5 text-base ml-1.5`,
  textWhite && tw`text-white`,
]);

import tw from 'twin.macro';

import styled, { css } from 'styled-components';

import { ZINDEX_100 } from 'utils/styles/zindex';

export const Container = styled.div`
  ${tw`bg-white flex flex-col justify-center items-center`};
  height: ${process.env.REACT_APP_MODE === 'staging' ? 'calc(100vh - 25px) !important' : '100vh'};
`;

const rippleAnimation = css`
  @keyframes ripple {
    from {
      box-shadow: 0 0 0 0 rgba(148, 221, 199, 0.3), 0 0 0 8px rgba(148, 221, 199, 0.3),
        0 0 0 24px rgba(148, 221, 199, 0.3), 0 0 0 48px rgba(148, 221, 199, 0.3);
    }
    to {
      box-shadow: 0 0 0 8px rgba(148, 221, 199, 0.3), 0 0 0 24px rgba(148, 221, 199, 0.3),
        0 0 0 48px rgba(148, 221, 199, 0.3), 0 0 0 72px rgba(148, 221, 199, 0);
    }
  }
  animation: ripple 0.8s linear infinite;
`;

export const LoadingContainer = styled.div`
  ${tw`flex flex-col justify-center items-center text-observatory-500 text-lg relative`};
`;

export const LogoContainer = styled.div`
  ${tw`flex justify-center items-center mb-14`};
`;

export const LoadingRipple = styled.div`
  ${tw`w-5 h-5 bg-observatory-300 border-radius[3rem] absolute`};
  ${rippleAnimation}
`;

export const Image = styled.img`
  z-index: ${ZINDEX_100};
  width: 60px;
  height: 60px;
`;

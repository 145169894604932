import tw from 'twin.macro';

import { createGlobalStyle } from 'styled-components';

// types

// styles
export const ContentContainer = tw.div`bg-white w-full justify-center p-4`;
export const ChildrenCont = tw.div`flex flex-col items-center mb-4`;
export const Title = tw.div`font-semibold text-base mb-3 text-black-500`;

export const GlobalStyles = createGlobalStyle`
  .ReactModal__Content {
    transition: all 250ms ease-in-out;
    transform: translateY(250px);
  }

  .ReactModal__Content--after-open{
    transform: translateY(0px);
  }

  .ReactModal__Content--before-close{
    transform: translateY(250px);
  }

  .ReactModal__Overlay {
    transition: all 250ms ease-in-out;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`;

export const LockScroll = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;

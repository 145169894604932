import { FEATURE_AUTO_ADD_UTM_PARAMS } from 'env';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_id',
  'utm_term',
  'utm_content',
];

const initializeUtmParamsToSessionStorage = () => {
  const initialSearchParams = new URLSearchParams(window.location.search);
  UTM_PARAMS.forEach((param) => {
    if (initialSearchParams.has(param)) {
      sessionStorage.setItem(param, initialSearchParams.get(param)!);
    }
  });
};

const replaceCurrentURL = () => {
  if (!sessionStorage.utm_source) return;

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('utm_source')) return;

  UTM_PARAMS.forEach((param) => {
    if (sessionStorage[param]) {
      searchParams.set(param, sessionStorage[param]);
    }
  });
  history.replace(`${location.pathname}?${searchParams}`);
};

initializeUtmParamsToSessionStorage();

if (FEATURE_AUTO_ADD_UTM_PARAMS) {
  replaceCurrentURL();
  history.listen(() => replaceCurrentURL());
}

export default history;

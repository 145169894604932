import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const CultivationForm = loadable(
  () => import(/* webpackChunkName: "TransactionHistoryPageV2" */ './CultivationFormPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const cultivationFormRoutes: Route[] = [
  {
    name: 'Cultivation Form',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.CULTIVATION_FORM,
      component: CultivationForm,
    },
  },
];

import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const ComplaintPage = loadable(
  () => import(/* webpackChunkName: ComplaintPage */ './ComplaintPage'),
  {
    fallback: <LoadingScreen />,
  },
);

export const complaintListRoutes: Route[] = [
  {
    name: 'Complaint List',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.COMPLAINT_LIST,
      component: ComplaintPage,
    },
  },
  {
    name: 'Complaint Detail',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.COMPLAINT_DETAIL,
      component: ComplaintPage,
    },
  },
];

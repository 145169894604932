import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';
import { PATH } from 'routes/constants';

const NotificationPages = loadable(
  () => import(/* webpackChunkName: "NotificationPages" */ './Notification'),
  {
    fallback: <LoadingScreen />,
  },
);

export const notificationRoute: Route = {
  name: 'Notification',
  isPublic: false,
  routeProps: {
    exact: true,
    path: PATH.NOTIFICATION,
    component: NotificationPages,
  },
};

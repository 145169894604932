import { useState } from 'react';
import { FEATURE_DEVICE_ID_SD, FEATURE_HEADER_PLATFORM, FEATURE_NEW_DEVICE_ID } from 'env';
import { GraphQLClient } from 'graphql-request';
import { RequestDocument, Variables } from 'graphql-request/dist/types';

import { CLIENT_ID } from 'routes/constants';
import { tokenAuth } from 'utils/constants/cookies';
import { HTTP_CLIENT_ID, HTTP_CLIENT_VERSION } from 'utils/constants/gqlCodes';
import { ga } from 'utils/helpers/ga';
import { getToken } from 'utils/token';

interface IRequestArgs {
  query: RequestDocument;
  variables?: Variables;
  cb?: (value1: any, value2: any) => void;
  url?: string;
}
const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const request = async ({ query, variables = {}, cb = undefined, url = '' }: IRequestArgs) => {
    try {
      setLoading(true);
      const client = new GraphQLClient(url || (process.env.REACT_APP_GRAPH_API as string));
      const clientIdStorage = localStorage.getItem(CLIENT_ID);
      const requestHeaders = {
        authorization: `Bearer ${getToken(query)}`,
        'X-Client-Id': HTTP_CLIENT_ID,
        'X-Client-Version': HTTP_CLIENT_VERSION,
        'X-Device-Id': window.customDeviceId,
        'X-Device-Datetime': new Date().toISOString(),
        'X-Page-Location': window.location.href,
        'GA-Session-Id': ga.getSessionId(),
        'GA-Client-Id': window.postMessageTwa ? clientIdStorage || '' : ga.getClientId(),
        ...(FEATURE_HEADER_PLATFORM
          ? { 'X-Platform-Id': window.postMessageTwa ? 'EFISHERYKU-APP' : 'EFISHERYKU-WEB' }
          : {}),
        ...(FEATURE_NEW_DEVICE_ID && {
          'X-New-Device-Id': window.customDeviceIdNew,
        }),
        ...(FEATURE_DEVICE_ID_SD && {
          'X-Device-Id-Sd': window.customDeviceIdSD,
        }),
      };

      let resp = await client.request(query, variables, requestHeaders);

      // callback
      if (cb) {
        resp = cb(data, { fetchMoreResult: resp });
      }

      setError(null);
      setData(resp);
      setLoading(false);
      return resp;
    } catch (err: any) {
      if (err?.response?.errors.length) {
        err.response.errors.forEach((item) => {
          if (!item.extensions.responseBody) {
            item.extensions.responseBody = item.extensions.responseJson;
          }
        });
      }

      setError(err);
      setLoading(false);

      const isForceLogout = err.response?.status === 401;
      if (isForceLogout) {
        document.cookie = `${tokenAuth}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        window.location.href = '/';
        return;
      }

      throw err;
    }
  };

  return {
    request,
    loading,
    data,
    error,
  };
};

export default useRequest;

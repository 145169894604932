import 'twin.macro';

import React, { FunctionComponent } from 'react';

import efisheryLogo from './assets/efishery_logo.png';

import { Container, Image, LoadingContainer, LoadingRipple, LogoContainer } from './styles';

const LoadingScreen: FunctionComponent = () => {
  return (
    <Container>
      <LoadingContainer>
        <LogoContainer>
          <Image alt="loading" src={efisheryLogo} />
          <LoadingRipple />
        </LogoContainer>
        <div>Loading...</div>
      </LoadingContainer>
    </Container>
  );
};

export default LoadingScreen;

import React from 'react';
import loadable from '@loadable/component';
import { Route } from 'types';

import LoadingScreen from 'components/LoadingScreen';

import { PATH } from '../../../routes/constants';

const PromoPagesMF = loadable(
  () => import(/* webpackChunkName: "PromoPagesMF" */ './PromoListPageMF'),
  {
    fallback: <LoadingScreen />,
  },
);

const PromoDetailPagesMF = loadable(
  () => import(/* webpackChunkName: "PromoDetailPagesMF" */ './PromoDetailPageMF'),
  {
    fallback: <LoadingScreen />,
  },
);

export const promoRoutes: Route[] = [
  {
    name: 'Promo',
    isPublic: true,
    routeProps: {
      exact: true,
      path: PATH.PROMO,
      component: PromoPagesMF,
    },
  },
  {
    name: 'PromoDetail',
    isPublic: false,
    routeProps: {
      exact: true,
      path: PATH.PROMO_DETAIL,
      component: PromoDetailPagesMF,
    },
  },
];

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  COMMERCE_SERVICE,
  FEATURE_HEADER_PLATFORM,
  FEATURE_NEW_DEVICE_ID,
  PROMOTION_SERVICE,
  USER_SERVICE,
} from 'env';

import { CLIENT_ID } from 'routes/constants';

import { HTTP_CLIENT_ID, HTTP_CLIENT_VERSION } from './constants/gqlCodes';
import { ga } from './helpers/ga';
import { camelCaseObjKeys } from './helpers/object';

const paramsSerializer = (params: Pick<AxiosRequestConfig, 'params'>) => {
  const result = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        result.append(key, val);
      });
      return;
    }
    result.append(key, value);
  });

  return result;
};

const generateDefaultHeaders = () => ({
  authorization: `Bearer ${window.token}`,
  'X-Client-Id': HTTP_CLIENT_ID,
  'X-Client-Version': HTTP_CLIENT_VERSION,
  'X-Device-Id': window.customDeviceId,
  'X-Page-Location': window.location.href,
  'GA-Session-Id': ga.getSessionId(),
  ...(FEATURE_NEW_DEVICE_ID && {
    'X-New-Device-Id': window.customDeviceIdNew,
  }),
});

const axiosRequestInterceptor = (config: AxiosRequestConfig) => {
  const defaultHeaders = generateDefaultHeaders();

  if (config.headers) {
    const requestHeaders = config.headers;
    config.headers = {
      ...requestHeaders,
      ...defaultHeaders,
      'GA-Client-Id': window.postMessageTwa
        ? localStorage.getItem(CLIENT_ID) || ''
        : ga.getClientId(),
      ...(FEATURE_HEADER_PLATFORM
        ? { 'X-Platform-Id': window.postMessageTwa ? 'EFISHERYKU-APP' : 'EFISHERYKU-WEB' }
        : {}),
    };

    // Convert X-Client-Id to lowercase for promotion service
    if (config.baseURL === PROMOTION_SERVICE && config.headers['X-Client-Id']) {
      config.headers['X-Client-Id'] = (config.headers['X-Client-Id'] as string).toLowerCase();
    }
  }

  if (config.params) {
    config.params = paramsSerializer(config.params);
  }
  return config;
};

const axiosResponseInterceptor = (response: AxiosResponse) => {
  response.data = camelCaseObjKeys(response.data);
  return response;
};

const createAxios = (baseURL?: string) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.request.use(axiosRequestInterceptor);
  instance.interceptors.response.use(axiosResponseInterceptor);

  return instance;
};

export const commerceApi = createAxios(COMMERCE_SERVICE);
export const userApi = createAxios(USER_SERVICE);
export const promotionApi = createAxios(PROMOTION_SERVICE);

import React, { createContext, useState } from 'react';
import { SAWARNA_V2_SERVICES } from 'env';
import { useHistory } from 'react-router-dom';

import BlacklistModal from 'features/blacklist/components/BlacklistModal';
import blacklistUserImage from 'features/home/assets/blacklist_user.svg';
import useAuthContext from 'utils/hooks/useAuthContext';
import useRequest from 'utils/hooks/useRequest';

import { getUserBlacklistInfo } from '../graphql/blacklist';

export enum BlacklistType {
  USER = 'user',
  SUPPLIER = 'supplier',
}

type TContent = {
  title: string;
  description: string;
  btnTitle: string;
  type: BlacklistType;
  action: () => void;
};

const contentDefaultValue = {
  title: '',
  description: '',
  btnTitle: '',
  type: BlacklistType.SUPPLIER,
  action: () => {},
};

export type TBlacklistContext = {
  isShowModal: boolean;
  closeModal: () => void;
  showUserBlockedModal: () => void;
  showSupplierBlockedModal: () => void;
  checkIsUserBlacklisted: (action?: () => void, stay?: boolean) => Promise<boolean>;
};

const contextDefaultValue = {
  isShowModal: false,
  closeModal: () => {},
  showUserBlockedModal: () => {},
  showSupplierBlockedModal: () => {},
  checkIsUserBlacklisted: async () => false,
};

export const BlacklistContext = createContext<TBlacklistContext>(contextDefaultValue);

export const BlacklistProvider = ({ children }) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [content, setContent] = useState<TContent>(contentDefaultValue);

  const { refreshProfile } = useAuthContext();
  const { request } = useRequest();

  const history = useHistory();

  const closeModal = () => {
    setContent(contentDefaultValue);
    setIsShowModal(false);
  };

  const showUserBlockedModal = (action?: () => void, stay = false) => {
    setContent({
      title: 'Akun Anda Dinonaktifkan',
      description:
        'Karena itu Anda tidak bisa menggunakan layanan eFisheryKu. Silakan hubungi kami jika butuh info lebih lanjut.',
      btnTitle: 'Saya Mengerti',
      type: BlacklistType.USER,
      action:
        action ||
        (() => {
          closeModal();
          if (!stay) history.replace('/');
        }),
    });
    setIsShowModal(true);
  };

  const showSupplierBlockedModal = (action?: () => void) => {
    setContent({
      title: 'Akun Agen Dinonaktifkan',
      description:
        'Barang dari agen ini tidak bisa dibeli. Coba pilih barang dari agen lain untuk lanjut, ya.',
      btnTitle: 'Pilih Barang Lain',
      type: BlacklistType.SUPPLIER,
      action:
        action ||
        (() => {
          closeModal();
          history.replace('/beli-pakan');
        }),
    });
    setIsShowModal(true);
  };

  const checkIsUserBlacklisted = async (action?: () => void, stay = false): Promise<boolean> => {
    try {
      const { seluang_UserProfile } = await request({
        query: getUserBlacklistInfo,
        url: SAWARNA_V2_SERVICES,
      });
      if (seluang_UserProfile) {
        if (seluang_UserProfile.isBlacklist) {
          showUserBlockedModal(action, stay);
          refreshProfile();
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const value: TBlacklistContext = {
    isShowModal,
    closeModal,
    showUserBlockedModal,
    showSupplierBlockedModal,
    checkIsUserBlacklisted,
  };

  return (
    <BlacklistContext.Provider value={value}>
      {children}
      <BlacklistModal
        isOpen={isShowModal}
        title={content.title}
        description={content.description}
        illustration={blacklistUserImage}
        btnTitle={content.btnTitle}
        onClick={content.action}
        closeModal={content.action}
        testId={content.type}
      />
    </BlacklistContext.Provider>
  );
};

import tw from 'twin.macro';

import React, { createContext, FunctionComponent, useMemo, useState } from 'react';
import { FEATURE_FORCE_UPDATE } from 'env';

import ModalFloat from 'components/ModalFloat';
import { mappingVersions } from 'utils/helpers/array';
import useFlagrContext from 'utils/hooks/useFlagrContext';
import useQuery from 'utils/hooks/useQuery';

export const VersionContext = createContext({});

export const VersionProvider: FunctionComponent<IVersionProviderr> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const apkCurrentVersion = useQuery().get('appVersion') || '';
  const { appVersions, isForceUpdateActive } = useFlagrContext();
  const { playStoreVersion, minimumVersion } = appVersions;

  const isNeedUpdate = useMemo(() => {
    if (!playStoreVersion || !apkCurrentVersion || !minimumVersion)
      return { value: false, force: false };

    const current = mappingVersions(apkCurrentVersion);
    const newest = mappingVersions(playStoreVersion);
    const min = mappingVersions(minimumVersion);

    const isBelowLatest =
      current[0] < newest[0] ||
      (current[0] === newest[0] && current[1] < newest[1]) ||
      (current[1] === newest[1] && current[2] < newest[2]);

    const isBelowStable =
      current[0] < min[0] ||
      (current[0] === min[0] && current[1] < min[1]) ||
      (current[1] === min[1] && current[2] < min[2]);

    if (isBelowLatest) {
      return { value: true, force: isBelowStable };
    }
    return { value: false, force: false };
  }, [apkCurrentVersion, playStoreVersion, minimumVersion]);

  return (
    <VersionContext.Provider value={{}}>
      {FEATURE_FORCE_UPDATE && isForceUpdateActive && isNeedUpdate.value && (
        <ModalForceUpdate
          isOpen={isModalOpen}
          isCanDismiss={!isNeedUpdate.force}
          newestVersion={playStoreVersion}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
      {children}
    </VersionContext.Provider>
  );
};

const ModalForceUpdate: FunctionComponent<IModalForceUpdate> = ({
  isOpen,
  isCanDismiss,
  newestVersion,
  closeModal,
}) => {
  return (
    <ModalFloat
      isOpen={isOpen}
      title={`Versi Terbaru(${newestVersion}) Tersedia`}
      clickOk={() => {
        window.open(
          'https://play.google.com/store/apps/details?id=com.efishery.stg_membership.twa&hl=in&gl=US',
        );
        window.close();
      }}
      clickCancel={isCanDismiss ? closeModal : undefined}
      cancelTitle="Nanti Saja"
      okTitle="Update"
    >
      <Content>
        Silahkan update aplikasi eFisheryKu dengan klik tombol <Update>Update</Update> di bawah
      </Content>
    </ModalFloat>
  );
};

const Content = tw.div`text-align[center] text-sm`;
const Update = tw.span`font-semibold`;

interface IVersionProviderr {
  children: any;
}

interface IModalForceUpdate {
  isOpen: boolean;
  isCanDismiss: boolean;
  newestVersion: string;
  closeModal: () => void;
}

export default VersionProvider;
